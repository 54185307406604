import React, { useState, useReducer } from "react";
export const UserContext = React.createContext();

export const SET_USER_TYPE = "SET_USER_TYPE";
export const SET_USER = "SET_USER";

const initialState = {
  userType: "",
  user: null,
};

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};
export default function UserProvider({ children }) {
  const [user, dispatchUser] = useReducer(UserReducer, initialState);
  const value = { user, dispatchUser };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
