import {
  USER_DETAILS,
  CLEAR_PERSIST_SLICE,
  UPDATE_USER_DETAILS,
  ACCEPT_TERMS_AND_CONDITIONS,
} from "../constants/common";

const initialState = {
  userData: null,
};

function updateUserData(state, payload) {
  console.log(payload, "payload");
  let user = state?.userData;
  user.fname = payload?.fname;
  user.lname = payload?.lname;
  user.contact = payload?.contact;

  return user;
}

export const PersistedReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_DETAILS:
      return {
        ...state,
        userData: action.payload,
      };
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        userData: updateUserData(state, action.payload),
      };
    case ACCEPT_TERMS_AND_CONDITIONS:
      return {
        ...state,
        userData: { ...state.userData, accepted_terms: 1 },
      };
    case CLEAR_PERSIST_SLICE:
      return {
        state,
      };

    default:
      return state;
  }
};
