import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteCity, getCitesByCountryName } from "../../store/Apis";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/system";
import BasicModal from "./addCityModel";
import { Delete } from "@mui/icons-material";

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& .MuiTableCell-root": {
    fontWeight: "bold",
  },
}));

const columns = [
 
  {
    id: "City",
    label: "City",
    align: "center",
  },
  {
    id: "Country",
    label: "Country",
    align: "center",
  },
  {
    id: "#",
    label: "#",
    align: "center",
  },
  ];

const ITEM_HEIGHT = 48;

export default function DataTable() {
  const [open,setOpen]=useState();
  const params=useParams();
  const {countryName}=params
  const cites=useSelector((state)=>state.employer.cities)
 
  const dispatch=useDispatch()
  useEffect(()=>{
    dispatch(getCitesByCountryName(countryName))
  },[])
  const [anchorEl, setAnchorEl] = useState(null);
  const ITEM_HEIGHT = 48;
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
const handleAddCity=()=>{
  setOpen(true)
  
}
const handleClose=()=>{
  setOpen(false)
  
}
const deleteCities =(city)=>{
 
  dispatch(deleteCity(city.id,countryName))
}

  return (
    <>
      
      <Paper sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
      <Typography variant="h6" style={{padding:"40px",textAlign:"left"}}>
        Cites in {countryName}</Typography>
        <Box textAlign={"right"} padding="10px">
        <Button variant="contained" onClick={handleAddCity}>Add City</Button>
        </Box>
        {open && <BasicModal setOpen={setOpen} country={countryName} open={open} handleClose={handleClose}/>}
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 130px)", position: "relative" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <StyledTableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, paddingTop: "40px" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
             
            </StyledTableHead>
           {cites.map((city,index)=>(
            
             <TableRow key={index}>
           
     <TableCell align={"center"}>
               {city?.city}
     </TableCell>
     <TableCell align={"center"}>
               {city.country}
     </TableCell>
     <TableCell align={"center"}>
     <div>
       <IconButton onClick={(e)=>{deleteCities(city)}}>
        <Delete sx={{color:"#9F325A"}}/>
       </IconButton>
        </div>
     </TableCell>
             </TableRow>
           ))}
           
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
