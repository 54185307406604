import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, TablePagination, Toolbar } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "../../components/Buttons";
import RequestStatusDialog from "./requestStatusDialog";
import SendNewRequestDialog from "./sendNewRequestDialog";
import theme from "../../theme";
import { SNACKBAR_OPEN } from "../../store/constants/common";
import { ERROR } from "../../constants/snackbarTypes";
import { Interceptor } from "../../utils/interceptor";

import {
  getEmployeeRequests,
  cancelRequest,
  getEmployeeSalary,
} from "../../store/Apis";
import { useDispatch, useSelector } from "react-redux";
import {
  PENDING,
  DENIED,
  APPROVED,
  REQUEST_COMPLETED,
  CANCELLED,
  PAID,
} from "../../constants/requestStatus";
import formatNumber from "../../helper/formatNumber";
const axiosInstance = Interceptor();

const ITEM_HEIGHT = 48;

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& .MuiTableCell-root": {
    fontWeight: "bold",
  },
}));

const columns = [
  {
    id: "package",
    label: "Requested Package",
    align: "center",
  },
  { id: "month", label: "Month", align: "center" },
  { id: "amount", label: "Requested Amount", align: "center" },
  {
    id: "request_status",
    label: "Request Status",
    align: "center",
  },
  {
    id: "payment_status",
    label: "Payment Status",
    align: "center",
  },
  {
    id: "action",
    label: "",
    align: "right",
  },
];

export default function DataTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState({ element: null, key: null });
  const [openDialog, setOpenDialog] = useState(false);
  const [openSendRequestDialog, setOpenSendRequestDialog] = useState(false);
  const dispatch = useDispatch();
  const myRequests = useSelector((state) => state.employee.myRequests);
  const { state } = useLocation();
  const [selectedRequest, setSelectedRequest] = useState({});
  const addNewRequestSuccess = useSelector(
    (state) => state.employee.addNewRequestSuccess
  );
  const cancelRequestStatus = useSelector(
    (state) => state.employee.cancelRequestStatus
  );

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, key) => {
    setAnchorEl({ element: event.currentTarget, key: key });
  };
  const handleClose = () => {
    setAnchorEl({ element: null, key: null });
  };

  const showRequestStatus = (id) => {
    const selectedRequest = myRequests.filter((req) => req.id === id);
    setSelectedRequest(selectedRequest[0]);
    setOpenDialog(true);
    handleClose();
  };

  const handleAddNewRequest = () => {
    axiosInstance
      .get("employee/advance-status")
      .then(() => {
        setOpenSendRequestDialog(true);
      })
      .catch((error) => {
        const errors = error?.response?.data?.errors;
        errors.map((error) => {
          dispatch({
            type: SNACKBAR_OPEN,
            payload: {
              snackbarType: ERROR,
              message: error.message,
            },
          });
        });
      });
  };

  const setBackgroundColor = (value) => {
    if (value === PENDING) {
      return "rgb(228 205 13 / 88%)";
    } else if (
      value === REQUEST_COMPLETED ||
      value === APPROVED ||
      value === PAID
    ) {
      return "rgb(57 117 57)";
    } else if (value === DENIED || value === CANCELLED) {
      return "rgb(220 48 38)";
    }
  };
  const handleRemoveStatus = (id) => {
    dispatch(cancelRequest({ request_id: id }));
  };

  useEffect(() => {
    if (state) {
      setOpenSendRequestDialog(state?.openPopup);
    }
  }, [state]);

  useEffect(() => {
    dispatch(getEmployeeRequests());
  }, []);

  useEffect(() => {
    if (addNewRequestSuccess) {
      setOpenSendRequestDialog(false);
      dispatch(getEmployeeRequests());
    }
  }, [addNewRequestSuccess]);

  useEffect(() => {
    if (cancelRequestStatus) {
      dispatch(getEmployeeRequests());
    }
  }, [cancelRequestStatus]);

  const EnhancedTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          minHeight: "35px !important",
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            placeContent: "start",
            marginTop: "15px",
            padding: "0px 35px",
            width: "100%",
          }}
        >
          <Button
            title="Add a Request"
            varient="outlined"
            style={{ width: "150px" }}
            onClick={handleAddNewRequest}
          />
        </Box>
      </Toolbar>
    );
  };

  return (
    <>
      <Paper sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
        <EnhancedTableToolbar />
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 200px)", position: "relative" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <StyledTableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {myRequests
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell key={index} align={"center"}>
                        {row.package}
                      </TableCell>
                      <TableCell key={index} align={"center"}>
                        {row.month}
                      </TableCell>
                      <TableCell key={index} align={"center"}>
                        {formatNumber(row.amount)}
                      </TableCell>
                      <TableCell key={index} align={"center"}>
                        <div
                          style={{
                            background: setBackgroundColor(row.request_status),
                            padding: "4px 20px",
                            borderRadius: "5px",
                            maxWidth: "90px",
                            margin: "auto",
                            color: theme.palette.common.white,
                          }}
                        >
                          {row.request_status === APPROVED
                            ? "APPROVED"
                            : row.request_status === CANCELLED
                            ? "CANCELLED"
                            : row.request_status.toUpperCase()}
                        </div>
                      </TableCell>
                      <TableCell key={index} align={"center"}>
                        <div
                          style={{
                            background: setBackgroundColor(row.payment_status),
                            padding: "4px 20px",
                            borderRadius: "5px",
                            maxWidth: "90px",
                            margin: "auto",
                            color: theme.palette.common.white,
                          }}
                        >
                          {row.payment_status.toUpperCase()}
                        </div>
                      </TableCell>
                      <TableCell
                        key={index}
                        sx={{ textAlign: " -webkit-center" }}
                      >
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(e) => handleClick(e, index)}
                          style={{ textAlign: " -webkit-center" }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        {anchorEl.key === index && (
                          <Menu
                            id={`long-menu-${index}`}
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl.element}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "14ch",
                                boxShadow:
                                  "0 19px 38px rgba(0,0,0,0), 0 6px 8px rgba(0,0,0,0.1)",
                              },
                            }}
                          >
                            <MenuItem
                              key={"1"}
                              onClose={handleClose}
                              onClick={() => handleRemoveStatus(row.id)}
                              sx={{ fontSize: "15px" }}
                              disabled={!(row.request_status === PENDING)}
                            >
                              Delete Request
                            </MenuItem>
                            <MenuItem
                              key={"1"}
                              onClose={handleClose}
                              onClick={() => {
                                if (anchorEl.key === index) {
                                  showRequestStatus(row.id);
                                }
                              }}
                              sx={{ fontSize: "15px" }}
                            >
                              Request Details
                            </MenuItem>
                          </Menu>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={myRequests.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <RequestStatusDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
        data={selectedRequest}
      />
      <SendNewRequestDialog
        open={openSendRequestDialog}
        handleClose={() => {
          setOpenSendRequestDialog(false);
          history.push("/my-requests");
        }}
      />
    </>
  );
}
