import React, { useState } from "react";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "../../components/Buttons";
import CustomSelect from "../../components/Select";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import {
  changeEmployeeStatus,
  getEmployerDetails,
  upDateEmployerStatus,
} from "../../store/Apis";
import {
  Account,
  Details,
  DetailsContainer,
  Profile,
  Requested,
} from "./styles";
import { IMG_URL } from "../../constants/urls";
export default function ViewEmployerDetails() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const employerDetails = useSelector((state) => state.admin.employerDetails);
  const dispatch = useDispatch();
  const { id } = useParams();
  const ITEM_HEIGHT = 48;
  let imgpath = `${IMG_URL}${employerDetails?.document?.url}`;
  useEffect(() => {
    dispatch(getEmployerDetails(id));
  }, []);

  const getMenuItems = () => {
    let menuItems = [];
    if (employerDetails?.admin_approved === -1) {
      menuItems = [
        { label: "Reject", event: "reject" },
        { label: "Approve", event: "approve" },
      ];
    } else if (employerDetails?.admin_approved === 1) {
     
      if (employerDetails?.admin_blocked === -1) {
        menuItems = [
          { label: "Suspend", event: "suspend" },
          { label: "Block", event: "block" },
        ];
      } else if (employerDetails?.admin_blocked == 1) {
    
        menuItems = [
          { label: "Unblock Account", event: "unblock_account" },
          { label: "Suspend Account", event: "suspend_account" },
        ];
      } else if (employerDetails?.admin_blocked === 2) {
        menuItems = [
          { label: "Re-active", event: "re_active" },
          { label: "Block", event: "block" },
        ];
      }
    } else {
      menuItems = [{ label: "Approve", event: "approve" }];
    }
    setMenuItems(menuItems);
  };

  useEffect(() => {
    getMenuItems();
  }, [employerDetails]);

  const updateEmployerStatus = (type) => {
   
    // api request to change status with type and id of the employer
    dispatch(upDateEmployerStatus({ status: type, id: id }));
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        height: "100%",
      }}
    >
      <Card padding="0px 0px" sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 16px 16px 0px",
          }}
        >
          <Profile>Employee Details</Profile>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            style={{ textAlign: "-webkit-center" }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "22ch",
                boxShadow:
                  "0 19px 38px rgba(0,0,0,0), 0 6px 8px rgba(0,0,0,0.1)",
              },
            }}
          >
            {menuItems?.map?.((item) => {
              return (
                <MenuItem
                  key={"1"}
                  sx={{ fontSize: "16px" }}
                  onClick={() => {
                    updateEmployerStatus(item.event);
                  }}
                >
                  {item.label}
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
        <Box mb={3}>
          <Account>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DetailsContainer>
                  <Requested>First Name</Requested>
                  <Details>{employerDetails?.fname}</Details>
                </DetailsContainer>
                <DetailsContainer>
                  <Requested>Company Name</Requested>
                  <Details>{employerDetails?.company_name}</Details>
                </DetailsContainer>
                <DetailsContainer>
                  <Requested>Contact</Requested>
                  <Details>{employerDetails?.contact}</Details>
                </DetailsContainer>
                <DetailsContainer>
                  <Requested>Address</Requested>
                  <Details>{employerDetails?.address}</Details>
                </DetailsContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <DetailsContainer>
                  <Requested>Prefered Language</Requested>
                  <Details>{employerDetails?.prefered_language}</Details>
                </DetailsContainer>

                <DetailsContainer>
                  <Requested> Verified</Requested>
                  <Details>
                    {employerDetails?.otp_verified === 1 ? (
                      <FileDownloadDoneIcon />
                    ) : (
                      <CloseIcon />
                    )}
                  </Details>
                </DetailsContainer>
                <DetailsContainer>
                  <Requested>Blocking status</Requested>
                  <Details>
                    {employerDetails?.admin_blocked === 1 ? (
                      <FileDownloadDoneIcon />
                    ) : (
                      <CloseIcon />
                    )}
                  </Details>
                </DetailsContainer>
                <DetailsContainer>
                  <Requested>Admin verification</Requested>
                  <Details>
                    {employerDetails?.admin_approved === 1 ? (
                      <FileDownloadDoneIcon />
                    ) : (
                      <CloseIcon />
                    )}
                  </Details>
                </DetailsContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingBottom: "16px",
                  }}
                >
                  <Profile>Registration documents of employe</Profile>
                </Box>

                <DetailsContainer>
                  <Requested>PIN</Requested>
                  <Details>details</Details>
                </DetailsContainer>
                <DetailsContainer>
                  <Requested> Registration Document </Requested>
                  <Details>
                    <a download href={imgpath} target={"new"}>
                      {employerDetails?.document?.filename}
                    </a>
                  </Details>
                </DetailsContainer>
              </Grid>
            </Grid>
          </Account>
        </Box>
      </Card>
    </Container>
  );
}
