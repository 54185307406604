import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Container } from "@mui/material";
import { Login, Profile } from "../styles";
import { useHistory } from "react-router-dom";
import Card from "../../../components/Card";
import { EmployeeSignUp } from "../../../store/Apis";
import { useDispatch } from "react-redux";
import SignupForm from "./Form";

const EmployeeSignup = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    salutation: yup.string().required("Salutation required*"),
    first_name: yup.string().required("First name is required*"),
    last_name: yup.string().required("Last name is required*"),
    company_name: yup.object().shape({
      title: yup.string().required("Company name is required*"),
    }),
    contact: yup.string().required("Mobile number is required*"),
    prefered_language: yup.string().required("Language is required*"),
  });

  const initialValues = {
    salutation: "Mr.",
    first_name: "",
    last_name: "",
    company_name: "",
    contact: "",
    prefered_language: "English",
  };

  const handleUserRedirect = () => {
    history.push("/login");
  };

  return (
    <Container
      disableGutters
      maxWidth={"md"}
      sx={{
        paddingTop: "20px",
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Card>
        <Profile>Register your profile</Profile>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            const company_name = values.company_name.title;
            delete values.company_name;
            dispatch(EmployeeSignUp({ ...values, company_name }, history));
          }}
        >
          {(props) => <SignupForm {...props} loading={false} />}
        </Formik>
        <Login>
          Already have an account ?{" "}
          <span onClick={handleUserRedirect}>Login</span>
        </Login>
      </Card>
    </Container>
  );
};

export default EmployeeSignup;
