import React, { useState } from "react";
import { Box, Grid, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import TextInput from "../../components/TextInput";
import { Formik } from "formik";
import * as yup from "yup";
import { Button } from "../../components/Buttons";
import Card from "../../components/Card";
import { Container, Title } from "./styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { submitEmployerDetails } from "../../store/Apis";
import MobileNumberInput from "../../components/mobileNumberInput";
import { FILE_SIZE, SUPPORTED_FORMATS } from "../../constants/fileInfo";

const EmployerDocumentsVerification = () => {
  //yup
  // .string()
  // .required("Upload your iD or passport is required"),
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.persist.userData.id);
  const history = useHistory();
  const [showMore, setShowMore] = useState(false);

  const validationSchema = yup.object({
    file: yup
      .mixed()
      .test("fileSize", "File too large", ({ size }) => {
        return size && size != {} ? size <= FILE_SIZE : true;
      })
      .test("fileSize", "File is required", ({ size }) => {
        return size == undefined ? false : true;
      })
      .test("fileFormat", "Unsupported Format", ({ type }) => {
        return type ? SUPPORTED_FORMATS.includes(type) : true;
      }),

    pin: yup.string().required("Provide KRA pin is required"),
  });

  const secondAdminValidation = yup.object({
    file: yup
      .mixed()
      .test("fileSize", "File too large", ({ size }) => {
        return size && size != {} ? size <= FILE_SIZE : true;
      })
      .test("required", "File is required", ({ size }) => {
        return size == undefined ? false : true;
      })
      .test("fileFormat", "Unsupported Format", ({ type }) => {
        return type ? SUPPORTED_FORMATS.includes(type) : true;
      }),
    pin: yup.string().required("Provide KRA pin is required"),
    fullName: yup.string().required("Full name pin is required"),
    contact: yup.string().required("Mobile number is required"),
  });
  const initialValues = {
    pin: "",
    fullName: "",
    contact: "",
    file: {},
  };

  const handleRadioChange = (e) => {
    if (e.target.value === "yes") {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  };

  const handleFormSubmit = (values) => {
    // history.push("/set-password");
    const formData = new FormData();
    formData.append("pin", values.pin);
    formData.append("file", values.file);
    if (showMore) {
      formData.append("second_admin", showMore);
      formData.append("full_name", values.fullName);
      formData.append("phone", values.contact);
    }
    formData.append("user_id", user_id);
    dispatch(submitEmployerDetails(formData, history));
  };

  return (
    <>
      <Container>
        <Card sx={{ width: "100%" }}>
          <Title>Provide your verification documents</Title>
          <Box pt={6}>
            <Formik
              initialValues={initialValues}
              validationSchema={
                showMore ? secondAdminValidation : validationSchema
              }
              onSubmit={(values, actions) => {
                handleFormSubmit(values);
              }}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        name={"file"}
                        // value={props.values.registrationDoc}
                        onChange={(e) => {
                          props.setFieldValue("file", e.target.files[0]);
                        }}
                        type={"file"}
                        onBlur={props.onBlur}
                        inputlabel={
                          "Upload Company Registretion Document(IMAGE accept | max 1mb"
                        }
                        error={props.touched.file && props.errors.file}
                        helperText={props.touched.file && props.errors.file}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} mt={3}>
                      <TextInput
                        name={"pin"}
                        sx={{ fontSize: "13px" }}
                        inputlabel={"Enter Company KRA Pin"}
                        value={props.values.pin}
                        onChange={props.handleChange}
                        error={props.touched.pin && props.errors.pin}
                        helperText={props.touched.pin && props.errors.pin}
                      />
                    </Grid>
                  </Grid>
                  <Box pt={7} pb={3}>
                    <Box
                      sx={{
                        border: "1px solid #D0D0D0",
                        borderRadius: "10px",
                        padding: "112px 18px",
                      }}
                    >
                      <Box textAlign={"left"} pb={2.5}>
                        Would you like to assign a second admin for payment
                        approvel proccess ?
                      </Box>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="no"
                        onChange={handleRadioChange}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="YES"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>

                      {showMore && (
                        <Box>
                          <Box textAlign={"left"} py={2}>
                            {" "}
                            Second admin information
                          </Box>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <TextInput
                                name={"fullName"}
                                sx={{ fontSize: "13px" }}
                                inputlabel={"Full Name"}
                                value={props.values.fullName}
                                onChange={props.handleChange}
                                error={
                                  props.touched.fullName &&
                                  props.errors.fullName
                                }
                                helperText={
                                  props.touched.fullName &&
                                  props.errors.fullName
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <MobileNumberInput
                                onChange={(e, f) =>
                                  props.setFieldValue("contact", `+${e}`)
                                }
                                touched={
                                  props.touched.contact && props.errors.contact
                                }
                                value={props.values.contact}
                                inputlabel={"Mobile Number"}
                                name={"contact"}
                                error={
                                  props.touched.contact && props.errors.contact
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Box>
                    <Box pt={4} width={"400px"} margin={"auto"}>
                      <Button
                        disableRipple
                        title={"Submit"}
                        type="submit"
                        // onClick={() => history.push("/my-requests")}
                      />
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default EmployerDocumentsVerification;
