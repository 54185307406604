import { Interceptor } from "../../utils/interceptor";
import {
  SNACKBAR_OPEN,
  SNACKBAR_CLOSE,
  LOADER_OPEN,
  LOADER_CLOSE,
  USER_DETAILS,
  UPADATE_TERMS_AND_CONDITIONS,
  ACCEPT_TERMS_AND_CONDITIONS,
} from "../constants/common";
import { ERROR, SUCCESS } from "../../constants/snackbarTypes";
import LocalStorageService from "../../utils/localStorageService";
import {
  MY_EMPLOYEES,
  RECEIVED_REQUESTS,
  CHANGE_EMPLOYEE_REQUEST_STATUS,
  ACCEPT_TERMS_CONDITIONS_STATUS,
  REQUEST_DATA,
  COUNTRIES,
  CITIES,
  CHANGE_EMPLOYEE_STATUS,
  BANKS,
} from "../constants/Employer";
import { EMPLOYEE_DATA } from "../constants/Employee";

const axiosInstance = Interceptor();

export const EmployerSignUp = (data, history) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("/auth/signup/employer", data)
    .then((res) => {
      LocalStorageService.setUserType(res?.data?.role);
      dispatch({
        type: USER_DETAILS,
        payload: res.data,
      });
      history.push("/verify-otp");
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors?.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const submitEmployerDetails = (formData, history) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("/auth/details/employer", formData, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    })
    .then((res) => {
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: "Details added successfully",
        },
      });
      history.push("/set-password");
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const updateEmployerDetails = (formData, employeId) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post(`employer/update/employe/${employeId}`, formData, {})
    .then((res) => {
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: "Details added successfully",
        },
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};
export const getEmployeesList = (employer_id) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get(`/common/employes/${employer_id}`)
    .then((res) => {
      dispatch({
        type: MY_EMPLOYEES,
        payload: res.data,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const getEmployeeById = (id) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get(`/common/employee/${id}`)
    .then((res) => {
      let payload = {
        ...res.data,
        bank_details: JSON.parse(res.data.employee_detail.bank),
      };
      console.log(payload, "payload");
      dispatch({
        type: EMPLOYEE_DATA,
        payload: payload,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const getEmployeesRequests = () => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get("/employer/requests")
    .then((res) => {
      dispatch({
        type: RECEIVED_REQUESTS,
        payload: res.data,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const changeEmployeeRequestStatus =
  (status, requestId) => (dispatch) => {
    dispatch({
      type: LOADER_OPEN,
    });
    axiosInstance
      .post(`employer/request-update/${requestId}`, status)
      .then((res) => {
        dispatch({
          type: CHANGE_EMPLOYEE_REQUEST_STATUS,
          payload: status,
        });
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: SUCCESS,
            message: res.data,
          },
        });
        dispatch(getEmployeesRequests);
      })
      .catch((error) => {
        dispatch({
          type: CHANGE_EMPLOYEE_REQUEST_STATUS,
          payload: "failed",
        });
        const errors = error?.response?.data?.errors;
        errors.map((error) => {
          dispatch({
            type: SNACKBAR_OPEN,
            payload: {
              snackbarType: ERROR,
              message: error.message,
            },
          });
        });
        getEmployeesRequests();
      })
      .finally(() => {
        dispatch({
          type: LOADER_CLOSE,
        });
        setTimeout(() => {
          dispatch({
            type: SNACKBAR_CLOSE,
          });
        }, 5000);
      });
  };

export const sendOtp = (data) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post(`auth/otp-terms-conditions`, data)
    .then((res) => {
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: res.data,
        },
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const acceptTermsAndConditions = (data) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("auth/accept-terms", data)
    .then((res) => {
      dispatch({
        type: ACCEPT_TERMS_CONDITIONS_STATUS,
        payload: true,
      });
      dispatch({
        type: ACCEPT_TERMS_AND_CONDITIONS,
        payload: true,
      });
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: res.data,
        },
      });
      document.getElementById("loginBtn").click();
    })
    .catch((error) => {
      dispatch({
        type: ACCEPT_TERMS_CONDITIONS_STATUS,
        payload: false,
      });
      const errors = error?.response?.data?.errors;
      errors?.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const getEmployeeRequest = (id) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get(`/common/request/${id}`)
    .then((res) => {
      dispatch({
        type: REQUEST_DATA,
        payload: res.data,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;

      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const getCountries = () => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get(`countries`)
    .then((res) => {
      dispatch({
        type: COUNTRIES,
        payload: res.data,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;

      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};
export const getBanks = () => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get(`banks`)
    .then((res) => {
      dispatch({
        type: BANKS,
        payload: res.data,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;

      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};
export const getCitesByCountryName = (CountryName) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get(`cities/${CountryName}`)
    .then((res) => {
      dispatch({
        type: CITIES,
        payload: res.data,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;

      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const changeEmployeeStatus = (id, status) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post(`common/change-emplpoyee-status/${id}`, status)
    .then((res) => {
      dispatch({
        type: CHANGE_EMPLOYEE_STATUS,
        payload: status.status === -1 ? -1 : 1,
      });
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: res.data,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: ACCEPT_TERMS_CONDITIONS_STATUS,
        payload: false,
      });
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};
