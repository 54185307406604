import {
  Box,
  Button,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBox from "../../components/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeSalary, addNewRequest, addCity } from "../../store/Apis";
import theme from "../../theme";
import moment from "moment";
import TextInput from "../../components/TextInput";
import formatNumber from "../../helper/formatNumber";

const AddNewCity = (props) => {
  const { open, handleClose } = props;
  const [city, setCity] = useState("");
  const date = new Date();
  const dispatch = useDispatch();
  const saveCity = () => {
    let postData = { city: city, country: props.country };
    dispatch(addCity(postData));
    props.setOpen(false);
  };

  return (
    <DialogBox
      title={"Add New City"}
      open={open}
      handleClose={handleClose}
      sx={{ with: "100%" }}
      max-width="100px"
    >
      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            borderRadius: "5px",
            width: { md: "526px", sm: "fit-content" },
          }}
        >
          <Box padding={"10px"} width={"100%"}>
            <TextInput
              sx={{ fontSize: "13px", width: "100%" }}
              inputlabel={"City Name "}
              value={city}
              required="true"
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button variant="contained" onClick={handleClose}>
            {" "}
            close
          </Button>
          <Button variant="contained" onClick={saveCity}>
            {" "}
            save
          </Button>
        </Box>
      </DialogContent>
    </DialogBox>
  );
};

export default AddNewCity;
