import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import { SUCCESS } from "../../constants/snackbarTypes";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars() {
  const [open, setOpen] = React.useState(false);
  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const isSnackbarOpen = useSelector((state) => state.common.snackbarOpen);
  const snackbarType = useSelector((state) => state.common.snackbarType);
  const message = useSelector((state) => state.common.message);

  React.useEffect(() => {
    if (isSnackbarOpen) {
      setOpen(true);
    }
  }, [isSnackbarOpen]);

  setTimeout(() => {
    setOpen(false);
  }, 5000);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
      bodyStyle={{ backgroundColor: snackbarType === SUCCESS && "#478e47" }}
    >
      <Alert
        onClose={onClose}
        severity={snackbarType}
        sx={{
          width: "100%",
          backgroundColor: snackbarType === SUCCESS && "#478e47",
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
