import { Interceptor } from "../../utils/interceptor";
import {
  SNACKBAR_OPEN,
  SNACKBAR_CLOSE,
  LOADER_OPEN,
  LOADER_CLOSE,
} from "../constants/common";
import { ERROR, SUCCESS } from "../../constants/snackbarTypes";
import {
  ALL_EMPLOYERS,
  ALL_REQUESTS,
  DELETE_SALARY_RANGE,
  EMPLOYER_DETAILS,
  EMPLOYER_PENDING_REQUEST,
  GET_SALARY_RANGE,
  SALARY_RANGE,
} from "../constants/Admin";
import { getCitesByCountryName } from "./employer";
import { getTermsAndConditions } from "./common";
import { CHANGE_EMPLOYEE_REQUEST_STATUS } from "../constants/Employer";
const axiosInstance = Interceptor();

export const getAllRequest = (status) => (dispatch) => {
  let url = status ? `admin/requests?status=${status}` : "admin/requests";
  console.log(status, "url");
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get(url)
    .then((res) => {
      dispatch({
        type: ALL_REQUESTS,
        payload: res.data,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};
export const getRequestBySearch = (query) => (dispatch) => {
  let url = `admin/requests?query=${query}`;
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get(url)
    .then((res) => {
      dispatch({
        type: ALL_REQUESTS,
        payload: res.data,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};
export const getEmployerPendingRequest = () => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get("admin/requests?status=pending")
    .then((res) => {
      dispatch({
        type: EMPLOYER_PENDING_REQUEST,
        payload: res.data,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};
export const getAllEmployers = () => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get("admin/employers")
    .then((res) => {
      dispatch({
        type: ALL_EMPLOYERS,
        payload: res.data,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};
export const getEmployerDetails = (id) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get(`admin/employer/${id}`)
    .then((res) => {
      dispatch({
        type: EMPLOYER_DETAILS,
        // need to remove 0 after chaning api
        payload: res.data[0],
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const upDateEmployerStatus = (postData) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post(`admin/update-employer-status/${postData.id}`, {
      status: postData.status,
    })
    .then((res) => {
      dispatch({
        type: EMPLOYER_DETAILS,
        payload: res.data,
      });
      dispatch(getEmployerDetails(postData.id));
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};
export const setSalaryRange = (values) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("admin/salary-range", values)
    .then((res) => {
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: res.data,
        },
      });
      dispatch(getSalaryRange());
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const getSalaryRange = () => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get("admin/salary-range")
    .then((res) => {
      dispatch({
        type: SALARY_RANGE,
        payload: res.data,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const deleteSalaryRange = (id) => (dispatch) => {
  console.log(id, "id");
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .delete(`admin/salary-range/${id}`)
    .then((res) => {
      dispatch({
        type: DELETE_SALARY_RANGE,
        payload: res.data,
      });
      dispatch(getSalaryRange());
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: res.data,
        },
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};
export const addCity = (postData) => (dispatch) => {
  axiosInstance
    .post(`admin/add-city`, postData)
    .then((res) => {
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: res.data,
        },
      });
      dispatch(getCitesByCountryName(postData.country));
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const deleteCity = (id, country) => (dispatch) => {
  axiosInstance
    .delete(`admin/city/${id}`)
    .then((res) => {
      console.log(res, "ress");
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: res.data,
        },
      });
      dispatch(getCitesByCountryName(country));
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};
export const saveTermsAndContions = (terms) => (dispatch) => {
  axiosInstance
    .post(`admin/terms-and-conditions`, terms)
    .then((res) => {
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: res.data,
        },
      });
      dispatch(getTermsAndConditions());
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const updateEmployer = (terms) => (dispatch) => {
  axiosInstance
    .post(`admin/terms-and-conditions`, terms)
    .then((res) => {
      console.log(res, "ress");
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: res.data,
        },
      });
      dispatch(getTermsAndConditions());
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const adminUpdateRequest = (status, request_id) => (dispatch) => {
  console.log(request_id, "id");
  axiosInstance
    .post(`admin/update-request/${request_id}`, status)
    .then((res) => {
      dispatch({
        type: CHANGE_EMPLOYEE_REQUEST_STATUS,
        payload: status,
      });
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: res.data,
        },
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};
