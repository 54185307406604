import {
  SNACKBAR_OPEN,
  SNACKBAR_CLOSE,
  LOADER_OPEN,
  LOADER_CLOSE,
  OPT_SENT_STATUS,
  RESET_PASS_STATUS,
  TERMS_AND_CONDITIONS,
} from "../constants/common";
const initialState = {
  loading: false,
  snackbarOpen: false,
  snackbarType: "",
  message: "",
  optSentStatus: false,
  resetPasswordStatus: false,
  termsAndCondtions: null,
  termsAndCondtionsUpdated: null,
};

export const CommonSlice = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_OPEN:
      return {
        ...state,
        snackbarOpen: true,
        loading: false,
        snackbarType: action.payload.snackbarType,
        message: action.payload.message,
      };
    case SNACKBAR_CLOSE:
      return {
        ...state,
        snackbarOpen: false,

        message: "",
      };
    case LOADER_OPEN:
      return {
        ...state,
        loading: true,
      };
    case LOADER_CLOSE:
      return {
        ...state,
        loading: false,
      };
    case OPT_SENT_STATUS:
      return {
        ...state,
        optSentStatus: action.payload,
      };
    case RESET_PASS_STATUS:
      return {
        ...state,
        resetPasswordStatus: action.payload,
      };

    case TERMS_AND_CONDITIONS:
      return {
        ...state,
        termsAndCondtions: action.payload,
      };

    default:
      return state;
  }
};
