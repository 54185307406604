import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  debounce,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableBody,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../components/TextInput";
import { useHistory, useParams } from "react-router-dom";
import {
  adminUpdateRequest,
  getAllRequest,
  getRequestBySearch,
} from "../../store/Apis/admin";
import theme from "../../theme";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import formatNumber from "../../helper/formatNumber";
import moment from "moment/moment";
import Timer from "../../components/Timer";
import { changeEmployeeRequestStatus } from "../../store/Apis";
import Row from "./Row";

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& .MuiTableCell-root": {
    fontWeight: "bold",
  },
}));

const columns = [
  {
    id: "EmployeeName",
    label: "Employee Name",
    align: "center",
  },
  {
    id: "companyName",
    label: "Company Name",
    align: "center",
  },

  {
    id: "requestedPackage",
    label: "Requested Package",
    align: "center",
  },
  // {
  //   id: "of_request",
  //   label: "Of Request",
  //   align: "center",
  // },
  {
    id: "requestedFor",
    label: "Requested For",
    align: "center",
  },
  {
    id: "requestedAmount",
    label: "Requested Amount",
    align: "center",
  },
  {
    id: "requestedOn",
    label: "Requested On",
    align: "center",
  },
  {
    id: "requestStatus",
    label: "Requested Status",
    align: "center",
  },
  {
    id: "paymentStatus",
    label: "Payment Status",
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    align: "center",
  },
];

const ITEM_HEIGHT = 48;

export default function DataTable() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let status = params.get("status");

  const [page, setPage] = useState(0);
  const [query, setQuery] = useState("");
  const requestStatus = useSelector(
    (state) => state.employer.changeEmployeeRequestStatus
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const allRequests = useSelector((state) => state.admin.allRequests);

  const dispatch = useDispatch();
  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(getRequestBySearch(query));
    }, 2000);

    return () => clearTimeout(getData);
  }, [query]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getAllRequest(status));
  }, [requestStatus, status]);

  return (
    <>
      <Paper sx={{ width: "100%", height: "100vh" }}>
        <Typography variant="h6" style={{ padding: "40px", textAlign: "left" }}>
          {status ? `Pending Requests` : `All Requests`}
        </Typography>
        {!status && (
          <Grid container padding={"10px 30px"}>
            <Grid item xs={5}>
              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                fullWidth
                onChange={(event) => setQuery(event.target.value)}
              />
            </Grid>
          </Grid>
        )}
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 330px)", position: "relative" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <StyledTableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, paddingTop: "40px" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {allRequests.length > 0 &&
                allRequests
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return <Row row={row} index={index} />;
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={allRequests.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
