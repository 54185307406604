export const SNACKBAR_OPEN = "SNACKBAR_OPEN";
export const SNACKBAR_CLOSE = "SNACKBAR_CLOSE";
export const LOADER_OPEN = "LOADER_OPEN";
export const LOADER_CLOSE = "LOADER_CLOSE";
export const USER_DETAILS = "USER_DETAILS";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const CLEAR_PERSIST_SLICE = "CLEAR_PERSIST_SLICE";
export const OPT_SENT_STATUS = "OPT_SENT_STATUS";
export const RESET_PASS_STATUS = "RESET_PASS_STATUS";
export const TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS";
export const ACCEPT_TERMS_AND_CONDITIONS = "ACCEPT_TERMS_AND_CONDITIONS";
