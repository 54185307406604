import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#9F325A",
    },
    secondary: {
      main: "#F6F6F6",
    },
    grey: {
      G100: "#F6F6F6",
      G200: "#ECECEC",
      G300: "#D9D9D9",
    },
    text: {
      main: "#656565",
    },
    error: {
      main: "#CF5951",
    },
    success: {
      main: "#00FF00",
    },

    disabled: "#ECECEC",
    white: "#FFFFFF",
    black: "#3F3C3C",
    purple: "#791C7B",
    gradient:
      "linear-gradient(270deg, rgba(159, 50, 90, 0.97) 28.92%, rgba(255, 0, 0, 0.75) 108.41%)",
    gradient360:
      "linear-gradient(360deg, rgba(159, 50, 90, 0.97) 28.92%, rgba(255, 0, 0, 0.75) 108.41%)",
  },
  typography: {},
});

export default theme;
