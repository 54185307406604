import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
const MDBackdrop = () => {
  const [open, setOpen] = useState(true);
  const loading = useSelector((state) => state.common.loading);

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: "999999" }}
        open={loading}
        //onClick={handleClose}
      >
        <CircularProgress
          size={50}
          disableShrink
          style={{ color: "#3c96ef" }}
        />
      </Backdrop>
    </React.Fragment>
  );
};

export default MDBackdrop;
