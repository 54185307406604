import { styled } from "@mui/system";
export const DetailsContainer = styled("div")(({ theme }) => ({
    width: "70%",
    margin:"auto",
  borderBottom: "1px solid #D0D0D0",
  display: "flex",
}));
export const Requested = styled("div")(({ theme }) => ({
  padding: "10px 25px",
  fontSize: "14px",
  fontWeight: 600,
  color: " #656565E5",
  display: "flex",
  width: "40%",
}));
export const Details = styled("div")(({ theme }) => ({
  padding: "10px 25px",
  fontSize: "14px",
  fontWeight: 400,
  color: " #656565E5",
  display: "flex",
  
}));