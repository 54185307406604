import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { EmployeeSlice } from "./Employee";
import { EmployerSlice } from "./Employer";
import { CommonSlice } from "./CommonReducer";
import { PersistedReducer } from "./PersistedReducer";
import { AdminSlice } from "./Admin";

const persistUserReducer = persistReducer(
  {
    key: "user",
    storage,
  },
  PersistedReducer
);

export const rootReducer = combineReducers({
  // login: LoginSlice,
  employer: EmployerSlice,
  admin: AdminSlice,
  employee: EmployeeSlice,
  common: CommonSlice,
  persist: persistUserReducer,
});
