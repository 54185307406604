import { styled } from "@mui/system";

export const Profile = styled("p")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "20px",
  lineHeight: "23px",
  marginTop: "5px",
  color: theme.palette.black,
  textAlign: "start",
  margin: "0",
  padding: "40px",
}));
export const Account = styled("div")(({ theme }) => ({
  border: "1px solid #D0D0D0",
  borderRadius: "10px",
  padding: "16px 18px",
  width: "95%",
  margin: "auto",
}));
export const DetailsContainer = styled("div")(({ theme }) => ({
  border: "1px solid #D0D0D0",
  borderRadius: "5px",
  backgroundColor: theme.palette.secondary.main,
  display: "flex",
  marginBottom: "10px",
}));
export const Requested = styled("div")(({ theme }) => ({
  padding: "20px 0px 20px 25px ",
  fontSize: "15.5px",
  fontWeight: 400,
  color: " #656565E",
  display: "flex",
  width: "35%",
}));
export const Details = styled("div")(({ theme }) => ({
  padding: "16px 25px",
  fontSize: "14px",
  fontWeight: 400,
  color: " #656565E5",
  display: "flex",
  borderLeft: "1px solid #D0D0D0",
  alignItems:"center"
}));
