import { Box, DialogContent, Grid } from "@mui/material";
import React from "react";
import DialogBox from "../../components/Dialog";
import { Details, DetailsContainer, Requested } from "./styles";
import moment from "moment";
import formatNumber from "../../helper/formatNumber";
import {
  APPROVED,
  CANCELLED,
  PAID,
  PENDING,
  REQUEST_COMPLETED,
} from "../../constants/requestStatus";
import theme from "../../theme";

const RequestStatusDialog = (props) => {
  const { data, open, handleClose } = props;
  const setBackgroundColor = (value) => {
    if (value === PENDING) {
      return "rgb(228 205 13 / 88%)";
    } else if (
      value === APPROVED ||
      value === PAID ||
      value === REQUEST_COMPLETED
    ) {
      return "rgb(32 133 27 / 1)";
    } else {
      return "rgb(220 48 38)";
    }
  };

  const {
    month,
    package: reqPackage,
    amount,
    payment_status,
    created_at,
    approved_at,
  } = data;
  return (
    <DialogBox
      title={"Request Details"}
      open={open}
      maxWidth="md"
      handleClose={handleClose}
    >
      <Box>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} width={"100%"}>
              <DetailsContainer>
                {" "}
                <Requested> REQUESTED PACKAGE </Requested>
                <Details>{reqPackage}</Details>
              </DetailsContainer>
            </Grid>
            <Grid item md={6} sm={12} width={"100%"}>
              <DetailsContainer>
                {" "}
                <Requested>MONTH</Requested> <Details> {month} </Details>
              </DetailsContainer>
            </Grid>
            <Grid item md={6} sm={12} width={"100%"}>
              <DetailsContainer>
                <Requested>REQUESTED AMOUNT</Requested>{" "}
                <Details> {formatNumber(amount)}</Details>
              </DetailsContainer>
            </Grid>
            <Grid item md={6} sm={12} width={"100%"}>
              <DetailsContainer>
                <Requested>REQUESTED STATUS</Requested>{" "}
                <Details>
                  <div
                    style={{
                      background: setBackgroundColor(data?.request_status),
                      padding: "4px 20px",
                      borderRadius: "5px",
                      maxWidth: "90px",
                      margin: "auto",
                      color: theme.palette.common.white,
                    }}
                  >
                    {data.request_status === APPROVED
                      ? "APPROVED"
                      : data?.request_status === CANCELLED
                      ? "CANCELLED"
                      : data?.request_status?.toUpperCase()}
                  </div>
                </Details>
              </DetailsContainer>
            </Grid>
            <Grid item md={6} sm={12} width={"100%"}>
              <DetailsContainer>
                <Requested>PAYMENT STATUS</Requested>{" "}
                <Details>
                  {" "}
                  <div
                    style={{
                      background: setBackgroundColor(payment_status),
                      padding: "4px 20px",
                      borderRadius: "5px",
                      maxWidth: "90px",
                      margin: "auto",
                      color: theme.palette.common.white,
                    }}
                  >
                    {data.request_status === APPROVED
                      ? "APPROVED"
                      : payment_status === CANCELLED
                      ? "CANCELLED"
                      : payment_status?.toUpperCase()}
                  </div>
                </Details>
              </DetailsContainer>
            </Grid>
            <Grid item md={6} sm={12} width={"100%"}>
              <DetailsContainer>
                <Requested> REQUESTED ON</Requested>{" "}
                <Details>
                  {moment(created_at).format("YYYY-MM-DD HH:mm:ss")}
                </Details>
              </DetailsContainer>
            </Grid>
            <Grid item md={6} sm={12} width={"100%"}>
              <DetailsContainer>
                <Requested>APPROVED ON</Requested>{" "}
                <Details>
                  {moment(approved_at).format("YYYY-MM-DD HH:mm:ss")}
                </Details>
              </DetailsContainer>
            </Grid>
            <Grid item md={6} sm={12} width={"100%"}>
              <DetailsContainer>
                <Requested>PAID ON</Requested>{" "}
                <Details>
                  {data?.paid_at
                    ? moment(data?.paid_at).format("YYYY-MM-DD HH:mm:ss")
                    : "---"}
                </Details>
              </DetailsContainer>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </DialogBox>
  );
};
export default RequestStatusDialog;
