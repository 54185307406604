import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/styles";
import theme from "../../theme";
import { Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    borderRadius: "10px !important",
    height: 40,
    textTransform: "inherit !important",
  },
  outlined: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
      // background: theme.palette.outlinedButtonHoverBg,
    },
    "&:disabled": {
      border: `1px solid ${theme.palette.disabled}`,
      color: theme.palette.disabled,
    },
  },
  outlinedSecondary: {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    "&:hover": {
      border: `1px solid ${theme.palette.secondary.main}`,
      // background: theme.palette.outlinedButtonHoverBg,
    },
    "&:disabled": {
      border: `1px solid ${theme.palette.disabled}`,
      color: theme.palette.disabled,
    },
  },
  contained: {
    background: theme.palette.primary.main,
    color: theme.palette.white,
    "&:hover": {
      // background: theme.palette.containedButtonHoverBg,
    },
    "&:disabled": {
      // background: theme.palette.containedButtonDisabledBg,
      color: theme.palette.disabled,
    },
  },
  containedSecondary: {
    backgroundColor: `${theme.palette.grey.G200} !important`,
    color: `${theme.palette.black} !important`,
    "&:hover": {
      // background: theme.palette.containedButtonHoverBg,
    },
    "&:disabled": {
      // background: theme.palette.containedButtonDisabledBg,
      color: theme.palette.disabled,
    },
  },
  text: {
    // color: theme.palette.darkBlue,
    "&:hover": {
      background: "#1753891a",
    },
    "&:disabled": {
      color: theme.palette.disabled,
    },
  },
  sizeLarge: {
    padding: "8px 22px",
  },
  sizeMedium: {
    padding: "6px 16px",
  },
  sizeSmall: {
    padding: "4px 10px",
    height: "30px",
  },
  label: {
    textTransform: "capitalize",
  },
}));

export default function CustomButton(props) {
  const {
    type,
    id,
    onChange,
    isloading = false,
    title,
    disabled,
    icon: Icon,
    varient,
    size,
    style,
    sx,
  } = props;
  const classes = useStyles();

  return (
    // <ButtonWrapper>
    <Button
      style={style}
      onClick={onChange}
      classes={classes}
      className={classes.root}
      variant={varient ? varient : "contained"}
      color="primary"
      disabled={disabled}
      type={type}
      size={size}
      id={id}
      // startIcon={Icon && <Icon />}
      // endIcon={Icon && <Icon />}
      // fullWidth
      {...props}
    >
      {isloading ? (
        <CircularProgress size={25} color="grey" />
      ) : (
        <Typography variant="body1">{title}</Typography>
      )}
    </Button>
    // </ButtonWrapper>
  );
}
