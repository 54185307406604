import React from "react";
import { Box } from "@mui/material";
import { LayoutContainer, Logo, Welcome } from "./styles";

import AppLogo from "../../assets/logo.png";

const Header = ({ children }) => {
  return (
    <Box sx={{ height: "100%" }}>
      <LayoutContainer>
        <Logo>
          <img src={AppLogo} alt="logo" />
        </Logo>
        <Welcome>ADVANCE SALO</Welcome>
        {/* <AccountCircleIcon /> */}
      </LayoutContainer>
      <Box sx={{ height: "calc(100% - 70px)" }}>{children}</Box>
    </Box>
  );
};

export default Header;
