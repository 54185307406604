import React from "react";
import { Route } from "react-router-dom";

import PublicLayout from "../components/Layout/Public";
const PublicGuard = ({ children, component: Component, path, ...rest }) => {
  return (
    <Route
      {...rest}
      path={path}
      render={({ location }) => (
        <PublicLayout>
          <Component />
        </PublicLayout>
      )}
    />
  );
};
export default PublicGuard;
