import React from "react";
import { LoginContainer, FormWrapper, Logo } from "../Login/styles";
import AppLogo from "../../assets/logo.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button } from "../../components/Buttons";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import Card from "../../components/Card";
const Login = () => {
  const history = useHistory();
  return (
    <LoginContainer>
      <Card padding="60px" sx={{ width: "100%" }}>
        <Box width={"100%"}>
          <Box pb={10}>
            <Logo>
              <img src={AppLogo} alt="logo" />
            </Logo>
          </Box>
          <Box pb={3.5}>
            <Button
              disableRipple
              title={"Register as employer"}
              endIcon={<ArrowForwardIosIcon fontSize="large" />}
              onClick={() => {
                history.push("/employer-signup");
              }}
            />
          </Box>
          <Box>
            <Button
              title={"Register as employee"}
              variant={"containedSecondary"}
              disableRipple
              endIcon={<ArrowForwardIosIcon fontSize="large" />}
              onClick={() => {
                history.push("/employee-signup");
              }}
            />
          </Box>
        </Box>
      </Card>
    </LoginContainer>
  );
};

export default Login;
