import { Box, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBox from "../../components/Dialog";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PinIcon from "@mui/icons-material/Pin";
import PhoneIcon from "@mui/icons-material/Phone";
import PasswordIcon from "@mui/icons-material/Password";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Input from "../../components/TextInput";
import MobileNumberInput from "../../components/mobileNumberInput";
import { useDispatch, useSelector } from "react-redux";
import {
  ResetPasswordOtpRequest,
  resendOTP,
  ResetPassword,
} from "../../store/Apis";
import { Formik } from "formik";
import * as yup from "yup";
import { ResendOTP } from "./styles";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <PhoneIcon />,
    2: <PinIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const validation = yup.object({
  phone: yup.string().required("mobile Number is required*"),
});

const validation2 = yup.object({
  otp: yup.string().required("otp is required*"),
  password: yup.string().required("Passowrd is required*"),
  confirmPassword: yup
    .string()
    .required("Passowrd is required*")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const steps = ["Your Phone Number", "Reset Password"];

export default function CustomizedSteppers(props) {
  const { open, handleClose } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [contact, setContact] = React.useState(null);
  const dispatch = useDispatch();
  const optSendStatus = useSelector((state) => state.common.optSentStatus);
  const resetPasswordStatus = useSelector(
    (state) => state.common.resetPasswordStatus
  );

  useEffect(() => {
    if (optSendStatus) {
      setActiveStep(1);
    }
  }, [optSendStatus]);

  useEffect(() => {
    if (resetPasswordStatus) {
      handleClose();
      setActiveStep(0);
    }
  }, [resetPasswordStatus]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const handleResendOTP = () => {
    dispatch(
      resendOTP({
        contact: contact,
        resend_type: "forget-password",
      })
    );
  };

  return (
    <DialogBox
      title={"Reset Password"}
      open={open}
      maxWidth="md"
      handleClose={handleClose}
    >
      <DialogContent dividers>
        <Box width={"650px"}>
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>
          <Box px={5}>
            {
              <React.Fragment>
                <Box width={"70%"} margin="auto" my={3}>
                  {activeStep === 0 ? (
                    <Formik
                      initialValues={{ phone: "" }}
                      validationSchema={validation}
                      onSubmit={(values, actions) => {
                        dispatch(
                          ResetPasswordOtpRequest({ phone: values.phone })
                        );
                      }}
                    >
                      {(props) => (
                        <form onSubmit={props.handleSubmit}>
                          <MobileNumberInput
                            value={props.values.phone}
                            name={"phone"}
                            onChange={(e) => {
                              props.setFieldValue("phone", `+${e}`);
                              setContact(`+${e}`);
                            }}
                            error={props.touched.phone && props.errors.phone}
                            helperText={props.errors.phone}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 2,
                            }}
                          >
                            <Box sx={{ flex: "1 1 auto" }} />

                            <Button type="submit" sx={{ marginTop: 4 }}>
                              {completedSteps() === totalSteps() - 1
                                ? "Reset Password"
                                : "Next"}
                            </Button>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  ) : activeStep === 1 ? (
                    <Formik
                      initialValues={{
                        otp: "",
                        password: "",
                        confirmPassword: "",
                      }}
                      validationSchema={validation2}
                      onSubmit={(values, actions) => {
                        dispatch(
                          ResetPassword({
                            otp: values.otp,
                            password: values.password,
                          })
                        );
                      }}
                    >
                      {(props) => (
                        <form onSubmit={props.handleSubmit}>
                          <Input
                            value={props.values.otp}
                            inputlabel="verify OTP"
                            name="otp"
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                            error={props.touched.otp && props.errors.otp}
                            helperText={props.errors.otp}
                          />
                          <ResendOTP onClick={handleResendOTP}>
                            Resend SMS OTP
                          </ResendOTP>
                          <Box py={2}>
                            <Input
                              value={props.values.password}
                              type={"password"}
                              name={"password"}
                              inputlabel="New Password"
                              onBlur={props.handleBlur}
                              error={
                                props.touched.password && props.errors.password
                              }
                              helperText={props.errors.password}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Input
                            value={props.values.confirmPassword}
                            type={"password"}
                            name="confirmPassword"
                            inputlabel="Confirm Password"
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                            error={
                              props.touched.confirmPassword &&
                              props.errors.confirmPassword
                            }
                            helperText={
                              props.touched.confirmPassword &&
                              props.errors.confirmPassword
                            }
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 2,
                            }}
                          >
                            <Box sx={{ flex: "1 1 auto" }} />

                            {
                              <Button type="submit" sx={{ marginTop: 4 }}>
                                {completedSteps() === totalSteps() - 1
                                  ? "Reset Password"
                                  : "Next"}
                              </Button>
                            }
                          </Box>
                        </form>
                      )}
                    </Formik>
                  ) : (
                    <></>
                  )}
                </Box>
              </React.Fragment>
            }
          </Box>
        </Box>
      </DialogContent>
    </DialogBox>
  );
}
