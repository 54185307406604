import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@mui/material";
import {
  getEmployeesRequests,
  changeEmployeeRequestStatus,
} from "../../store/Apis";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components/Buttons";
import theme from "../../theme";
import moment from "moment";
import {
  PENDING,
  DENIED,
  APPROVED,
  REQUEST_COMPLETED,
  CANCELLED,
} from "../../constants/requestStatus";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  PAID,
  PENDING as PAYMENT_PENDING,
} from "../../constants/paymentStatus";
import { useHistory } from "react-router-dom";
import Row from "./Row";

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& .MuiTableCell-root": {
    fontWeight: "bold",
  },
}));

const columns = [
  {
    id: "employee_name",
    label: "Employee Name",
    align: "center",
  },
  {
    id: "amount",
    label: "Requested Amount",
    align: "center",
  },
  { id: "month", label: "Requested Month", align: "center" },
  { id: "package", label: "Requested Package", align: "center" },

  {
    id: "requested_on",
    label: "Requested On",
    align: "center",
  },
  {
    id: "request_status",
    label: "Request Status",
    align: "center",
  },
  {
    id: "approved_on",
    label: "Approved On",
    align: "center",
  },
  {
    id: "payment_status",
    label: "Payment Status",
    align: "center",
  },
  {
    id: "paid_on",
    label: "Paid On",
    align: "center",
  },
  {
    id: "action",
    label: "Fast Approve/Cancel",
    align: "center",
  },
  {
    id: "action",
    label: " ",
    align: "center",
  },
];
const ITEM_HEIGHT = 48;
export default function DataTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.employer.receivedRequests);
  const requestStatus = useSelector(
    (state) => state.employer.changeEmployeeRequestStatus
  );
  const history = useHistory();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const setBackgroundColor = (value) => {
    if (value === PENDING) {
      return "rgb(228 205 13 / 88%)";
    } else if (value === DENIED) {
      return "rgb(220 48 38)";
    } else if (value === APPROVED || value === PAID) {
      return "rgb(32 133 27 / 1)";
    } else {
      return "rgb(57 117 57)";
    }
  };

  useEffect(() => {
    dispatch(getEmployeesRequests());
  }, [requestStatus]);

  function approveRequest(id) {
    dispatch(
      changeEmployeeRequestStatus(
        {
          status: "request approved",
          more_info: null,
        },
        id
      )
    );
  }

  function rejectRequest(id) {
    if (!checkRejectRequestStatus) {
      dispatch(
        changeEmployeeRequestStatus(
          {
            status: "request denied",
            more_info: null,
          },
          id
        )
      );
    }
  }

  function checkRejectRequestStatus(updatedDate) {
    const currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const updated_at = moment(updatedDate).format("YYYY-MM-DD HH:mm:ss");
    var diff = moment(new Date()).diff(updatedDate, "minutes");
    return diff > 15;
  }
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (requestStatus === "success") {
      dispatch(getEmployeesRequests());
    }
  }, [requestStatus]);

  return (
    <>
      <Paper sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 130px)", position: "relative" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <StyledTableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, paddingTop: "40px" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {data &&
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return <Row row={row} index={index} />;
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
