import { Box, Card, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { decode } from "html-entities";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTermsAndConditions } from "../../store/Apis";

const TermsAndConditions = () => {
  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  const dispatch = useDispatch();
  const role = useSelector((state) => state.persist.userData.role);
  const termsAndConditions = useSelector(
    (state) => state.common.termsAndCondtions
  );
  useEffect(() => {
    dispatch(getTermsAndConditions("employee"));
  }, []);
  return (
    <Container maxWidth={false} disableGutters>
      <Card padding="0px 0px" sx={{ width: "100%", height: "100%" }}>
        <Typography
          variant="h6"
          style={{ padding: "40px", textAlign: "left" }}
        ></Typography>
        <p>{renderHTML(decode(termsAndConditions?.text))}</p>
      </Card>
    </Container>
  );
};
export default TermsAndConditions;
