import axios from "axios";
import LocalStorageService from "./localStorageService";
import history from "./redirect/history";
import { useSelector } from "react-redux";
import { store } from "../store";
import { SNACKBAR_OPEN } from "../store/constants/common";
import { ERROR } from "../constants/snackbarTypes";
import { BASE_URL } from "../constants/urls";
const localStorageService = LocalStorageService.getService();

export const Interceptor = () => {
  // const token = useSelector((state) => state.persist.employee.token);
  const axiosInstance = axios.create({
    // baseURL: "http://localhost:3000",
    baseURL: BASE_URL,
  });
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorageService.getAccessToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      config.headers["Content-Type"] = config.headers
        ? config.headers["Content-Type"]
        : "application/json";
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      console.log(error);
      if (!error?.response?.data) {
        store.dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: "Network Error. please check your network connection",
          },
        });
        return Promise.reject(error);
      }

      if (error?.response?.status === 401) {
        // localStorageService.clearToken();
        // window.location.href("/login");
        // window.location.reload();
        history.push("/login");
      }
      if (error?.response?.status === 500) {
        store.dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
