import { Container, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { LayoutContainer, MoreMenu, Welcome } from "./styles";
import MenuIcon from "@mui/icons-material/Menu";
import { MoveDown } from "@mui/icons-material";
import { display } from "@mui/system";
import MyMenu from "../SideNav/mobileMenu";
const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const userName = useSelector((state) => state.persist.userData.fname);
  const toggleMenu = () => {
    setNavbarOpen((prev) => !prev);
  };
  return (
    <Container maxWidth={false} disableGutters>
      <LayoutContainer>
        <IconButton
          sx={{ display: { sm: "block", md: "none", lg: "none" } }}
          onClick={toggleMenu}
        >
          <MenuIcon />
        </IconButton>
        {navbarOpen && <MyMenu isOpen={navbarOpen} setIsOpen={setNavbarOpen} />}
        {/* <Logo>
              <img src={AppLogo} alt="logo" />
            </Logo> */}
        <Welcome>ADVANCE SALO</Welcome>
        {/* <div onClick={() => history.push("/test")}>test</div> */}
        <MoreMenu>
          {/* <AccountCircleIcon /> */}
          Welcome <span>{userName?.toUpperCase()}</span>
        </MoreMenu>
      </LayoutContainer>
    </Container>
  );
};

export default Navbar;
