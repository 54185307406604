import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer } from "./reducers";
import { persistStore } from "redux-persist";

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));

// The store now has the ability to accept thunk functions in `dispatch`
// const store = createStore(rootReducer, composedEnhancer);

export const store = createStore(rootReducer, composedEnhancer);

export const persistor = persistStore(store);
