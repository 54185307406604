import React from "react";
import { TextField, Typography } from "@mui/material";

import { makeStyles } from "@material-ui/styles";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  InputFeild: {
    display: "flex",
    borderBottom: "none !important",
    width: "100%",
    // margin: "8px 0px",
    "& .MuiFilledInput-root": {
      padding: "0px 15px",
      // zIndex: 99999,
      borderRadius: "10px",
      backgroundColor: `${theme.palette.grey[200]} !important`,
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.black,
    },
    "& .MuiFilledInput-input": {
      padding: "8px",
      color: theme.palette.black,
      fontSize: "16px",
    },
    "& .MuiInputLabel-filled": {
      padding: "0px 15px",
      // zIndex: 999999,
      margin: -5,
      color: "#42526E",
      opacity: 0.6,
    },
    "& .MuiFilledInput-underline:before": {
      borderBottom: "none !important",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "none",
    },
  },
}));

function CustomTextField(props) {
  const classes = useStyles();
  const { inputlabel, type, extratext, autoFocus } = props;

  return (
    <>
      <Typography
        color={theme.palette.black}
        fontSize={16}
        paddingLeft={0.5}
        paddingBottom={0.5}
        textAlign={"left"}
      >
        {inputlabel}{" "}
        {extratext && (
          <span
            style={{
              fontSize: "14px",
              fontWeight: 700,
              borderRadius: "10px",
              padding: "0px 5px",
              paddingBottom: "3px",
            }}
          >
            {`(${extratext})`}
          </span>
        )}
      </Typography>
      <TextField
        type={type}
        variant="filled"
        fullWidth
        size="small"
        className={classes.InputFeild}
        autoFocus={autoFocus}
        {...props}
      />
    </>
  );
}

export default CustomTextField;
