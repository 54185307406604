import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, TablePagination, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../components/TextInput";
import { getEmployeesList } from "../../store/Apis";
import { useHistory } from "react-router-dom";
import Input from "@mui/material";
import { Search } from "@mui/icons-material";

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& .MuiTableCell-root": {
    fontWeight: "bold",
  },
}));

const columns = [
  {
    id: "companyName",
    label: "Company Name",
    align: "center",
  },
  {
    id: "EmployeeName",
    label: "Employee Name",
    align: "center",
  },
  {
    id: "requestedPackage",
    label: "Requested Package",
    align: "center",
  },
  // {
  //   id: "of_request",
  //   label: "Of Request",
  //   align: "center",
  // },
  {
    id: "requestedFor",
    label: "Requested For",
    align: "center",
  },
  {
    id: "requestedAmount",
    label: "Requested Amount",
    align: "center",
  },
  {
    id: "requestedOn",
    label: "Requested On",
    align: "center",
  },
  {
    id: "requestStatus",
    label: "Requested Status",
    align: "center",
  },
  {
    id: "paymentStatus",
    label: "Payment Status",
    align: "center",
  },
  
 
];

const ITEM_HEIGHT = 48;

export default function DataTable() {
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const employeesList = useSelector((state) => state.employer.myEmployees);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getEmployeesList());
  }, []);

  const setBackgroundColor = (value) => {
    if (value === "pending") {
      return "rgb(228 205 13 / 88%)";
    } else if (value === "approved") {
      return "rgb(57 117 57)";
    } else {
      return "rgb(220 48 38)";
    }
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      
      <Paper sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
      <Typography variant="h6" style={{padding:"40px",textAlign:"left"}}>
        Employer Requests Pending Approval</Typography>
        <Grid container >
        <Grid item xs={4}>
          <TextInput
            name={"registrationDoc"}
            type={"text"}
            value={"search"}
            sx={{margin:"10px"}}
          />
        </Grid>
       
        </Grid>
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 130px)", position: "relative" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <StyledTableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, paddingTop: "40px" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            {/* <TableBody>
              {employeesList &&
                employeesList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return <Row row={row}/>
                 
                  })}
            </TableBody> */}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={employeesList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
