import React, { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { adminUpdateRequest } from "../../store/Apis/admin";
import theme from "../../theme";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  PENDING,
  DENIED,
  APPROVED,
  REQUEST_COMPLETED,
  CANCELLED,
  PAID,
  HOLD,
  ADMIN_DENIED,
} from "../../constants/requestStatus";
import formatNumber from "../../helper/formatNumber";
import moment from "moment/moment";
import Timer from "../../components/Timer";
import { changeEmployeeRequestStatus } from "../../store/Apis";
import { AdminSlice } from "../../store/reducers/Admin";
import {
  ADMIN_REQUEST_TIMEOUT,
  EMPLOYER_REQUEST_TIMEOUT,
  P1_APPROVE_DATE,
  P2_APPROVE_DATE,
  REQUEST_APPROVE_MINUTES,
} from "../../constants/adminConstant";
const ITEM_HEIGHT = 48;
const Row = ({ row, index }) => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let status = params.get("status");

  const [page, setPage] = useState(0);
  const [displayCancel, setCancelDisplay] = useState(true);
  const [anchorEl, setAnchorEl] = useState({ element: null, key: null });
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const history = useHistory();
  const dispatch = useDispatch();

  const setBackgroundColor = (value) => {
    if (value === PENDING) {
      return "rgb(228 205 13 / 88%)";
    } else if (
      value === APPROVED ||
      value === PAID ||
      value === REQUEST_COMPLETED
    ) {
      return "rgb(32 133 27 / 1)";
    } else {
      return "rgb(220 48 38)";
    }
  };
  const open = Boolean(anchorEl.element);
  const handleClick = (event, key) => {
    setAnchorEl({ element: event.currentTarget, key: key });
  };
  const handleClose = () => {
    setAnchorEl({ element: null, key: null });
  };
  function updateRequest(id, status) {
    dispatch(
      adminUpdateRequest(
        {
          status: status,
        },
        id
      )
    );
  }
  let approvedDate = moment(row?.approved_at);
  let currentDate = moment(new Date());
  let diff = currentDate.diff(approvedDate, "seconds");

  const getCompareDate = () => {
    let current_date = moment(new Date());
    let approved_at = moment(row?.approved_at).format("YYYY-MM-DD HH:mm:ss");
    const request_date =
      row.package === "p1" ? P1_APPROVE_DATE : P2_APPROVE_DATE;

    const current_month = current_date.format("MM");
    let current_year = current_date.format("YYYY");
    const hours_and_minutes_start = getTimeFromMins(
      REQUEST_APPROVE_MINUTES - ADMIN_REQUEST_TIMEOUT
    );

    const compare_start_date = moment(
      new Date(
        `${current_year}-${current_month}-${request_date} ${hours_and_minutes_start}`
      )
    ).format("YYYY-MM-DD HH:mm:ss");
    const hours_and_minutes_end = getTimeFromMins(REQUEST_APPROVE_MINUTES);
    const compare_end_date = moment(
      new Date(
        `${current_year}-${current_month}-${request_date} ${hours_and_minutes_end}`
      )
    ).format("YYYY-MM-DD HH:mm:ss");
    let time_now = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    if (time_now < compare_start_date) {
      return { display_timer: false };
    } else if (time_now >= compare_start_date && time_now <= compare_end_date) {
      console.log("coming here");
      const difference = moment(compare_end_date).diff(new Date(), "seconds");
      return { time: difference, display_timer: true };
    } else if (
      moment(time_now).diff(approved_at, "minutes") <=
        ADMIN_REQUEST_TIMEOUT + EMPLOYER_REQUEST_TIMEOUT &&
      moment(time_now).diff(approved_at, "minutes") >= EMPLOYER_REQUEST_TIMEOUT
    ) {
      console.log(moment(time_now).diff(approved_at, "minutes"), "diifff");
      console.log("testing here");
      const difference =
        (ADMIN_REQUEST_TIMEOUT + EMPLOYER_REQUEST_TIMEOUT) * 60 -
        moment(time_now).diff(approved_at, "seconds");
      return { time: difference, display_timer: true };
    } else {
      return { display_timer: false, last: false };
    }
  };

  function getTimeFromMins(mins) {
    if (mins >= 24 * 60 || mins < 0) {
      throw new RangeError(
        "Valid input should be greater than or equal to 0 and less than 1440."
      );
    }
    var h = (mins / 60) | 0,
      m = mins % 60 | 0;
    return moment().hours(h).minutes(m).format("HH:mm:00");
  }

  const compare_date = getCompareDate();
  console.log("checking response", compare_date);
  if (compare_date.display_timer) {
    setTimeout(() => {
      console.log("time out called");
      setCancelDisplay(false);
    }, compare_date.time * 1000);
  }
  console.log(compare_date, "compare_date");
  return (
    <TableRow key={index} row={row}>
      <TableCell key={index} align={"center"}>
        {row.employee_details.fname} {row.employee_details.lname}
      </TableCell>
      <TableCell key={index} align={"center"}>
        {row.employee_details.company_name}
      </TableCell>

      <TableCell key={index} align={"center"}>
        {row.package}
      </TableCell>
      <TableCell key={index} align={"center"}>
        {row.month}
      </TableCell>
      <TableCell key={index} align={"center"}>
        {formatNumber(row.amount)}
      </TableCell>
      <TableCell key={index} align={"center"}>
        {moment(row.created_at).format("YYYY-MM-DD HH:mm")}
      </TableCell>

      <TableCell key={index} align={"center"}>
        <div
          style={{
            background: setBackgroundColor(row.request_status),
            padding: "4px 20px",
            borderRadius: "5px",
            maxWidth: "90px",
            margin: "auto",
            color: theme.palette.common.white,
          }}
        >
          {row.request_status === APPROVED
            ? "APPROVED"
            : row.request_status === CANCELLED
            ? "CANCELLED"
            : row.request_status.toUpperCase()}
        </div>
      </TableCell>
      <TableCell key={index} align={"center"}>
        <div
          style={{
            background: setBackgroundColor(row.payment_status),
            padding: "4px 20px",
            borderRadius: "5px",
            maxWidth: "90px",
            margin: "auto",
            color: theme.palette.common.white,
          }}
        >
          {row.payment_status === APPROVED
            ? "APPROVED"
            : row.payment_status === CANCELLED
            ? "CANCELLED"
            : row.payment_status.toUpperCase()}
        </div>
      </TableCell>
      <TableCell>
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={(e) => handleClick(e, index)}
            style={{ textAlign: " -webkit-center" }}
          >
            <MoreVertIcon />
          </IconButton>
          {anchorEl.key === index && (
            <Menu
              id={`long-menu-${index}`}
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl.element}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "22ch",
                  boxShadow:
                    "0 19px 38px rgba(0,0,0,0), 0 6px 8px rgba(0,0,0,0.1)",
                },
              }}
            >
              <MenuItem
                key={`${index}`}
                sx={{ fontSize: "16px" }}
                onClick={() => {
                  if (anchorEl.key === index) {
                    history.push(`/view-request-details/${row.id}`);
                  }
                }}
              >
                View Request Details
              </MenuItem>

              {(row.request_status === APPROVED &&
                compare_date.display_timer &&
                displayCancel) ||
              row.request_status === HOLD ? (
                <>
                  <MenuItem
                    key={`${index}`}
                    sx={{ fontSize: "16px" }}
                    onClick={() => {
                      updateRequest(row.id, ADMIN_DENIED);
                    }}
                  >
                    Cancel &nbsp;{" "}
                    {row.request_status === APPROVED && (
                      <Timer seconds={compare_date.time} />
                    )}
                  </MenuItem>
                  {row.request_status === APPROVED && (
                    <MenuItem
                      key={`${index}`}
                      sx={{ fontSize: "16px" }}
                      onClick={() => {
                        updateRequest(row.id, HOLD);
                      }}
                    >
                      Hold
                    </MenuItem>
                  )}
                  {row.request_status === HOLD && (
                    <MenuItem
                      key={`${index}`}
                      sx={{ fontSize: "16px" }}
                      onClick={() => {
                        updateRequest(row.id, APPROVED);
                      }}
                    >
                      Un-Hold
                    </MenuItem>
                  )}
                </>
              ) : null}
            </Menu>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default Row;
