import { styled } from "@mui/system";

export const OtpContainer = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "375px",
  margin: "auto",
}));

export const ResendOTP = styled("div")(({ theme }) => ({
  margin: "5px 0px 0px 4px",
  color: theme.palette.purple,
  textAlign: "left",
  fontSize: "12px",
  cursor: "pointer",
}));

export const VerifyOtp = styled("div")(({ theme }) => ({
  color: theme.palette.black,
  paddingBottom: "25px",
}));
