import React from "react";
import { Card, Container, Typography } from "@mui/material";
import Table from "./Table"

const AllCites = () => {
  return (
    <Container
    maxWidth={false}
    disableGutters
    sx={{
      height: "100%",
    }}
  >
    <Card padding="0px 0px" sx={{ width: "100%", height: "100%" }}>
      <Table />
    </Card>
  </Container>
  );
};

export default AllCites;

