import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { TermsAndConditions, AcceptTerms } from "./styles";

import { Button } from "../../components/Buttons";
import * as yup from "yup";
import { Formik } from "formik";
import OTPVerificationDialog from "../SetPassword/SmsOtpVerificationDialog";
import {
  acceptTermsAndConditions,
  getTermsAndConditions,
} from "../../store/Apis";
import { useDispatch, useSelector } from "react-redux";
import { decode } from "html-entities";
import DialogBox from "../../components/Dialog";
import { EMPLOYER } from "../../constants/userTypes";
import { isError } from "react-query";

const TermsDialog = (props) => {
  const { open, handleClose, user, setDialog } = props;
  const theme = useTheme();

 

  const termsAndConditions = useSelector(
    (state) => state.common.termsAndCondtions
  );

  const acceptTermsAndCondition = useSelector(
    (state) => state.employer.acceptTermsAndConditionsStatus
  );
  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  const [openOtp, setOtp] = useState(open);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user !== null) {
      dispatch(getTermsAndConditions(user?.role));
    }
  }, [user]);

  const validationSchema = yup.object({
    TermsAndConditions: yup
      .boolean()
      .oneOf([true], "Please accept Terms and Conditions to proceed"),
    // .required("Please accept Terms and Conditions to proceed"),
  });
  const handleFormSubmit = () => {
    dispatch(
      acceptTermsAndConditions(
        {
          user_id: user?.id,
          role: user?.role,
        },
        setDialog
      )
    );
  };
  const initialValues = {
    TermsAndConditions: false,
  };

  return (
    <DialogBox open={open} handleClose={handleClose} title={""}>
      <Box width={"initial"} padding={"20px"}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => handleFormSubmit(values)}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Typography
                color={theme.palette.black}
                paddingTop={2.5}
                paddingBottom={2}
              >
                {user.role == EMPLOYER
                  ? `Employer Aggrement`
                  : `Terms & Conditions`}
              </Typography>
              <TermsAndConditions>
                <p>{renderHTML(decode(termsAndConditions?.text))}</p>
              </TermsAndConditions>
              <AcceptTerms>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="TermsAndConditions"
                      checked={props.values.TermsAndConditions}
                      onChange={(e) => {
                        setOtp(e.target.checked);
                        props.setFieldValue(
                          "TermsAndConditions",
                          e.target.checked
                        );
                      }}
                    />
                  }
                  label="Accept Terms and Conditions"
                />
              </AcceptTerms>
              {props.touched.TermsAndConditions &&
                props.errors.TermsAndConditions && (
                  <Box
                    sx={{
                      color: "#CF5951",
                      textAlign: "left",
                      margin: "4px 14px 0px 14px",
                      fontSize: " 0.75rem",
                      fontWeight: 400,
                    }}
                  >
                    {props.errors.TermsAndConditions}
                  </Box>
                )}
              <Box pt={1.5} width={"200px"} margin={"auto"}>
                <Button disableRipple title={"Save"} type="submit" />
              </Box>
              {openOtp && user?.role === "employer" && (
                <OTPVerificationDialog
                  open={openOtp}
                  setDialog={setDialog}
                  handleClose={() => {
                    setOtp(false);
                    props.setFieldValue("TermsAndConditions", false);
                  }}
                />
              )}
            </form>
          )}
        </Formik>
      </Box>
    </DialogBox>
  );
};

export default TermsDialog;
