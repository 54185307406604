const nameSpace = "employer";

export const MY_EMPLOYEES = `${nameSpace}/MY_EMPLOYEES`;
export const RECEIVED_REQUESTS = `${nameSpace}/RECEIVED_REQUESTS`;
export const CHANGE_EMPLOYEE_REQUEST_STATUS = `${nameSpace}/CHANGE_EMPLOYEE_REQUEST_STATUS`;
export const ACCEPT_TERMS_CONDITIONS_STATUS = `${nameSpace}/ACCEPT_TERMS_CONDITIONS_STATUS`;
export const REQUEST_DATA = `${nameSpace}/REQUEST_DATA `;
export const COUNTRIES = `${nameSpace}/COUNTRIES `;
export const CITIES = `${nameSpace}/CITIES `;
export const BANKS = `${nameSpace}/BANKS `;
export const CHANGE_EMPLOYEE_STATUS = `${nameSpace}/CHANGE_EMPLOYEE_STATUS  `;
