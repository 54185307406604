import React, { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
// import moment from "moment";
import moment from "moment-timezone";
import { Button } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import theme from "../../theme";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeEmployeeRequestStatus } from "../../store/Apis";
import {
  PENDING,
  DENIED,
  APPROVED,
  REQUEST_COMPLETED,
  CANCELLED,
  PAID,
} from "../../constants/requestStatus";
import formatNumber from "../../helper/formatNumber";
import Timer from "../../components/Timer";
import {
  ADMIN_REQUEST_TIMEOUT,
  EMPLOYER_REQUEST_TIMEOUT,
  P1_APPROVE_DATE,
  P2_APPROVE_DATE,
  REQUEST_APPROVE_MINUTES,
} from "../../constants/adminConstant";

const Row = ({ row, index }) => {
  const blockStatus = useSelector(
    (state) => state?.persist?.userData?.admin_blocked
  );
  console.log(blockStatus, "blockStatus");
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayCancel, setCancelDisplay] = useState(true);
  const ITEM_HEIGHT = 48;
  const history = useHistory();
  const dispatch = useDispatch();

  const getCompareDate = () => {
    let current_date = moment(new Date());
    let approved_at = moment(row?.approved_at).format("YYYY-MM-DD HH:mm:ss");
    const request_date =
      row.package === "p1" ? P1_APPROVE_DATE : P2_APPROVE_DATE;

    const current_month = current_date.format("MM");
    let current_year = current_date.format("YYYY");
    const hours_and_minutes_start = getTimeFromMins(
      REQUEST_APPROVE_MINUTES - EMPLOYER_REQUEST_TIMEOUT - ADMIN_REQUEST_TIMEOUT
    );

    const compare_start_date = moment(
      new Date(
        `${current_year}-${current_month}-${request_date} ${hours_and_minutes_start}`
      )
    ).format("YYYY-MM-DD HH:mm:ss");
    const hours_and_minutes_end = getTimeFromMins(
      REQUEST_APPROVE_MINUTES - ADMIN_REQUEST_TIMEOUT
    );
    const compare_end_date = moment(
      new Date(
        `${current_year}-${current_month}-${request_date} ${hours_and_minutes_end}`
      )
    ).format("YYYY-MM-DD HH:mm:ss");
    
    let time_now = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    console.log({
      compare_start_date,compare_end_date,time_now
    },'timmeee')
    if (time_now < compare_start_date) {
      return { display_timer: false ,display_button:true};
    } else if (
      approved_at < compare_start_date &&
      time_now >= compare_start_date &&
      time_now <= compare_end_date
    ) {
      const difference = moment(compare_end_date).diff(new Date(), "seconds");
      return { time: difference, display_timer: true,display_button:true };
    } else if (
      moment(time_now).diff(approved_at, "seconds") <
      EMPLOYER_REQUEST_TIMEOUT * 60
    ) {
      const difference =
        EMPLOYER_REQUEST_TIMEOUT * 60 -
        moment(time_now).diff(approved_at, "seconds");
      return { time: difference, display_timer: true ,display_button:true};
    } else return { display_timer: false ,display_button:false};
  };
  function getTimeFromMins(mins) {
    if (mins >= 24 * 60 || mins < 0) {
      throw new RangeError(
        "Valid input should be greater than or equal to 0 and less than 1440."
      );
    }
    var h = (mins / 60) | 0,
      m = mins % 60 | 0;
    return moment().hours(h).minutes(m).format("HH:mm:00");
  }
  const compare_date = getCompareDate();
  if (compare_date.display_timer) {
    setTimeout(() => {
      console.log("time out called");
      setCancelDisplay(false);
    }, compare_date.time * 1000);
  }
  const setBackgroundColor = (value) => {
    if (value === PENDING) {
      return "rgb(228 205 13 / 88%)";
    } else if (
      value === APPROVED ||
      value === PAID ||
      value === REQUEST_COMPLETED
    ) {
      return "rgb(32 133 27 / 1)";
    } else {
      return "rgb(220 48 38)";
    }
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function approveRequest(id) {
    dispatch(
      changeEmployeeRequestStatus(
        {
          status: "request approved",
          more_info: null,
        },
        id
      )
    );
  }
  console.log(compare_date,"cmp")
  function cancelRequest(id) {
    dispatch(
      changeEmployeeRequestStatus(
        {
          status: DENIED,
          more_info: null,
        },
        id
      )
    );
  }
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
      <TableCell key={index + 3} align={"center"}>
        {row.employee_details.fname} {row.employee_details.lname}
      </TableCell>
      <TableCell key={index + 1} align={"center"}>
        {formatNumber(row.amount)}
      </TableCell>
      <TableCell key={index + 2} align={"center"}>
        {row.month}
      </TableCell>
      <TableCell key={index + 3} align={"center"}>
        {row.package}
      </TableCell>

      <TableCell key={index + 4} align={"center"}>
        {moment(row.created_at).format("DD-MM-YYYY")}
      </TableCell>
      <TableCell key={index + 5} align={"center"}>
        <div
          style={{
            background: setBackgroundColor(row.request_status),
            padding: "4px 20px",
            borderRadius: "5px",
            maxWidth: "90px",
            margin: "auto",
            color: theme.palette.common.white,
          }}
        >
          {row.request_status === APPROVED
            ? "APPROVED"
            : row.request_status === DENIED
            ? "DENIED"
            : row.request_status === CANCELLED
            ? "CANCELLED"
            : row.request_status.toUpperCase()}
        </div>
      </TableCell>
      <TableCell key={index + 6} align={"center"}>
        {row.approved_at
          ? moment(row.approved_at).format("DD-MM-YYYY")
          : "----"}
      </TableCell>
      <TableCell key={index} align={"center"}>
        <div
          style={{
            background: setBackgroundColor(row.payment_status),
            padding: "4px 20px",
            borderRadius: "5px",
            maxWidth: "90px",
            margin: "auto",
            color: theme.palette.common.white,
          }}
        >
          {row.payment_status.toUpperCase()}
        </div>
      </TableCell>
      <TableCell key={index + 7} align={"center"}>
        ----
      </TableCell>

      <TableCell key={index + 8} align={"center"}>
        <div
          style={{
            display: "flex",
            columnGap: "4px",
          }}
        >
          {row.request_status === PENDING && (
            <Button
              variant="outlined"
              size="small"
              text="Approve"
              disabled={
                row.request_status === APPROVED ||
                row.request_status === REQUEST_COMPLETED
              }
              onClick={() => approveRequest(row.id)}
            >
              Approve
            </Button>
          )}
           {
                compare_date.display_button && 
                row.request_status === APPROVED &&
                <Button
           variant="outlined"
           size="small"
           text="Approve"
           onClick={() => cancelRequest(row.id)}
         >
           Cancel
         </Button>
              } 
         
          {row.request_status === APPROVED &&
            compare_date.display_timer &&
            displayCancel &&
            row.payment_status === PENDING && (
              <>
                <Timer seconds={compare_date.time} />
              </>
            )}
        </div>
      </TableCell>
      <TableCell key={index} align={"center"}>
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "16ch",
                boxShadow:
                  "0 19px 38px rgba(0,0,0,0), 0 6px 8px rgba(0,0,0,0.1)",
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MenuItem
              key={"1"}
              onClose={handleClose}
              onClick={() => history.push(`/view-request-details/${row.id}`)}
              sx={{ fontSize: "15px" }}
            >
              Request Details
            </MenuItem>
          </Menu>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default Row;
