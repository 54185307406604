import React, { useState } from "react";
import { Button } from "../../../components/Buttons";
import TextInput from "../../../components/TextInput";
import Select from "../../../components/Select";
import { Box, Grid } from "@mui/material";
import MobileNumberInput from "../../../components/mobileNumberInput";
import { useDispatch, useSelector } from "react-redux";
import SelectCountry from "../../../components/Select/county";
import { getCitesByCountryName } from "../../../store/Apis";
import SelectCity from "../../../components/Select/cities";
const SignupForm = ({
  handleSubmit,
  touched,
  errors,
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  loading,
  ...rest
}) => {
  const dispatch = useDispatch();
  const cities = useSelector((state) => state.employer.cities);
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Select
            options={["Mr.", "Ms.", "Mrs."]}
            selectlabel={"Salutation"}
            name="salutation"
            value={values.salutation}
            onChange={(e) => {
              setFieldValue("salutation", e.target.value);
            }}
            onBlur={handleBlur}
            error={Boolean(errors.salutation)}
            error_message={touched.salutation && errors.salutation}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            sx={{ fontSize: "13px" }}
            inputlabel={"First Name"}
            name="first_name"
            value={values.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.first_name)}
            helperText={touched.first_name && errors.first_name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            sx={{ fontSize: "13px" }}
            inputlabel={"Last Name"}
            name="last_name"
            value={values.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.last_name)}
            helperText={touched.last_name && errors.last_name}
          />
        </Grid>
        <Grid item xs={12} md={6} md={6}>
          <TextInput
            sx={{ fontSize: "13px" }}
            inputlabel={"Company Name"}
            name="company_name"
            value={values.company_name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.company_name)}
            helperText={touched.company_name && errors.company_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectCountry
            selectlabel={"Country"}
            name="country"
            value={values.country}
            onChange={(e) => {
              setFieldValue("country", e.target.value);
              dispatch(getCitesByCountryName(values.country));
            }}
            onBlur={handleBlur}
            error={Boolean(errors.country)}
            error_message={touched.country && errors.country}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectCity
            countryName={values.country}
            selectlabel={"City"}
            name="city"
            value={values.city}
            onChange={(e) => {
              setFieldValue("city", e.target.value);
            }}
            onBlur={handleBlur}
            error={Boolean(errors.city)}
            error_message={touched.city && errors.city}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            name={"prefered_language"}
            options={["English"]}
            selectlabel={"Prefered Language"}
            value={values.prefered_language}
            onChange={(e) => {
              setFieldValue("prefered_language", e.target.value);
            }}
            onBlur={handleBlur}
            error={Boolean(errors.prefered_language)}
            error_message={
              touched.prefered_language && errors.prefered_language
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            name={"role"}
            options={["Owner", "Executive", "HR"]}
            selectlabel={"Role"}
            value={values.role}
            onChange={(e) => {
              setFieldValue("role", e.target.value);
            }}
            onBlur={handleBlur}
            error={Boolean(errors.role)}
            error_message={touched.role && errors.role}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <TextInput
            sx={{ fontSize: "13px" }}
            inputlabel={"Email"}
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
        </Grid> */}
        <Grid item xs={6}>
          <MobileNumberInput
            onChange={(e, f) => setFieldValue("contact", `+${e}`)}
            touched={touched.contact && errors.contact}
            value={values.contact}
            inputlabel={"Mobile Number"}
            name={"contact"}
            error={touched.contact && errors.contact}
          />
        </Grid>
      </Grid>
      <Box pt={4} width={"200px"} margin={"auto"}>
        <Button
          disableRipple
          title={"Register"}
          type={"submit"}
          // onClick={() => navigate("/employer-login")}
        />
      </Box>
    </form>
  );
};

export default SignupForm;
