import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import { Button } from "../../components/Buttons";
import { Container } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import {
  Account,
  Profile,
  Details,
  DetailsContainer,
  Requested,
} from "./styles";
import {
  updateEmployee,
  changeEmployeeStatus,
  getEmployeeById,
} from "../../store/Apis";
import Card from "../../components/Card";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import EditEmployeeForm from "./EditEmployeeDetailsForm";
import { useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const ViewEmployeeDetails = () => {
  const [isEditable, setEditable] = useState(false);
  const dispatch = useDispatch();
  const employeeData = useSelector((state) => state.employee.employeeData);
  const employeeStatus = useSelector((state) => state.employer.employeeStatus);
  const { id } = useParams();

  useEffect(() => {
    if (employeeStatus !== employeeData.admin_blocked) {
      dispatch(getEmployeeById(id));
    }
  }, [employeeStatus]);

  useEffect(() => {
    dispatch(getEmployeeById(id));
  }, []);

  const [selectValue, setSelectValue] = useState(null);
  const [salutation, setSalutation] = useState(null);
  useEffect(() => {
    if ((employeeData?.bank_details?.bank_name, employeeData?.salutation)) {
      setSelectValue(employeeData?.bank_details?.bank_name);
      setSalutation(employeeData?.salutation);
    }
  }, [employeeData]);

  const validationSchema = yup.object({
    first_name: yup.string().required("First name is required*"),
    last_name: yup.string().required("Last name is required*"),
    contact: yup.string().required("Contact Number is required*"),
    pin: yup.string().required("KRA pin is required*"),
    salary: yup.string().required("Salary is required*"),
    payroll: yup.string().required("Payroll is required*"),
    bankName: yup.string().required("Bank name is required*"),
    accountHolderName: yup.string().required("Account holder is required*"),
    accountNumber: yup.string().required("Account number is required*"),
  });

  const initialValues = {
    first_name: employeeData?.fname,
    last_name: employeeData?.lname,
    contact: employeeData?.contact,
    address: employeeData?.address,
    city: "GIlgit",
    salutation: salutation,
    postal: "345",
    imgName: employeeData?.document?.url,
    pin: employeeData?.employee_detail?.pin,
    payroll: employeeData?.employee_detail?.payroll,
    salary: employeeData?.employee_detail?.salary,
    bankName: selectValue,
    accountHolderName: employeeData?.bank_details?.account_holder,
    accountNumber: employeeData?.bank_details?.account_number,
  };
  const handleFormSubmit = (values) => {
    const formData = new FormData();
    formData.append("pin", values.pin);
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("payroll", values.payroll);
    formData.append("salary", values.salary);
    formData.append("file", values.file);
    formData.append("contact", values.contact);
    formData.append("salutation", values.salutation);
    formData.append("bank_name", values.bankName);
    formData.append("account_holder", values.accountHolderName);
    formData.append("account_number", values.accountNumber);
    dispatch(updateEmployee(id, formData));
  };
  const handleBlock = () => {
    dispatch(changeEmployeeStatus(id, { status: 1 }));
  };
  const handleUnblock = () => {
    dispatch(changeEmployeeStatus(id, { status: -1 }));
  };
  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        height: "100%",
      }}
    >
      <Card
        sx={(theme) => ({
          height: "calc(100% - 60px)",
          overflow: "scroll",
          [theme.breakpoints.down("lg")]: {
            height: "fit-content",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "16px",
          }}
        >
          <Profile>Employee Details</Profile>
          <Box width={"200px"}>
            {employeeData?.admin_blocked == 1 ? (
              <Button title={"Unblock this Account"} onChange={handleUnblock} />
            ) : (
              <Button
                title={"Block this Account"}
                onChange={handleBlock}
                style={{ backgroundColor: "#bb2626" }}
              />
            )}
          </Box>
        </Box>

        <Box mb={3}>
          <Account>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DetailsContainer>
                  <Requested> PREFERRED LANGUAGE</Requested>
                  <Details>{employeeData?.prefered_language}</Details>
                </DetailsContainer>
              </Grid>
              <Grid item xs={6}>
                <DetailsContainer>
                  <Requested> COMPANY NAME</Requested>
                  <Details>{employeeData?.company_name}</Details>
                </DetailsContainer>
              </Grid>
              <Grid item xs={6}>
                <DetailsContainer>
                  <Requested>VERIFIED</Requested>
                  <Details>
                    {employeeData?.admin_approved == 1 ? (
                      <DoneIcon color="green" />
                    ) : (
                      <CloseIcon color="red" />
                    )}
                  </Details>
                </DetailsContainer>
              </Grid>
              <Grid item xs={6}>
                <DetailsContainer>
                  <Requested> BLOCKING STATUS </Requested>
                  <Details>
                    {employeeData?.admin_blocked == 1 ? (
                      <CloseIcon color="red" />
                    ) : (
                      <DoneIcon color="green" />
                    )}
                  </Details>
                </DetailsContainer>
              </Grid>
            </Grid>
          </Account>
        </Box>
        <Account>
          <Box width={"150px"} pb={2}>
            {isEditable ? (
              <Button title={"Cancel"} onClick={() => setEditable(false)} />
            ) : (
              <Button title={"Edit"} onClick={() => setEditable(true)} />
            )}
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values, actions) => handleFormSubmit(values)}
          >
            {(props) => <EditEmployeeForm isEditable={isEditable} {...props} />}
          </Formik>
        </Account>
      </Card>
    </Container>
  );
};

export default ViewEmployeeDetails;
