import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
  useTheme,
} from "@mui/material";
import {
  PasswordContainer,
  TermsAndConditions,
  AcceptTerms,
  Title,
} from "./styles";
import TextInput from "../../components/TextInput";
import { Button } from "../../components/Buttons";
import Card from "../../components/Card";
import * as yup from "yup";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import OTPVerificationDialog from "./SmsOtpVerificationDialog";
import { getTermsAndConditions, setPassword } from "../../store/Apis";
import { useDispatch, useSelector } from "react-redux";
import { EMPLOYEE } from "../../constants/userTypes";
import { decode } from "html-entities";
import { ClassNames } from "@emotion/react";
import { makeStyles } from "@material-ui/styles";
import theme from "../../theme";
const useStyles = makeStyles(() => ({
  flexBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    gap: "20px",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));

const SetPassword = () => {
  const theme = useTheme();
  const history = useHistory();
  const user = useSelector((state) => state.persist.userData);
  const user_id = useSelector((state) => state.persist.userData.id);
  const role = useSelector((state) => state.persist.userData.role);
  const termsAndConditions = useSelector(
    (state) => state.common.termsAndCondtions
  );

  const acceptTermsAndCondition = useSelector(
    (state) => state.employer.acceptTermsAndConditionsStatus
  );
  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  const [openDialog, setDialog] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTermsAndConditions(role));
  }, []);

  const validationSchema = yup.object({
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long"),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .min(6, "Password must be at least 6 characters long")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    TermsAndConditions: yup
      .boolean()
      .oneOf([true], "Please accept Terms and Conditions to proceed"),
    // .required("Please accept Terms and Conditions to proceed"),
  });

  const initialValues = {
    password: "",
    confirmPassword: "",
    TermsAndConditions: false,
  };

  const handleFormSubmit = (values) => {
    values.user_id = user_id;
    dispatch(setPassword(values, user, history));
  };

  useEffect(() => {
    if (acceptTermsAndCondition) {
      setDialog(false);
    }
  }, [acceptTermsAndCondition]);
  const classes = useStyles();
  return (
    <PasswordContainer>
      <Box width={"100%"}>
        <Card>
          <Title>Enter Your Password</Title>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => handleFormSubmit(values)}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Box className={classes.flexBox}>
                  <Box width={"100%"}>
                    <TextInput
                      name={"password"}
                      value={props.values.smsOtp}
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      inputlabel={"Password"}
                      type={"password"}
                      error={Boolean(props.errors.password)}
                      helperText={
                        props.touched.password && props.errors.password
                      }
                    />
                  </Box>
                  <Box width={"100%"}>
                    <TextInput
                      name={"confirmPassword"}
                      value={props.values.emailOtp}
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      inputlabel={"Confirm Password"}
                      type={"password"}
                      error={Boolean(props.errors.confirmPassword)}
                      helperText={
                        props.touched.confirmPassword &&
                        props.errors.confirmPassword
                      }
                    />
                  </Box>
                </Box>
                <Divider
                  sx={{ paddingTop: "25px", width: "25%", margin: "auto" }}
                />
                <Typography
                  color={theme.palette.black}
                  paddingTop={2.5}
                  paddingBottom={2}
                >{`Terms & Conditions`}</Typography>
                <TermsAndConditions>
                  <p>{renderHTML(decode(termsAndConditions?.text))}</p>
                </TermsAndConditions>
                <AcceptTerms>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="TermsAndConditions"
                        checked={props.values.TermsAndConditions}
                        onChange={(e) => {
                          setDialog(e.target.checked);
                          props.setFieldValue(
                            "TermsAndConditions",
                            e.target.checked
                          );
                        }}
                      />
                    }
                    label="Accept Terms and Conditions"
                  />
                </AcceptTerms>
                {props.touched.TermsAndConditions &&
                  props.errors.TermsAndConditions && (
                    <Box
                      sx={{
                        color: "#CF5951",
                        textAlign: "left",
                        margin: "4px 14px 0px 14px",
                        fontSize: " 0.75rem",
                        fontWeight: 400,
                      }}
                    >
                      {props.errors.TermsAndConditions}
                    </Box>
                  )}
                <Box pt={1.5} width={"200px"} margin={"auto"}>
                  <Button disableRipple title={"Save"} type="submit" />
                </Box>
                {openDialog && role === "employer" && (
                  <OTPVerificationDialog
                    open={openDialog}
                    handleClose={() => {
                      setDialog(false);
                      props.setFieldValue("TermsAndConditions", false);
                    }}
                  />
                )}
              </form>
            )}
          </Formik>
        </Card>
      </Box>
    </PasswordContainer>
  );
};

export default SetPassword;
