import { Button, Card, FilledInput, FormControl, TextField } from '@mui/material'
import TextInput from "../../components/TextInput"
import React from 'react'
import { Formik } from 'formik'
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import { setSalaryRange } from '../../store/Apis';

const SalaryRangeForm = () => {
  const dispatch = useDispatch()
  

  const validationSchema = yup.object({
   min_range:yup.string().required("*Required*"),
   max_range:yup.string().required("*Required*"),
   charges:yup.string().required("*Required*"),
  });

  const initialValues = {
    min_range:'',
    max_range:'',
    charges:''
  };
  const handleFormSubmit = (values, actions) => {
    dispatch(setSalaryRange(values));
     actions.resetForm();
      
  };

  return (
    
      <Card sx={{padding:"40px 20px"}}>
      <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values, actions) => {
              handleFormSubmit(values,actions);
            }}
          >
            {(props) => (
      <form onSubmit={props.handleSubmit}>
          <FormControl fullWidth sx={{ mt:1 }} variant="filled">
           <TextInput
            name={"min_range"}
            sx={{ fontSize: "13px" }}
            onChange={props.handleChange}
            inputlabel={"Salary Lower Range"}
            type={"number"}
            value={props.values.min_range}
            error={props.touched.min_range && props.errors.min_range}
            helperText={props.touched.min_range && props.errors.min_range}
          />
         
        </FormControl>
        <FormControl fullWidth sx={{ mt:1 }} variant="filled">
        <TextInput
            name={"max_range"}
            sx={{ fontSize: "13px" }}
            inputlabel={"Salary Upper Range"}
            onChange={props.handleChange}
            type={"number"}
            value={props.values.max_range}
            
            
            error={props.touched.max_range && props.errors.max_range}
            helperText={props.touched.max_range && props.errors.max_range}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt:1,mb:1 }} variant="filled">
        <TextInput
            name={"charges"}
            sx={{ fontSize: "13px" }}
            onChange={props.handleChange}
            inputlabel={"Charges"}
            type={"number"}
            value={props.values.charges}
            
            error={props.touched.charges && props.errors.charges}
            helperText={props.touched.charges && props.errors.charges}
          />
        </FormControl>
        <Button variant="contained" size="large" fullWidth type='submit'>
          Save
        </Button>

 </form>
            )}
            </Formik>
    </Card>
  )
}

export default SalaryRangeForm