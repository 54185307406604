import {
  Box,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "../../components/Buttons";
import DialogBox from "../../components/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeSalary, addNewRequest } from "../../store/Apis";
import theme from "../../theme";
import moment from "moment";
import formatNumber from "../../helper/formatNumber";
import { SNACKBAR_OPEN } from "../../store/constants/common";
import { ERROR, SUCCESS } from "../../constants/snackbarTypes";

const SendNewRequestPopup = (props) => {
  const { open, handleClose } = props;
  const date = new Date();
  const [pacakage, setPacakge] = useState("p1");
  const [paymentMethod, setPaymentMethod] = useState();
  const dispatch = useDispatch();
  const salary = useSelector((state) => state.employee.salary);
  useEffect(() => {
    dispatch(getEmployeeSalary());
  }, []);

  const handleAddNewRequest = () => {
    if (paymentMethod) {
      dispatch(
        addNewRequest({ package: pacakage, payment_method: paymentMethod })
      );
    }
    dispatch({
      type: SNACKBAR_OPEN,
      payload: {
        snackbarType: ERROR,
        message: "Please select a payment method",
      },
    });
  };
  return (
    <DialogBox
      title={"Send your request"}
      open={open}
      handleClose={handleClose}
      sx={{ with: "100%" }}
      max-width="100px"
    >
      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            borderRadius: "5px",
            border: "1px solid rgba(0, 0, 0, 0.06)",
            width: { md: "526px", sm: "fit-content" },
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.secondary.main,
              padding: "16px",
            }}
          >
            Your Salary
          </Box>
          <Box
            sx={{
              padding: "16px",
              display: "flex",
              gap: "10px",
              // width: "762px",
            }}
          >
            <Box>{formatNumber(salary?.salary)}</Box>
          </Box>
        </Box>
        <Box
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#3F3C3C",
            padding: "30px 0px",
          }}
        >
          {`Get 1x Advance per Month for ${moment(date).format("MMMM, YYYY")}`}
        </Box>
        <Box
          sx={{
            border: "1px solid #D0D0D0",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="p1"
            name="radio-buttons-group"
            onChange={(e) => setPacakge(e.target.value)}
          >
            <FormControlLabel
              value="p1"
              control={<Radio />}
              label={`After Week 1: Get ${formatNumber(salary?.salary * 0.25)}`}
            />
            <FormControlLabel
              value="p2"
              control={<Radio />}
              label={`After Week 2: Get  ${formatNumber(salary?.salary * 0.5)}`}
            />
          </RadioGroup>
        </Box>
        <Box
          sx={{
            padding: "20px",
          }}
        >
          <Typography variant="subtitle1">Payment Method</Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="p1"
            name="radio-buttons-group"
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <Box display={"flex"}>
              {" "}
              <FormControlLabel
                value="bank"
                control={<Radio />}
                label={"Bank"}
              />
              <FormControlLabel
                value="m_pesa"
                control={<Radio />}
                label={"M-PESA"}
              />
            </Box>
          </RadioGroup>
        </Box>

        <Box pt={"30px"}>
          <Button
            disableRipple
            title={"Submit Request"}
            onClick={handleAddNewRequest}
          />
        </Box>
        <Box width={"90%"} margin="auto" padding={"20px"}>
          <Typography variant="p" sx={{ fontSize: "15px", width: "50px" }}>
            Remaining salary on your pay day minus a service transaction fee of
            &nbsp;
            {formatNumber(salary?.charges)} (incl VAT). (see Terms And
            Conditions for Details)
          </Typography>
        </Box>
      </DialogContent>
    </DialogBox>
  );
};

export default SendNewRequestPopup;
