import {
  Box,
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  TextField,
} from "@mui/material";
import React from "react";
import { Button } from "../../components/Buttons";
import { Container } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Account, Profile } from "../viewEmployeeDetails/styles";
import moment from "moment";
import { Details, Requested, DetailsContainer } from "./styles";
import Card from "../../components/Card";
import { getEmployeeRequest } from "../../store/Apis";
import { changeEmployeeRequestStatus } from "../../store/Apis";
import { useEffect } from "react";
import { useState } from "react";
import {
  APPROVED,
  REQUEST_COMPLETED,
  PENDING,
  CANCELLED,
  PAID,
  DENIED,
} from "../../constants/requestStatus";
import formatNumber from "../../helper/formatNumber";

const PaymentLedgerEmployer = ({ requestData }) => {
  const blockStatus = useSelector(
    (state) => state?.persist?.userData?.admin_blocked
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [moreInfo, setMoreInfo] = useState("");

  const requestStatus = useSelector(
    (state) => state.employer.changeEmployeeRequestStatus
  );
  console.log(requestStatus);
  useEffect(() => {
    dispatch(getEmployeeRequest(id));
  }, []);
  useEffect(() => {
    if (requestStatus === "success") {
      dispatch(getEmployeeRequest(id));
    }
  }, [requestStatus]);

  const remainingSalary =
    requestData?.employee_details?.employee_detail?.salary -
    requestData?.service_charges -
    requestData?.amount;
  const remainingDues = requestData?.service_charges + requestData?.amount;
  const columns = [
    {
      id: "#",
      label: "#",
      align: "center",
    },
    { id: "PAYMENT", label: "PAYMENT", align: "center" },
    {
      id: "requested on",
      label: "REQUESTED ON",
      align: "center",
    },
    {
      id: "status",
      label: "STATUS",
      align: "center",
    },
    {
      id: "paid",
      label: "PAID ON",
      align: "center",
    },
    {
      id: "transaction",
      label: "TRANSACTION ID",
      align: "center",
    },
  ];
  function updateRequest(e) {
    e.preventDefault();

    dispatch(
      changeEmployeeRequestStatus(
        {
          status: status,
          more_info: status == "more info" ? moreInfo : null,
        },
        id
      )
    );
  }
  const setBackgroundColor = (value) => {
    if (value === PENDING) {
      return "rgb(228 205 13 / 88%)";
    } else if (value === DENIED) {
      return "rgb(220 48 38)";
    } else if (value === APPROVED || value === PAID) {
      return "rgb(32 133 27 / 1)";
    } else {
      return "rgb(57 117 57)";
    }
  };
  return (
    <div>
      {" "}
      {requestData?.request_status == PENDING && (
        <Grid item xs={6} sx={{ mt: 5, width: "40%" }} marginX="auto">
          <Profile>
            Request Status
            <span
              style={{ color: "green", fontSize: "15px", marginLeft: "10px" }}
            >
              {requestData?.request_status}
            </span>
          </Profile>
          {blockStatus === -1 && (
            <>
              <Typography
                sx={{ marginTop: "20px" }}
                fontSize={18}
                textAlign={"left"}
                color="#3F3C3C"
              >
                Update the status of this request
              </Typography>
              <Box
                sx={{ display: "flex", alignItems: "Left", color: "#3F3C3C" }}
              >
                <form onSubmit={updateRequest}>
                  <FormControl>
                    <RadioGroup row sx={{ textAlign: "left" }}>
                      <FormControlLabel
                        value="request approved"
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                        control={<Radio />}
                        label="Accepted"
                      />
                      <FormControlLabel
                        value="request denied"
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                        control={<Radio />}
                        label="Rejected"
                      />
                      <FormControlLabel
                        value="more info"
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                        control={<Radio />}
                        label="More info"
                      />
                    </RadioGroup>
                    {status == "more info" && (
                      <TextField
                        placeholder="Type something..."
                        multiline
                        maxRows={6}
                        onChange={(e) => {
                          setMoreInfo(e.target.value);
                        }}
                      />
                    )}
                    <Box py={2} width={"200px"}>
                      <Button title={"Submit"} type="submit" />
                    </Box>
                  </FormControl>
                </form>
              </Box>
            </>
          )}
        </Grid>
      )}
      {(requestData?.request_status == APPROVED ||
        requestData?.request_status == REQUEST_COMPLETED) && (
        <Box sx={{ width: "100%", height: "30%" }}>
          <Profile sx={{ mt: 5, mb: 2 }}>
            Request Status{" "}
            <span style={{ color: "green", fontSize: "15px" }}>
              {requestData?.request_status}
            </span>
          </Profile>
          <Account>
            <Typography
              sx={{ marginTop: "10px" }}
              fontSize={18}
              textAlign={"left"}
            >
              Advance Payment Ledger
            </Typography>
            <TableContainer
              sx={{ maxHeight: "calc(100vh - 100px)", position: "relative" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        paddingTop: "40px",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>

                <TableBody>
                  <TableRow>
                    <TableCell align={"center"}>1</TableCell>
                    <TableCell align={"center"}>
                      {formatNumber(requestData?.amount)}
                    </TableCell>
                    <TableCell align={"center"}>
                      {" "}
                      {moment(requestData?.created_at).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align={"center"}>
                      <div
                        style={{
                          background: setBackgroundColor(
                            requestData.request_status
                          ),
                          padding: "4px 20px",
                          borderRadius: "5px",
                          maxWidth: "90px",
                          margin: "auto",
                          color: "#fff",
                        }}
                      >
                        {requestData.request_status === APPROVED
                          ? "APPROVED"
                          : requestData.request_status === CANCELLED
                          ? "CANCELLED"
                          : requestData.request_status.toUpperCase()}
                      </div>
                    </TableCell>
                    <TableCell align={"center"}>
                      {requestData?.approved_at &&
                        moment(requestData?.approved_at).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell align={"center"}>
                      {requestData?.payment_proof}
                    </TableCell>
                    <TableCell align={"center"}>
                      {requestData?.more_info}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Account>
          <Grid spacing={1} container sx={{ mt: 5 }}>
            <Grid item xs={12}>
              <Box
                sx={{
                  height: "50px",
                  backgroundColor: "lightblue",
                  padding: "10px",
                }}
                borderRadius="5px"
              >
                <Typography
                  sx={{
                    marginTop: "5px",
                  }}
                  fontSize={15}
                  textAlign={"left"}
                >
                  Your remaining salary to your employee
                </Typography>
                <Typography
                  sx={{ marginTop: "5px" }}
                  fontSize={15}
                  textAlign={"left"}
                >
                  {formatNumber(
                    requestData?.employee_details?.employee_detail?.salary
                  )}{" "}
                  - {formatNumber(requestData?.service_charges)} -{" "}
                  {formatNumber(requestData?.amount)}=
                  <span style={{ fontWeight: "700" }}>
                    {formatNumber(remainingSalary)}
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  height: "50px",
                  backgroundColor: "lightblue",
                  padding: "10px",
                }}
                borderRadius="5px"
              >
                <Typography
                  sx={{
                    marginTop: "5px",
                  }}
                  fontSize={15}
                  textAlign={"left"}
                >
                  Your Due payments to Advance Salo
                </Typography>
                <Typography
                  sx={{ marginTop: "5px" }}
                  fontSize={15}
                  textAlign={"left"}
                >
                  {formatNumber(requestData?.service_charges)} +{" "}
                  {formatNumber(requestData?.amount)}=
                  <span style={{ fontWeight: "700" }}>
                    {" "}
                    {formatNumber(remainingDues)}
                  </span>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default PaymentLedgerEmployer;
