import React from "react";
import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import { Button } from "../../components/Buttons";
import TextInput from "../../components/TextInput";
import { Container } from "@mui/material";
import { Account, Password, Profile } from "./styles";
import { updateProfile, changePassword } from "../../store/Apis";
import { useHistory } from "react-router-dom";
import Card from "../../components/Card";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import MobileNumberInput from "../../components/mobileNumberInput";
import { UPDATE_USER_DETAILS } from "../../store/constants/common";
import {
  DetailsContainer,
  Requested,
  Details,
} from "../viewEmployeeDetails/styles";
import formatNumber from "../../helper/formatNumber";
import { EMPLOYEE } from "../../constants/userTypes";

const AccountSetting = () => {
  let employeeData;
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.persist.userData);

  if (user.role === EMPLOYEE) {
    employeeData = JSON.parse(user.employee_detail?.bank);
  }

  const validationSchema = yup.object({
    first_name: yup.string().required("First name is required*"),
    last_name: yup.string().required("Last name is required*"),
    contact_number: yup.string().required("Contact Number is required*"),
  });

  const initialValues = {
    first_name: user.fname,
    last_name: user.lname,
    contact_number: user.contact,
  };

  const changePasswordValidation = yup.object({
    password: yup
      .string()
      .required("Current password  is required*")
      .min(6, "Password must be at least 6 characters long"),
    new_password: yup
      .string()
      .required("New password  is required*")
      .min(6, "Password must be at least 6 characters long"),
    confirm_password: yup
      .string()
      .required("Confirm password is required*")
      .min(6, "Password must be at least 6 characters long")
      .oneOf([yup.ref("new_password"), null], "Passwords must match"),
  });

  const changePasswordInitialValues = {
    password: "",
    new_password: "",
    confirm_password: "",
  };

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        height: "100%",
      }}
    >
      <Card
        sx={(theme) => ({
          height: "calc(100% - 60px)",
          [theme.breakpoints.down("lg")]: {
            height: "fit-content",
          },
        })}
      >
        {user.role === EMPLOYEE && (
          <Box mb={3}>
            <Account>
              <Profile>Employee Details</Profile>
              <Grid container spacing={2}>
                <Grid item sm={12} md={6} width="100%">
                  <DetailsContainer>
                    <Requested> Bank Name</Requested>
                    <Details>{employeeData?.bank_name}</Details>
                  </DetailsContainer>
                </Grid>
                <Grid item sm={12} md={6} width="100%">
                  <DetailsContainer>
                    <Requested> Account Holder</Requested>
                    <Details>{employeeData?.account_holder}</Details>
                  </DetailsContainer>
                </Grid>
                <Grid item sm={12} md={6} width="100%">
                  <DetailsContainer>
                    <Requested> Account Number</Requested>
                    <Details>{employeeData?.account_number}</Details>
                  </DetailsContainer>
                </Grid>
                <Grid item sm={12} md={6} width="100%">
                  <DetailsContainer>
                    <Requested> Salary</Requested>
                    <Details>
                      {formatNumber(user?.employee_detail?.salary)}
                    </Details>
                  </DetailsContainer>
                </Grid>
              </Grid>
            </Account>
          </Box>
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            dispatch({
              type: UPDATE_USER_DETAILS,
              payload: {
                fname: values.first_name,
                lname: values.last_name,
                contact: values.contact_number,
              },
            });
            dispatch(updateProfile(values));
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Account>
                <Grid container spacing={2}>
                  <Grid item md={4} sm={12} width="100%">
                    <TextInput
                      name={"first_name"}
                      sx={{ fontSize: "13px" }}
                      inputlabel={"First Name"}
                      value={props.values.first_name}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.first_name && props.errors.first_name
                      }
                      helperText={
                        props.touched.first_name && props.errors.first_name
                      }
                    />
                  </Grid>
                  <Grid item md={4} sm={12} width="100%">
                    <TextInput
                      name={"last_name"}
                      sx={{ fontSize: "13px" }}
                      inputlabel={"Last Name"}
                      value={props.values.last_name}
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      error={props.touched.last_name && props.errors.last_name}
                      helperText={
                        props.touched.last_name && props.errors.last_name
                      }
                    />
                  </Grid>
                  <Grid item md={4} sm={12} width="100%">
                    <MobileNumberInput
                      onChange={(e, f) =>
                        props.setFieldValue("contact_number", `+${e}`)
                      }
                      touched={
                        props.touched.contact_number &&
                        props.errors.contact_number
                      }
                      value={props.values.contact_number}
                      inputlabel={"Mobile Number"}
                      name={"contact_number"}
                      error={
                        props.touched.contact_number &&
                        props.errors.contact_number
                      }
                    />
                  </Grid>
                </Grid>
                <Box pb={"10px"} textAlign={"initial"}></Box>
                <Box py={2} width={"200px"} margin={"auto"}>
                  <Button title={"Update Profile"} type="submit" />
                </Box>
              </Account>
            </form>
          )}
        </Formik>
        <Formik
          initialValues={changePasswordInitialValues}
          validationSchema={changePasswordValidation}
          onSubmit={(values, actions) => {
            delete values.confirm_password;
            dispatch(changePassword(values));
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Box pt={4}>
                <Profile>Change Password</Profile>
              </Box>

              <Password>
                <Grid container spacing={2}>
                  <Grid item md={4} sm={12} width="100%">
                    <TextInput
                      name={"password"}
                      type="password"
                      sx={{ fontSize: "13px" }}
                      inputlabel={"Current Password"}
                      value={props.values.password}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.touched.password && props.errors.password}
                      helperText={
                        props.touched.password && props.errors.password
                      }
                    />
                  </Grid>
                  <Grid item md={4} sm={12} width="100%">
                    <TextInput
                      name={"new_password"}
                      sx={{ fontSize: "13px" }}
                      inputlabel={"New Password"}
                      type="password"
                      value={props.values.new_password}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.new_password && props.errors.new_password
                      }
                      helperText={
                        props.touched.new_password && props.errors.new_password
                      }
                    />
                  </Grid>
                  <Grid item md={4} sm={12} xs={12} width="100%">
                    <TextInput
                      name={"confirm_password"}
                      sx={{ fontSize: "13px" }}
                      inputlabel={"Confirm Password"}
                      type="password"
                      value={props.values.confirm_password}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.confirm_password &&
                        props.errors.confirm_password
                      }
                      helperText={
                        props.touched.confirm_password
                          ? props.errors.confirm_password
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                <Box py={2} width={"200px"} margin={"auto"}>
                  <Button title={"Change Password"} type={"submit"} />
                </Box>
              </Password>
            </form>
          )}
        </Formik>
      </Card>
    </Container>
  );
};

export default AccountSetting;
