import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeesList } from "../../store/Apis";
import Row from "./Row";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import IconButton from "@mui/material/IconButton";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import theme from "../../theme";
import { useHistory, useParams } from "react-router-dom";
import { EMPLOYER } from "../../constants/userTypes";

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& .MuiTableCell-root": {
    fontWeight: "bold",
  },
}));

const columns = [
  {
    id: "name",
    label: "Name",
    align: "center",
  },
  { id: "salary", label: "Net Salary", align: "center" },
  { id: "number_of_requests", label: "Number Of Requests", align: "center" },
  {
    id: "contact",
    label: "Mobile Num",
    align: "center",
  },
  {
    id: "employee_detail.payroll",
    label: "Payroll Number",
    align: "center",
  },
  // {
  //   id: "of_request",
  //   label: "Of Request",
  //   align: "center",
  // },
  {
    id: "permission_status",
    label: "Permission Status",
    align: "center",
  },
  {
    id: "actions",
    label: "",
  },
];

const ITEM_HEIGHT = 48;

export default function DataTable() {
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const params = useParams();
  const employeesList = useSelector((state) => state.employer.myEmployees);
  const user = useSelector((state) => state?.persist?.userData);

  const { id } = params;

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    let employer_id = user?.role === EMPLOYER ? user?.id : id;
    dispatch(getEmployeesList(employer_id));
  }, [user, params]);

  const setBackgroundColor = (value) => {
    if (value === "pending") {
      return "rgb(228 205 13 / 88%)";
    } else if (value === "approved") {
      return "rgb(57 117 57)";
    } else {
      return "rgb(220 48 38)";
    }
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Paper sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 130px)", position: "relative" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <StyledTableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, paddingTop: "40px" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {employeesList &&
                employeesList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return <Row key={index} row={row} />;
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={employeesList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
