import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import theme from "../../theme";
import { makeStyles } from "@material-ui/styles";
import SideNav from "../SideNav";
import Navbar from "../Navbar";
import { useSelector } from "react-redux";
import AddBankDetails from "../AddBankDeatils";
import { EMPLOYEE } from "../../constants/userTypes";

const useStyles = makeStyles(() => ({
  icon: {
    padding: "8px",
    borderRadius: "25px",
    background: theme.palette.gradient,
    color: "#fff",
    transitionProperty: "all",
    transitionDuration: "200ms",
    transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    "&:hover": {
      transform: "scale(1.3)",
    },
  },
  SideNav: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  Grid: {
    display: "grid",
    gridTemplateColumns: "304px 1fr",
    height: "calc(100vh - 70px)",
    columnGap: "10px",
    padding: "10px",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      height: "calc(fit-content)",
    },
  },
}));

const AuthUserLayout = ({ children }) => {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.persist.userData);
  useEffect(() => {
    if (!user?.employee_detail?.bank && user.role === EMPLOYEE) {
      setOpen(true);
    }
  }, [user]);
  const classes = useStyles();
  return (
    <Container maxWidth={false} disableGutters>
      <Navbar />

      <Box className={classes.Grid}>
        <div className={classes.SideNav}>
          <SideNav />
        </div>

        <Box sx={{ overflow: "scroll", width: "100%" }}>{children}</Box>
        {open && (
          <AddBankDetails
            open={open}
            handleClose={() => {
              setOpen(false);
            }}
            setOpen={setOpen}
          />
        )}
      </Box>
    </Container>
  );
};

export default AuthUserLayout;
