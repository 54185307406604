import React, { useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";
import formatNumber from "../../helper/formatNumber";

const Row = ({ row, index }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const ITEM_HEIGHT = 48;
  const history = useHistory();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
      <TableCell key={index} align={"center"}>
        {`${row.fname} ${row.lname}`}
      </TableCell>
      <TableCell key={index} align={"center"}>
        {formatNumber(row.employee_detail?.salary)}
      </TableCell>
      <TableCell key={index} align={"center"}>
        {row?.employee_detail?.requests_count}
      </TableCell>
      <TableCell key={index} align={"center"}>
        {row.contact}
      </TableCell>
      <TableCell key={index} align={"center"}>
        {row.employee_detail?.payroll}
      </TableCell>
      <TableCell key={index} align={"center"}>
        {/* <div
          style={{
            background: setBackgroundColor(
              row?.permission_status
            ),
            padding: "4px 20px",
            borderRadius: "5px",
            maxWidth: "90px",
            margin: "auto",
            color: theme.palette.common.white,
          }}
        >
          {" "}
          APPROVED
        </div> */}
      </TableCell>
      <TableCell key={index} align={"center"}>
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "16ch",
                boxShadow:
                  "0 19px 38px rgba(0,0,0,0), 0 6px 8px rgba(0,0,0,0.1)",
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MenuItem
              key={index}
              onClose={handleClose}
              onClick={() => {
                history.push(`/view-employee-details/${row.id}`);
              }}
              sx={{ fontSize: "15px" }}
            >
              Employee Details
            </MenuItem>
          </Menu>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default Row;
