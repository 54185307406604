import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Paper from "@mui/material/Paper";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../components/TextInput";
import { getAllEmployers } from "../../store/Apis";
import { Link, useHistory } from "react-router-dom";
import Input from "@mui/material";
import { Search, TableRows } from "@mui/icons-material";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import CloseIcon from "@mui/icons-material/Close";

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& .MuiTableCell-root": {
    fontWeight: "bold",
  },
}));

const columns = [
  {
    id: "companyName",
    label: "Company Name",
    align: "center",
  },
  {
    id: "Name",
    label: "Name",
    align: "center",
  },

  // {
  //   id: "of_request",
  //   label: "Of Request",
  //   align: "center",
  // },
  {
    id: "MobileNumber",
    label: "Mobile Number",
    align: "center",
  },
  {
    id: "permissionstatus",
    label: "Permission Status",
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    align: "center",
  },
];

const ITEM_HEIGHT = 48;

export default function DataTable() {
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState({ element: null, key: null });
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const allEmployers = useSelector((state) => state.admin.allEmployers);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getAllEmployers());
  }, []);

  const setBackgroundColor = (value) => {
    if (value === "pending") {
      return "rgb(228 205 13 / 88%)";
    } else if (value === "approved") {
      return "rgb(57 117 57)";
    } else {
      return "rgb(220 48 38)";
    }
  };
  const open = Boolean(anchorEl.element);
  const handleClick = (event, key) => {
    setAnchorEl({ element: event.currentTarget, key: key });
  };
  const handleClose = () => {
    setAnchorEl({ element: null, key: null });
  };
  return (
    <>
      <Paper sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
        <Typography variant="h6" style={{ padding: "40px", textAlign: "left" }}>
          My Employers
        </Typography>
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 130px)", position: "relative" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <StyledTableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, paddingTop: "40px" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {allEmployers &&
                allEmployers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                  
                    return (
                      <TableRow row={row} key={index}>
                        <TableCell align={"center"}>
                          {row.company_name}
                        </TableCell>
                        <TableCell align={"center"}>
                          {row.salutation} {row.fname} {row.last_name}
                        </TableCell>

                        <TableCell align={"center"}>{row.contact}</TableCell>

                        <TableCell align={"center"}>
                          {row.admin_approved === 1 ? (
                            <FileDownloadDoneIcon />
                          ) : (
                            <CloseIcon />
                          )}
                        </TableCell>
                        <TableCell sx={{ textAlign: " -webkit-center" }}>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => handleClick(e, index)}
                            style={{ textAlign: " -webkit-center" }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          {anchorEl.key === index && (
                            <Menu
                              id={`long-menu-${index}`}
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl.element}
                              open={open}
                            
                              onClose={handleClose}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: "22ch",
                                  boxShadow:
                                    "0 19px 38px rgba(0,0,0,0), 0 6px 8px rgba(0,0,0,0.1)",
                                },
                              }}
                            >
                              <MenuItem
                                key={`${index}`}
                                sx={{ fontSize: "16px" }}
                                onClick={() => {
                                
                                  if (anchorEl.key === index) {
                                    history.push(`/employer-details/${row.id}`);
                                  }
                                }}
                              >
                                View Details
                              </MenuItem>

                              <MenuItem
                                key={"1"}
                                sx={{ fontSize: "16px" }}
                                onClick={() => {
                                  history.push(`/my-employees/${row.id}`);
                                }}
                              >
                                View Employees
                              </MenuItem>
                            </Menu>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={allEmployers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
