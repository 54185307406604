import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey.G100,
  // width: "fit-content",
  margin: "auto",
  borderRadius: "8px",
  background: "#fff",
  boxShadow: "0 14px 28px rgba(0,0,0,0.10), 0 5px 10px rgba(0,0,0,0.5)",
}));
