import "./App.css";
import React from "react";
import { ThemeProvider } from "@mui/material";
import UserProvider from "./context/userContext";
import { ToastProvider } from "./context/ToastContext";
import AppRoutes from "./Routes";

import Snackbar from "./components/Snackbar";
import Backdrop from "./components/Backdrop";
import theme from "./theme";

function App() {
  return (
    <div className="App">
      <ToastProvider>
        <UserProvider>
          <ThemeProvider theme={theme}>
            <Snackbar />
            <Backdrop />
            <AppRoutes />
          </ThemeProvider>
        </UserProvider>
      </ToastProvider>
    </div>
  );
}

export default App;
