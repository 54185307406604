import React from "react";
import page404 from "../../assets/page404.jpg";
import { Box } from "@mui/material";
import { Button } from "../../components/Buttons";
import { useHistory } from "react-router-dom";

function Page404() {
  const history = useHistory();

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <img src={page404} style={{ height: "100vh", width: "100%" }} />
        <Box
          sx={{
            width: "300px",
            position: "absolute",
            bottom: "4%",
            left: "45%",
          }}
        >
          <Button title={"Back To Home"} onClick={() => history.goBack()} />
        </Box>
      </Box>
    </>
  );
}

export default Page404;
