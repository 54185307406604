import { Interceptor } from "../../utils/interceptor";
import {
  ADD_NEW_REQUEST_SUCCESS,
  CANCEL_REQUEST_STATUS,
  MY_SALARY,
  MY_REQUESTS,
} from "../constants/Employee";
import {
  SNACKBAR_OPEN,
  SNACKBAR_CLOSE,
  LOADER_OPEN,
  LOADER_CLOSE,
  USER_DETAILS,
  ACCEPT_TERMS_AND_CONDITIONS,
} from "../constants/common";
import { ERROR, SUCCESS } from "../../constants/snackbarTypes";
import LocalStorageService from "../../utils/localStorageService";
import { loginRequest } from "./common";

// const userType = () => {
//   return LocalStorageService.getUserType();
// };

const axiosInstance = Interceptor();

export const EmployeeSignUp = (data, history) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("/auth/signup/employee", data)
    .then((res) => {
      LocalStorageService.setUserType(res?.data?.role);
      dispatch({
        type: USER_DETAILS,
        payload: res.data,
      });
      history.push("/verify-otp");
    })
    .catch((error) => {
      console.log(error, "Errror");
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const getEmployeeRequests = () => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get("/employee/requests")
    .then((res) => {
      dispatch({
        type: MY_REQUESTS,
        payload: res.data,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const getEmployeeSalary = () => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get("/employee/salary")
    .then((res) => {
      dispatch({
        type: MY_SALARY,
        payload: res.data,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const addNewRequest = (data, history) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("/employee/requests", { ...data, request_from: "web" })
    .then((res) => {
      dispatch({
        type: ADD_NEW_REQUEST_SUCCESS,
        payload: true,
      });
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: "Request successfull",
        },
      });
      history.push("/my-requests");
    })
    .catch((error) => {
      dispatch({
        type: ADD_NEW_REQUEST_SUCCESS,
        payload: false,
      });
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const setPassword = (data, user, history) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("/auth/employee/password", data)
    .then((res) => {
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: "Password changed sucessfully",
        },
      });
      dispatch({
        type: ACCEPT_TERMS_AND_CONDITIONS,
        payload: true,
      });
      dispatch(
        loginRequest(
          {
            number: user.contact,
            password: data.password,
          },
          history
        )
      );
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const changePassword = (data, history) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("/change-password", data)
    .then((res) => {
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: "Password changed sucessfully",
        },
      });
      history.push("/my-requests");
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const submitEmployeeDetails = (formData, history) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("/auth/details", formData, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    })
    .then((res) => {
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: "Details added sucessfully",
        },
      });
      history.push("/set-password");
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};
export const submitBankDetails = (values, setOpen) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("/employee/bank-details", values)
    .then((res) => {
      dispatch({
        type: USER_DETAILS,
        payload: res.data.user,
      });
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: "Details added sucessfully",
        },
      });
      setOpen(false);
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const updateProfile = (data, history) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("/profile", data)
    .then((res) => {
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: "Profile updated sucessfully",
        },
      });
      // const path =
      //   userType === EMPLOYEE ? "/employee-login" : "/employer-login";
      // history.push(path);
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const cancelRequest = (data) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("/employee/delete-request", data)
    .then((res) => {
      dispatch({
        type: CANCEL_REQUEST_STATUS,
        payload: true,
      });
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: "Request cancelled",
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: CANCEL_REQUEST_STATUS,
        payload: false,
      });
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const updateEmployee = (id, data) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post(`/common/update/employe/${id}`, data)
    .then((res) => {})
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};
