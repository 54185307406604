import React, { useState } from "react";
import { Container } from "@mui/system";
import ReactQuill from "react-quill";
import { EMPLOYEE } from "../../constants/userTypes";
import { Button } from "../../components/Buttons";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { saveTermsAndContions } from "../../store/Apis";
import { useDispatch } from "react-redux";
import { encode, decode } from "html-entities";
import Toolbar, { modules, formats } from "./Toolbar";
import EditIcon from "@mui/icons-material/Edit";
import Card from "../../components/Card";
import moment from "moment";

const QuillEditor = ({ termsAndConditions }) => {
  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  const dispatch = useDispatch();
  const [terms, setTerms] = useState(decode(termsAndConditions.text));
  const [viewText, setViewText] = useState(false);

  const saveTerms = () => {
    dispatch(
      saveTermsAndContions({ role: termsAndConditions.role, text: terms })
    );
    setViewText(!viewText);
  };
  const switchView = () => {
    setViewText(!viewText);
  };
  return (
    <>
      {!viewText && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={4}
          p="20px"
        >
          {/* {!viewText && <Button title={'Edit'} style={{width:"100px", marginLeft:"20px"}} onClick={switchView}/>} */}
          <Typography variant="h4">
            LAST UPDATED{" "}
            {moment(termsAndConditions.updatedAt).format("YYYY-MM-DD")} (
            “Effective Date”)
          </Typography>
          <IconButton onClick={switchView}>
            <EditIcon
              sx={{ width: "30px", height: "30px", color: "#9F325A" }}
            />
          </IconButton>
        </Stack>
      )}
      <Card mb={5} mt={5} sx={{ height: "48vh", overflow: "auto" }}>
        {!viewText ? (
          <div>{renderHTML(decode(termsAndConditions.text))}</div>
        ) : (
          <Box>
            <Toolbar />
            <ReactQuill
              modules={modules}
              theme="snow"
              value={terms}
              onChange={setTerms}
              style={{ height: "35vh" }}
            />
            <Button
              title={"Save"}
              onClick={() => saveTerms()}
              style={{ width: "100px", float: "right", marginTop: "60px" }}
            />
          </Box>
        )}
      </Card>
    </>
  );
};

export default QuillEditor;
