import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { LoginContainer, Logo, SignUp, ForgotPass } from "./styles";
import { Box } from "@mui/material";
import { Button } from "../../components/Buttons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TextInput from "../../components/TextInput";
import { useHistory } from "react-router-dom";
import AppLogo from "../../assets/logo.png";
import Card from "../../components/Card";
import LocalStorageService from "../../utils/localStorageService";
import { loginRequest } from "../../store/Apis";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import MobileNumberInput from "../../components/mobileNumberInput";
import ResetPasswordPopup from "./ResetPasswordPopup";
import TermsDialog from "./AcceptTermsAndCondition";

const UserLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openDialog, setDialog] = useState(false);
  // const user = JSON.parse(LocalStorageService.getCurrentUser());
  const user = useSelector((state) => state.persist.userData);

  useEffect(() => {
 
    if (user) {
      if (user.accepted_terms == -1) {
        setDialog(true);
      }
    }
  }, [user]);

  const [openResetPassword, setResetPassword] = useState(false);

  const termsAndConditionsUpdated = useSelector(
    (state) => state.common.termsAndCondtionsUpdated
  );
 
  const validationSchema = yup.object({
    number: yup.string().required("Mobile number is required"),
    password: yup
      .string()
      .min(6, "Password must be atleast 6 charachters long")
      .required("Password is required"),
  });

  const initialValues = {
    number: "",
    password: "",
  };

  const handleUserRedirect = () => {
    history.push("/signup");
  };

  const HandleFormSubmit = (values) => {
    dispatch(loginRequest(values, history));
    if (user) {
      if (user.accepted_terms == -1) {
        setDialog(true);
      }
    }
  };

  

  return (
    <LoginContainer>
      <Card sx={{ width: "100%" }}>
        <Box width={"100%"}>
          <Box pb={10}>
            <Logo>
              <img src={AppLogo} alt="logo" />
            </Logo>
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={HandleFormSubmit}
            pathname
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Box pb={1.5} textAlign={"initial"}>
                  <MobileNumberInput
                    onChange={(e, f) => props.setFieldValue("number", `+${e}`)}
                    touched={props.touched.number && props.errors.number}
                    value={props.values.number}
                    inputlabel={"Mobile Number"}
                    name={"number"}
                    error={props.touched.number && props.errors.number}
                  />
                </Box>
                <Box pb={1.5} textAlign={"initial"}>
                  <TextInput
                    sx={{ fontSize: "13px" }}
                    value={props.values.password}
                    inputlabel={"Password"}
                    type="password"
                    name={"password"}
                    onChange={props.handleChange}
                    error={props.touched.password && props.errors.password}
                    helperText={props.touched.password && props.errors.password}
                  />
                </Box>
                <Box pt={2}>
                  <Button
                    disableRipple
                    title={"Login"}
                    id="loginBtn"
                    endIcon={<ArrowForwardIosIcon sx={{ fontSize: "14px" }} />}
                    type="submit"
                  />
                </Box>
              </form>
            )}
          </Formik>

          <ForgotPass onClick={() => setResetPassword(true)}>
            Forgot Password.
          </ForgotPass>
          <SignUp>
            Don’t have an account ?{" "}
            <span onClick={handleUserRedirect}>Sign Up</span>
          </SignUp>
        </Box>
      </Card>
      <ResetPasswordPopup
        open={openResetPassword}
        handleClose={() => setResetPassword(false)}
      />
      {setDialog && user !== null && (
        <TermsDialog
          open={openDialog}
          handleClose={() => {
            setDialog(false);
          }}
          user={user}
          setDialog={setDialog}
        />
      )}
    </LoginContainer>
  );
};

export default UserLogin;
