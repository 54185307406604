import {
  LOGIN_SUCCESS,
  MY_REQUESTS,
  MY_SALARY,
  ADD_NEW_REQUEST_SUCCESS,
  CANCEL_REQUEST_STATUS,
  EMPLOYEE_DATA
} from "../constants/Employee";
const initialState = {
  user: null,
  status: null,
  myRequests: [],
  salary: null,
  addNewRequestSuccess: false,
  cancelRequestStatus: false,
  employeeData: {}
};

export const EmployeeSlice = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: true,
        user: action.payload,
        status: 200,
      };
    case MY_REQUESTS:
      return {
        ...state,
        myRequests: action.payload,
      };
    case MY_SALARY:
      return {
        ...state,
        salary: action.payload,
      };
    case ADD_NEW_REQUEST_SUCCESS:
      return {
        ...state,
        addNewRequestSuccess: action.payload,
      };

    case CANCEL_REQUEST_STATUS:
      return {
        ...state,
        cancelRequestStatus: action.payload,
      };
    case EMPLOYEE_DATA:
      return{
        ...state,
        employeeData: action.payload
      }
    default:
      return state;
  }
};
