import React from "react";
import { Button } from "../../../components/Buttons";
import TextInput from "../../../components/TextInput";
import Select from "../../../components/Select";
import { Box, Grid } from "@mui/material";
import MobileNumberInput from "../../../components/mobileNumberInput";
import Autocomplete from "../../../components/AutoComplete";

const SignupForm = ({
  handleSubmit,
  touched,
  errors,
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  loading,
  ...rest
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Select
            name={"salutation"}
            options={["Mr.", "Ms.", "Mrs."]}
            selectlabel={"Salutation"}
            value={values.salutation}
            onChange={(e) => {
              setFieldValue("salutation", e.target.value);
            }}
            onBlur={handleBlur}
            error={Boolean(errors.salutation)}
            error_message={touched.salutation && errors.salutation}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            name={"first_name"}
            sx={{ fontSize: "13px" }}
            inputlabel={"First Name"}
            value={values.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.first_name)}
            helperText={touched.first_name && errors.first_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            name={"last_name"}
            sx={{ fontSize: "13px" }}
            inputlabel={"Last Name"}
            value={values.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.last_name)}
            helperText={touched.last_name && errors.last_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            name={"company_name"}
            inputlabel={"Company Name"}
            value={values.company_name}
            onChange={(e, companyName) =>
              setFieldValue("company_name", companyName)
            }
            onBlur={handleBlur}
            error={Boolean(errors.company_name)}
            helperText={touched.company_name && errors.company_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MobileNumberInput
            onChange={(e, f) => setFieldValue("contact", `+${e}`)}
            touched={touched.contact && errors.contact}
            value={values.contact}
            inputlabel={"Mobile Number"}
            name={"contact"}
            error={touched.contact && errors.contact}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            name={"prefered_language"}
            options={["English"]}
            value={"English"}
            selectlabel={"Prefered Language"}
            onChange={(e) => {
              setFieldValue("prefered_language", e.target.value);
            }}
            onBlur={handleBlur}
            error={Boolean(errors.prefered_language)}
            error_message={
              touched.prefered_language && errors.prefered_language
            }
          />
        </Grid>
      </Grid>
      <Box pb={"24px"} textAlign={"initial"}></Box>
      <Box py={2.5} width={"200px"} margin={"auto"}>
        <Button
          disableRipple
          title={"Register"}
          type="submit"
          isloading={loading}
        />
      </Box>
    </form>
  );
};
export default SignupForm;
