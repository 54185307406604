const nameSpace = "employee";

export const SIGNUP = `${nameSpace}/EMPLOYEE_SIGNUP`;
export const SIGNUP_SUCCESS = `${nameSpace}/EMPLOYEE_SIGNUP_SUCCESS`;
export const ERROR_SIGNUP = `${nameSpace}/ERROR_EMPLOYEE_SIGNUP`;
export const LOGIN = `${nameSpace}/LOGIN`;
export const LOGIN_SUCCESS = `${nameSpace}/LOGIN_SUCCESS`;
export const LOGIN_ERROR = `${nameSpace}/LOGIN_ERROR`;
export const MY_REQUESTS = `${nameSpace}/MY_REQUESTS`;
export const MY_SALARY = `${nameSpace}/MY_SALARY`;
export const ADD_NEW_REQUEST_SUCCESS = `${nameSpace}/ADD_NEW_REQUEST_SUCCESS`;
export const CANCEL_REQUEST_STATUS = `${nameSpace}/CANCEL_REQUEST_STATUS `;
export const EMPLOYEE_DATA = `${nameSpace}/EMPLOYEE_DATA `;
