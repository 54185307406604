import {
  MY_EMPLOYEES,
  RECEIVED_REQUESTS,
  CHANGE_EMPLOYEE_REQUEST_STATUS,
  ACCEPT_TERMS_CONDITIONS_STATUS,
  REQUEST_DATA,
  COUNTRIES,
  CITIES,
  CHANGE_EMPLOYEE_STATUS,
  BANKS,
} from "../constants/Employer";
const initialState = {
  user: null,
  status: null,
  myEmployees: [],
  receivedRequests: [],
  changeEmployeeRequestStatus: null,
  acceptTermsAndConditionsStatus: false,
  requestData: {},
  countries: [],
  cities: [],
  banks: [],
  termsAndConditionsStatus: {},
  employeeStatus: 0,
};

export const EmployerSlice = (state = initialState, action) => {
  switch (action.type) {
    case MY_EMPLOYEES:
      return {
        ...state,
        myEmployees: action.payload,
      };
    case RECEIVED_REQUESTS:
      return {
        ...state,
        receivedRequests: action.payload,
      };
    case CHANGE_EMPLOYEE_REQUEST_STATUS:
      return {
        ...state,
        changeEmployeeRequestStatus: action.payload,
      };
    case ACCEPT_TERMS_CONDITIONS_STATUS:
      return {
        ...state,
        acceptTermsAndConditionsStatus: action.payload,
      };
    case REQUEST_DATA:
      return {
        ...state,
        requestData: action.payload,
      };
    case COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case CITIES:
      return {
        ...state,
        cities: action.payload,
      };
    case BANKS:
      return {
        ...state,
        banks: action.payload,
      };
    case CHANGE_EMPLOYEE_STATUS:
      return {
        ...state,
        employeeStatus: action.payload,
      };

    default:
      return state;
  }
};
