import { styled } from "@mui/system";

export const LoginContainer = styled("div")(({ theme }) => ({
  margin: "auto",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "375px",
  width: "100%",
}));

export const FormWrapper = styled("div")(({ theme }) => ({
  width: "100%",
}));
export const Logo = styled("div")(({ theme }) => ({
  width: "150px",
  height: "100px",
  borderRadius: "50%",
  // background: theme.palette.grey.G300,
  display: "flex",
  alignItems: "center",
  margin: "auto",

  "& > img": {
    width: "150px",
    margin: "auto",
  },
  "& > div": {
    width: "100px",
    margin: "auto",
  },
}));
export const TermsAndConditions = styled("div")(({ theme }) => ({
  color: theme.palette.black,
  maxHeight: "160px",
  overflow: "auto",
  padding: "10px 5px",
  border: "1px solid rgba(101, 101, 101, 0.45)",
  textAlign: "left",
  "& > div": {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    color: theme.palette.black,
  },
  "&  p": {
    fontFamily: "Poppins",
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18px",
    color: "#616161",
  },
  "& span": {
    color: theme.palette.purple,
  },
}));

export const AcceptTerms = styled("div")(({ theme }) => ({
  color: theme.palette.black,
  width: "100%",
  textAlign: "start",
  "& .MuiTypography-root": {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "14px",
    color: theme.palette.text.main,
  },
}));

export const SignUp = styled("div")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: theme.palette.black,
  paddingTop: "40px",

  "& span": {
    color: theme.palette.purple,
    cursor: "pointer",
  },
}));

export const ForgotPass = styled("div")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: theme.palette.black,
  paddingTop: "20px",
  cursor: "pointer",
}));
export const ResendOTP = styled("div")(({ theme }) => ({
  margin: "5px 0px 0px 4px",
  color: theme.palette.purple,
  textAlign: "left",
  fontSize: "12px",
  cursor: "pointer",
}));
