import React from "react";
import { Card, Container, Typography } from "@mui/material";
import Table from "./Table";

const AllRequest = () => {
  return (
    <Container
    maxWidth={false}
    disableGutters
    sx={{
      height: "100%",
    }}
  >
    <Card padding="0px 0px" sx={{ width: "100%", height: "calc(100vh - 60px)" }}>
      <Table />
    </Card>
  </Container>
  );
};

export default AllRequest;