import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import TextInput from "./TextInput";
import * as yup from "yup";
import { Formik } from "formik";
import DialogBox from "./Dialog/index";
import SelectBank from "./Select/SelectBank";
import { Button } from "./Buttons";
import { useDispatch } from "react-redux";
import { submitBankDetails } from "../store/Apis";

const AddBankDetails = (props) => {
  const { open, handleClose, setOpen } = props;
  const theme = useTheme();
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    account_holder: yup.string().required("Account holder name is required"),
    account_number: yup.string().required("Account number is required"),
    bank_name: yup.string().required("Bank name is required"),
  });
  const handleFormSubmit = (values) => {
    dispatch(submitBankDetails(values, setOpen));
  };
  const initialValues = {
    account_holder: "",
    account_number: "",
    bank_name: "",
  };

  return (
    <DialogBox open={open} handleClose={handleClose} title={""}>
      <DialogTitle>Add Bank Details</DialogTitle>
      <Box width={"500px"} padding={"20px"} maxWidth="900px">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => handleFormSubmit(values)}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextInput
                    name="account_holder"
                    sx={{ fontSize: "13px" }}
                    inputlabel="Account Holder Full Name"
                    value={props.values.account_holder}
                    onChange={props.handleChange}
                    Account
                    Holder
                    Name
                    error={
                      props.touched.account_holder &&
                      props.errors.account_holder
                    }
                    helperText={
                      props.touched.account_holder &&
                      props.errors.account_holder
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    name="account_number"
                    sx={{ fontSize: "13px" }}
                    inputlabel="Account Number"
                    value={props.values.account_number}
                    onChange={props.handleChange}
                    Account
                    Holder
                    Name
                    error={
                      props.touched.account_number &&
                      props.errors.account_number
                    }
                    helperText={
                      props.touched.account_number &&
                      props.errors.account_number
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectBank
                    name="bank_name"
                    selectlabel={"Bank Name"}
                    value={props.values.bank_name}
                    // defaultValue={props.values.bankName}
                    onChange={props.handleChange}
                    error={Boolean(
                      props.touched.account_holder &&
                        props.errors.account_holder
                    )}
                    error_message={
                      props.touched.account_holder &&
                      props.errors.account_holder
                    }
                  />
                </Grid>
              </Grid>
              <br />
              <Button
                disableRipple
                title={"Submit"}
                type="submit"
                // onClick={() => history.push("/my-requests")}
              />
            </form>
          )}
        </Formik>
      </Box>
    </DialogBox>
  );
};

export default AddBankDetails;
