import React from "react";
import { Container } from "./styles";

const Card = ({ children, padding = "30px 20px", sx }) => {
  return (
    <Container sx={sx} style={{ padding: padding }}>
      {children}
    </Container>
  );
};
export default Card;
