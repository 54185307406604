import { styled } from "@mui/system";

export const PasswordContainer = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "55vw",
  margin: "auto",
}));

export const TermsAndConditions = styled("div")(({ theme }) => ({
  color: theme.palette.black,
  maxHeight: "160px",
  overflow: "auto",
  padding: "10px 5px",
  border: "1px solid rgba(101, 101, 101, 0.45)",
  textAlign: "left",
  "& > div": {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    color: theme.palette.black,
  },
  "&  p": {
    fontFamily: "Poppins",
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18px",
    color: "#616161",
  },
  "& span": {
    color: theme.palette.purple,
  },
}));

export const AcceptTerms = styled("div")(({ theme }) => ({
  color: theme.palette.black,
  width: "100%",
  textAlign: "start",
  "& .MuiTypography-root": {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "14px",
    color: theme.palette.text.main,
  },
}));

export const Title = styled("div")(({ theme }) => ({
  fontStyle: "normal",
  fontSeight: "400",
  fontSize: "20px",
  lineHeight: "23px",
  paddingBottom: "20px",
  color: theme.palette.black,
}));

export const ResendOTP = styled("div")(({ theme }) => ({
  margin: "5px 0px 0px 4px",
  color: theme.palette.purple,
  textAlign: "left",
  fontSize: "12px",
  cursor: "pointer",
}));
