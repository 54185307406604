import React, { useState, useEffect } from "react";
import Card from "../Card";
import AppLogo from "../../assets/logo.png";
import { Box, Divider, Drawer } from "@mui/material";
import theme from "../../theme";
import { makeStyles } from "@material-ui/styles";
import { StyledNavItem } from "./styles";
import Logout from "../../assets/svg/logout.svg";
import Request from "../../assets/svg/request.png";
import RequestAdvance from "../../assets/svg/newRequest.svg";
import Setting from "../../assets/svg/setting.svg";
import Privacy from "../../assets/svg/privacy.svg";
import Condition from "../../assets/svg/condition.svg";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { SNACKBAR_OPEN } from "../../store/constants/common";
import { ERROR } from "../../constants/snackbarTypes";
import { Interceptor } from "../../utils/interceptor";
import { useLocation } from "react-router-dom";
import LocalStorageService from "../../utils/localStorageService";
import { EMPLOYEE, EMPLOYER, ADMIN } from "../../constants/userTypes";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_PERSIST_SLICE } from "../../store/constants/common";
import PublicIcon from "@mui/icons-material/Public";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import GroupIcon from "@mui/icons-material/Group";
export default function DesktopMenu({ isOpen, setIsOpen, scroll }) {
  const classes = useStyles();
  const axiosInstance = Interceptor();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(1);

  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const search = useLocation().search;
  const status = new URLSearchParams(search).get("status");

  const userType = useSelector((state) => state.persist.userData.role);

  useEffect(() => {
    switch (pathname) {
      case "/my-requests":
      case "/new-request":
      case "/received-requests":
        setActiveTab(2);
        break;
      case "/account-setting":
        setActiveTab(3);
        break;
      case "/all-employers":
        setActiveTab(4);
        break;
      case "/privacy-policy":
        setActiveTab(4);
        break;
      case "/added-countries":
        setActiveTab(5);
        break;
      case "/set-salary-range":
        setActiveTab(6);
        break;
      case "/all-requests":
        status === "pending" ? setActiveTab(7) : setActiveTab(1);
        break;
      case "/admin/terms-and-conditions":
        setActiveTab(8);
        break;
      case "/terms-and-conditions":
        setActiveTab(9);
        break;
    }
  }, [pathname, status]);
  // useEffect(() => {
  //   if (state?.openPopup) {
  //     setActiveTab(2);
  //   }
  // }, [state]);

  const handleLogout = () => {
    dispatch({
      type: CLEAR_PERSIST_SLICE,
    });
    history.push("/login");
    LocalStorageService.clearToken();
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleAddNewRequest = () => {
    axiosInstance
      .get("employee/advance-status")
      .then(() => {
        history.push("/new-request", { openPopup: true });
      })
      .catch((error) => {
        const errors = error?.response?.data?.errors;
        errors.map((error) => {
          dispatch({
            type: SNACKBAR_OPEN,
            payload: {
              snackbarType: ERROR,
              message: error.message,
            },
          });
        });
      });
    handleClose();
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const list = (anchor) => (
    <Card
      sx={{
        backgroundColor: "#fff",
        borderRadius: "8px",
        position: "relative",
        maxWidth: "260px",
        width: "100%",
        margin: "unset",
        height: "100vh",
        display: "Flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box py={1}>
          <img width={"150px"} src={AppLogo} alt="logo" />
        </Box>
        <Divider
          sx={{
            backgroundImage: theme.palette.gradient,
            opacity: "0.25",
            backgroundColor: "transparent",
          }}
        />

        <Box
          sx={{ display: "flex", flexDirection: "column", rowGap: "5px" }}
          py={1.5}
        >
          {userType === EMPLOYEE ? (
            <>
              <Link to="/my-requests" style={{ textDecoration: "none" }}>
                <StyledNavItem
                  sx={{
                    backgroundColor:
                      activeTab === 1 ? "#00000011" : theme.palette.white,
                  }}
                  onClick={handleClose}
                >
                  <img src={Request} alt="request" />
                  <span> My Requests</span>
                </StyledNavItem>
              </Link>
              <StyledNavItem
                onClick={handleAddNewRequest}
                sx={{
                  backgroundColor:
                    activeTab === 2 ? "#00000011" : theme.palette.white,
                }}
              >
                <img src={RequestAdvance} alt="img" />
                <span>Request New Advance</span>
              </StyledNavItem>
            </>
          ) : userType == EMPLOYER ? (
            <>
              <Link to="/my-employees" style={{ textDecoration: "none" }}>
                <StyledNavItem
                  onClick={handleClose}
                  sx={{
                    backgroundColor:
                      activeTab === 1 ? "#00000011" : theme.palette.white,
                  }}
                >
                  <img src={Request} alt="request" />
                  <span> My Employees</span>
                </StyledNavItem>
              </Link>
              <StyledNavItem
                onClick={() => {
                  history.push("/received-requests");
                  handleClose();
                }}
                sx={{
                  backgroundColor:
                    activeTab === 2 ? "#00000011" : theme.palette.white,
                }}
              >
                <img src={RequestAdvance} alt="img" />
                <span>Received Requests</span>
              </StyledNavItem>
            </>
          ) : (
            <>
              <Link to="/all-requests" style={{ textDecoration: "none" }}>
                <StyledNavItem
                  onClick={handleClose}
                  sx={{
                    backgroundColor:
                      activeTab === 1 ? "#00000011" : theme.palette.white,
                  }}
                >
                  <img src={Request} alt="request" />
                  <span> All Requests</span>
                </StyledNavItem>
              </Link>
              <Link
                to="/all-requests?status=pending"
                style={{ textDecoration: "none" }}
              >
                <StyledNavItem
                  onClick={handleClose}
                  sx={{
                    backgroundColor:
                      activeTab === 7 ? "#00000011" : theme.palette.white,
                  }}
                >
                  <img src={Request} alt="request" />
                  <span> Employer Pending Request</span>
                </StyledNavItem>
              </Link>
              <Link to="/all-employers" style={{ textDecoration: "none" }}>
                <StyledNavItem
                  onClick={handleClose}
                  sx={{
                    backgroundColor:
                      activeTab === 4 ? "#00000011" : theme.palette.white,
                  }}
                >
                  <GroupIcon />
                  <span> All Employers</span>
                </StyledNavItem>
              </Link>
              <Link to="/added-countries" style={{ textDecoration: "none" }}>
                <StyledNavItem
                  onClick={handleClose}
                  sx={{
                    backgroundColor:
                      activeTab === 5 ? "#00000011" : theme.palette.white,
                  }}
                >
                  <PublicIcon />
                  <span> Added Countries</span>
                </StyledNavItem>
              </Link>
              <Link to="/set-salary-range" style={{ textDecoration: "none" }}>
                <StyledNavItem
                  onClick={handleClose}
                  sx={{
                    backgroundColor:
                      activeTab === 6 ? "#00000011" : theme.palette.white,
                  }}
                >
                  <PaidOutlinedIcon />
                  <span>Set Salary Range</span>
                </StyledNavItem>
              </Link>
            </>
          )}
          <Box
            sx={{
              paddingTop: "20%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <Link to={"/account-setting"} style={{ textDecoration: "none" }}>
              <StyledNavItem
                onClick={handleClose}
                sx={{
                  backgroundColor:
                    activeTab === 3 ? "#00000011" : theme.palette.white,
                }}
              >
                <img src={Setting} alt="img" />
                <span>Profile Settings</span>
              </StyledNavItem>
            </Link>
            {userType === ADMIN ? (
              <Link
                to={"/admin/terms-and-conditions"}
                style={{ textDecoration: "none" }}
              >
                <StyledNavItem
                  onClick={handleClose}
                  sx={{
                    backgroundColor:
                      activeTab === 8 ? "#00000011" : theme.palette.white,
                  }}
                >
                  <img src={Condition} alt="img" />

                  <span style={{ marginLeft: "-2px" }}>
                    {userType === EMPLOYER
                      ? "Aggrement"
                      : "Terms and Conditions"}
                  </span>
                </StyledNavItem>
              </Link>
            ) : (
              <Link
                to={"/terms-and-conditions"}
                style={{ textDecoration: "none" }}
              >
                <StyledNavItem
                  onClick={handleClose}
                  sx={{
                    backgroundColor:
                      activeTab === 9 ? "#00000011" : theme.palette.white,
                  }}
                >
                  <img src={Condition} alt="img" />

                  <span style={{ marginLeft: "-2px" }}>
                    {userType === EMPLOYER
                      ? " Aggrement"
                      : "Terms and Conditions"}
                  </span>
                </StyledNavItem>
              </Link>
            )}
            {userType !== ADMIN && (
              <Link to={"/privacy-policy"} style={{ textDecoration: "none" }}>
                <StyledNavItem
                  onClick={handleClose}
                  sx={{
                    backgroundColor:
                      activeTab === 4 ? "#00000011" : theme.palette.white,
                  }}
                >
                  <img src={Privacy} alt="img" />
                  <span>Privacy Policy</span>
                </StyledNavItem>
              </Link>
            )}
          </Box>
        </Box>
      </Box>
      <Box py={1.5}>
        {/* <Link to={"/"} style={{ textDecoration: "none" }} > */}
        <StyledNavItem onClick={handleLogout}>
          <img src={Logout} />
          Logout
        </StyledNavItem>
        {/* </Link> */}
      </Box>
    </Card>
  );
  const anchor = "left";
  return (
    <div>
      <React.Fragment key={anchor}>
        <Drawer
          anchor={anchor}
          className={classes.Drawer}
          open={isOpen}
          onClose={toggleDrawer(false)}
        >
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
const useStyles = makeStyles(() => ({
  Drawer: {
    "& .MuiDrawer-paper": {
      backgroundColor: `#fff`,
      height: "100vh !important",
      width: 270,
      marginTop: "50px",
      color: `#fff`,
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  },
  list: {
    "&:nth-of-type(1)": {
      color: `#fff`,
    },
  },
  close: {
    color: theme.palette.primary.main,
    textAlign: "right",
  },
  LinksContainer: {
    width: "80%",
    display: "flex",
    margin: "auto",
    cursor: "pointer",
    flexDirection: "column",
    justifyContent: "space-between",
    borderBottom: "0.5px solid #D9A06E",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));
