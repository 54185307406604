import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Button } from "../../components/Buttons";
import TextInput from "../../components/TextInput";
import Select from "../../components/Select";
import MobileNumberInput from "../../components/mobileNumberInput";
import { IMG_URL } from "../../constants/urls";
import formatNumber from "../../helper/formatNumber";
import { Profile } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import SelectBank from "../../components/Select/SelectBank";

const EditEmployeeForm = (props) => {
  let imgpath = `${IMG_URL}${props.values.imgName}`;

  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Select
            name={"salutation"}
            options={["Mr.", "Ms.", "Mrs."]}
            selectlabel={"Salutation"}
            disabled={!props.isEditable}
            value={props.values.salutation}
            defaultValue={props.values.salutation}
            onChange={(e) => {
              props.setFieldValue("salutation", e.target.value);
            }}
            onBlur={props.handleBlur}
            error={Boolean(props.errors.salutation)}
            error_message={props.touched.salutation && props.errors.salutation}
          />
        </Grid>
        <Grid item md={4}>
          <TextInput
            name={"first_name"}
            sx={{ fontSize: "13px" }}
            inputlabel={"First Name"}
            InputProps={{
              readOnly: !props.isEditable,
              autoFocus: props.isEditable,
            }}
            autoFocus={props.isEditable}
            value={props.values.first_name}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            error={props.touched.first_name && props.errors.first_name}
            helperText={props.touched.first_name && props.errors.first_name}
          />
        </Grid>
        <Grid item md={4}>
          <TextInput
            name={"last_name"}
            sx={{ fontSize: "13px" }}
            inputlabel={"Last Name"}
            InputProps={{
              readOnly: !props.isEditable,
            }}
            value={props.values.last_name}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            error={props.touched.last_name && props.errors.last_name}
            helperText={props.touched.last_name && props.errors.last_name}
          />
        </Grid>
        <Grid item md={4}>
          <MobileNumberInput
            onChange={(e, f) => props.setFieldValue("contact_number", `+${e}`)}
            touched={
              props.touched.contact_number && props.errors.contact_number
            }
            value={props.values.contact}
            inputlabel={"Mobile Number"}
            name={"contact_number"}
            error={props.touched.contact_number && props.errors.contact_number}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            name={"pin"}
            sx={{ fontSize: "13px" }}
            inputlabel={"KRA Pin"}
            InputProps={{
              readOnly: !props.isEditable,
            }}
            value={props.values.pin}
            onChange={props.handleChange}
            error={props.touched.pin && props.errors.pin}
            helperText={props.touched.pin && props.errors.pin}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            name={"salary"}
            type={"number"}
            sx={{ fontSize: "13px" }}
            inputlabel={`Current Net Monthly Salary`}
            InputProps={{
              readOnly: !props.isEditable,
            }}
            value={props.values.salary}
            onChange={props.handleChange}
            error={props.touched.salary && props.errors.salary}
            helperText={props.touched.salary && props.errors.salary}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            name={"payroll"}
            sx={{ fontSize: "13px" }}
            inputlabel={"Provide Employee Payroll Number"}
            InputProps={{
              readOnly: !props.isEditable,
            }}
            value={props.values.payroll}
            onChange={props.handleChange}
            error={props.touched.payroll && props.errors.payroll}
            helperText={props.touched.payroll && props.errors.payroll}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            name={"registrationDoc"}
            onChange={(e) => {
              props.setFieldValue("registrationDoc", e.target.files[0]);
            }}
            type={"file"}
            onBlur={props.onBlur}
            inputlabel={"Upload your ID or Passport"}
            InputProps={{
              readOnly: !props.isEditable,
            }}
            error={Boolean(
              props.touched.registrationDoc && props.errors.registrationDoc
            )}
            helperText={
              props.touched.registrationDoc && props.errors.registrationDoc
            }
          />
        </Grid>

        <Grid item xs={3} marginTop="35px" alignItems={"flex-start"}>
          <Typography variant="p" align="left">
            {" "}
            Download: &nbsp;
          </Typography>
          <a
            download
            href={imgpath}
            style={{ textDecoration: "none" }}
            target={"new"}
          >
            {props.values.imgName}
          </a>
        </Grid>
        <Grid item md={12} margin={"20px 0px"}>
          {" "}
          <Profile>Bank Details</Profile>
        </Grid>
        <Grid item xs={4}>
          <SelectBank
            name={"bankName"}
            selectlabel={"Bank Name"}
            value={props.values.bankName}
            disabled={!props.isEditable}
            onChange={(e) => {
              props.setFieldValue("bankName", e.target.value);
            }}
            error={Boolean(props.touched.bankName && props.errors.bankName)}
            error_message={props.touched.bankName && props.errors.bankName}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            name={"accountHolderName"}
            sx={{ fontSize: "13px" }}
            inputlabel={"Account Holder"}
            InputProps={{
              readOnly: !props.isEditable,
            }}
            value={props.values.accountHolderName}
            onChange={props.handleChange}
            error={
              props.touched.accountHolderName && props.errors.accountHolderName
            }
            helperText={
              props.touched.accountHolderName && props.errors.accountHolderName
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            name={"accountNumber"}
            sx={{ fontSize: "13px" }}
            inputlabel={"Account Number"}
            value={props.values.accountNumber}
            onChange={props.handleChange}
            error={props.touched.accountNumber && props.errors.accountNumber}
            helperText={
              props.touched.accountNumber && props.errors.accountNumber
            }
          />
        </Grid>
      </Grid>

      <Box pb={"10px"} textAlign={"initial"}></Box>
      <Box py={2} width={"200px"} margin={"auto"}>
        <Button
          title={"Update Profile"}
          type="submit"
          disabled={!props.isEditable}
        />
      </Box>
    </form>
  );
};
export default EditEmployeeForm;
