import { Interceptor } from "../../utils/interceptor";
import {
  SNACKBAR_OPEN,
  SNACKBAR_CLOSE,
  LOADER_OPEN,
  LOADER_CLOSE,
  USER_DETAILS,
  OPT_SENT_STATUS,
  RESET_PASS_STATUS,
  TERMS_AND_CONDITIONS,
} from "../constants/common";
import { ERROR, SUCCESS } from "../../constants/snackbarTypes";
import { ADMIN, EMPLOYEE, EMPLOYER } from "../../constants/userTypes";
import LocalStorageService from "../../utils/localStorageService";

const localStorageService = LocalStorageService.getService();
const axiosInstance = Interceptor();

function getRole() {
  return localStorageService.getUserType();
}

export const loginRequest = (data, history) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("/auth", data)
    .then((res) => {
      dispatch({
        type: USER_DETAILS,
        payload: res.data.user,
      });
      console.log(res.data);
      const user = res.data.user;

      const token = res.data.token;
      localStorageService.setToken(token);
      localStorageService.setCurrentUser(user);
      if (user.accepted_terms === 1) {
        let path = "/my-requests";
        if (res.data.user.role === EMPLOYER) {
          path = "/my-employees";
        } else if (res.data.user.role === ADMIN) {
          path = "/all-requests";
        }

        history.push(path);
      }
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const verifyOptRequest = (data, history) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("/auth/verify-otp", data)
    .then((res) => {
      const path =
        getRole() === EMPLOYEE
          ? "/add-employee-details"
          : "/documents-verification";
      history.push(path);
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const ResetPasswordOtpRequest = (data) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("/auth/forget-password", data)
    .then((res) => {
      dispatch({
        type: OPT_SENT_STATUS,
        payload: true,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      dispatch({
        type: OPT_SENT_STATUS,
        payload: false,
      });
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const ResetPassword = (data) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post("/auth/set-password", data)
    .then((res) => {
      dispatch({
        type: RESET_PASS_STATUS,
        payload: true,
      });
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: res.data,
        },
      });
    })
    .catch((error) => {
      console.log(error, "error");
      const errors = error?.response?.data?.errors;
      dispatch({
        type: RESET_PASS_STATUS,
        payload: false,
      });
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const getTermsAndConditions = (role) => (dispatch) => {
  let url = role
    ? `/terms-and-conditions?role=${role}`
    : `/terms-and-conditions`;
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .get(url)
    .then((res) => {
      dispatch({
        type: TERMS_AND_CONDITIONS,
        payload: res.data,
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      dispatch({
        type: RESET_PASS_STATUS,
        payload: false,
      });
      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};

export const resendOTP = (data) => (dispatch) => {
  dispatch({
    type: LOADER_OPEN,
  });
  axiosInstance
    .post(`/auth/resend-otp`, data)
    .then((res) => {
      dispatch({
        type: SNACKBAR_OPEN,
        payload: {
          snackbarType: SUCCESS,
          message: "OTP resend successfully",
        },
      });
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;

      errors.map((error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          payload: {
            snackbarType: ERROR,
            message: error.message,
          },
        });
      });
    })
    .finally(() => {
      dispatch({
        type: LOADER_CLOSE,
      });
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_CLOSE,
        });
      }, 5000);
    });
};
