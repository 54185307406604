import { styled } from "@mui/system";

export const DetailsContainer = styled("div")(({ theme }) => ({
  //   width: "100%",
  background: "#F7F7F7",
  border: "1px solid #D0D0D0",
  borderRadius: "5px",
  backgroundColor: theme.palette.secondary.main,
  display: "flex",
}));
export const Requested = styled("div")(({ theme }) => ({
  padding: "16px 25px",
  fontSize: "14px",
  fontWeight: 400,
  color: " #656565E5",
  display: "flex",
  width: "40%",
}));
export const Details = styled("div")(({ theme }) => ({
  padding: "16px 25px",
  fontSize: "14px",
  fontWeight: 400,
  color: " #656565E5",
  display: "flex",
  borderLeft: "1px solid #D0D0D0",
}));
export const Profile = styled("p")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "20px",
  lineHeight: "23px",
  marginTop: "5px",
  color: theme.palette.black,
  textAlign: "start",
  margin: "0",
}));
export const Account = styled("div")(({ theme }) => ({
  border: "1px solid #D0D0D0",
  borderRadius: "10px",
  padding: "16px 18px",
}));
export const Password = styled("div")(({ theme }) => ({
  // marginTop: "20px",
  border: "1px solid #D0D0D0",
  borderRadius: "10px",
  padding: "16px 18px",
}));
