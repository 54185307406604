import { styled } from "@mui/system";

export const DetailsContainer = styled("div")(({ theme }) => ({
  //   width: "100%",
  background: "#F7F7F7",
  border: "1px solid #D0D0D0",
  borderRadius: "5px",
  backgroundColor: theme.palette.secondary.main,
  display: "flex",
}));
export const Requested = styled("div")(({ theme }) => ({
  padding: "16px 25px",
  fontSize: "14px",
  fontWeight: 400,
  color: " #656565E5",
  display: "flex",
  width: "40%",
}));
export const Details = styled("div")(({ theme }) => ({
  padding: "16px 25px",
  fontSize: "14px",
  fontWeight: 400,
  color: " #656565E5",
  display: "flex",
  borderLeft: "1px solid #D0D0D0",
}));
