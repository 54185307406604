import React, { useEffect } from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import theme from "../../theme";
import { Box } from "@mui/system";
import { getBanks, getbanks } from "../../store/Apis";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  container: {
    alignSelf: "center",
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.grey[200],
      borderRadius: "10px",
      textAlign: "left",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  outlined: {},
}));

const SelectBank = (props) => {
  const dispatch = useDispatch();
  const banks = useSelector((state) => state.employer.banks);
 
  useEffect(() => {
    dispatch(getBanks());
  }, []);

  const {
    value,
    selectlabel,
    error = false,
    error_message = "",
    onBlur,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography
        color={theme.palette.black}
        fontSize={16}
        paddingLeft={0.5}
        paddingBottom={0.5}
        textAlign={"left"}
      >
        {selectlabel}
      </Typography>
      <FormControl fullWidth>
        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
        <Select
          classes={classes}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          size="small"
          {...props}
        >
          {banks.map((option, index) => {
            return (
              <MenuItem key={index} value={option.bank_name}>
                {option.bank_name}
              </MenuItem>
            );
          })}
        </Select>
        {error && (
          <Box
            sx={{
              color: "#CF5951",
              textAlign: "left",
              margin: "4px 14px 0px 14px",
              fontSize: " 0.75rem",
              fontWeight: 400,
            }}
          >
            {error_message}
          </Box>
        )}
      </FormControl>
    </div>
  );
};

export default SelectBank;
