import {
  Box,
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  TextField,
} from "@mui/material";
import React from "react";
import { Button } from "../../components/Buttons";
import { Container } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import TableHead from "@mui/material/TableHead";
import { Account, Profile } from "../viewEmployeeDetails/styles";
import moment from "moment";
import { Details, Requested, DetailsContainer } from "./styles";
import Card from "../../components/Card";
import { getEmployeeRequest } from "../../store/Apis";
import { changeEmployeeRequestStatus } from "../../store/Apis";
import { useEffect } from "react";
import { useState } from "react";
import formatNumber from "../../helper/formatNumber";
import PaymentLedgerEmployer from "./PaymentLedgerEmployer";
import { EMPLOYER } from "../../constants/userTypes";
import PaymentLedgerAdmin from "./PaymentLedgerAdmin";

const ViewRequestDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [moreInfo, setMoreInfo] = useState("");
  const role = useSelector((state) => state.persist.userData.role);
  const requestData = useSelector((state) => state.employer.requestData);
  const requestStatus = useSelector(
    (state) => state.employer.changeEmployeeRequestStatus
  );

  useEffect(() => {
    dispatch(getEmployeeRequest(id));
  }, []);
  useEffect(() => {
    if (requestStatus === "success") {
      dispatch(getEmployeeRequest(id));
    }
  }, [requestStatus]);

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        height: "100%",
      }}
    >
      <Card
        sx={(theme) => ({
          height: "calc(100% - 60px)",
          overflow: "scroll",
          [theme.breakpoints.down("lg")]: {
            height: "fit-content",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "16px",
          }}
        >
          <Profile>Request Details</Profile>
        </Box>

        <Box mb={1}>
          <Card sx={{ width: "70%" }}>
            <Grid container>
              <Grid item xs={12}>
                <DetailsContainer>
                  <Requested> Employee Name</Requested>
                  <Details>
                    {requestData?.employee_details?.fname}{" "}
                    {requestData?.employee_details?.lname}{" "}
                  </Details>
                </DetailsContainer>
              </Grid>
              <Grid item xs={12}>
                <DetailsContainer>
                  <Requested> Requested Amount</Requested>
                  <Details> {formatNumber(requestData?.amount)}</Details>
                </DetailsContainer>
              </Grid>
              <Grid item xs={12}>
                <DetailsContainer>
                  <Requested>Requested Mounth</Requested>
                  <Details>{requestData?.month}</Details>
                </DetailsContainer>
              </Grid>
              <Grid item xs={12}>
                <DetailsContainer>
                  <Requested> Requested package</Requested>
                  <Details>{requestData?.package}</Details>
                </DetailsContainer>
              </Grid>
              <Grid item xs={12} marginX="auto">
                <DetailsContainer>
                  <Requested> Requested On</Requested>
                  <Details>
                    {moment(requestData?.created_at).format("MM/DD/YYYY")}
                  </Details>
                </DetailsContainer>
              </Grid>
            </Grid>
          </Card>
        </Box>
        {role === EMPLOYER ? (
          <PaymentLedgerEmployer requestData={requestData} />
        ) : (
          <PaymentLedgerAdmin requestData={requestData} />
        )}
      </Card>
    </Container>
  );
};

export default ViewRequestDetails;
