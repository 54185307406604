import React from "react";
import { Card, Container, Typography } from "@mui/material";
import Table from "./Table";

const AllPendingRequest = () => {
  return (
    <Card padding="0px 0px" sx={{ width: "100%", height: "100%" }}>
        <Table />
      </Card>
  );
};

export default AllPendingRequest;