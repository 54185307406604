import React from "react";
import { Container, Typography } from "@mui/material";
import Table from "./Table";
import Card from "../../components/Card";

const AddedCountries = () => {
  return (
    <Container
      maxWidth={true}
      disableGutters
      sx={{
        height: "100%",
      }}
    >
      <Card padding="0px 0px" sx={{ width: "100%", height: "100%" }}>
        <Table />
      </Card>
    </Container>
  );
};

export default AddedCountries;
