import React from "react";
import Table from "./Table";
import Card from "../../components/Card";
import { Alert, Container } from "@mui/material";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";

const ReceivedRequests = () => {
  const blockStatus = useSelector(
    (state) => state?.persist?.userData?.admin_blocked
  );
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        height: "100%",
      }}
    >
      <Card padding="0px 0px" sx={{ width: "100%", height: "100%" }}>
        {blockStatus === 2 && (
          <Card>
            <Alert severity="error">
              {" "}
              Your Account Is on Hold, You cannot Approve Requests, Please
              contact admin to Un-hold your account
            </Alert>
          </Card>
        )}
        <Table />
      </Card>
    </Container>
  );
};

export default ReceivedRequests;
