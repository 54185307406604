import React from "react";
import { Route, Redirect } from "react-router-dom";
import LocalStorageService from "../utils/localStorageService";
import jwt_decode from "jwt-decode";
import moment from "moment";

import PrivateLayout from "../components/Layout/Private";
const PrivateGuard = ({ children, component: Component, ...rest }) => {
  const AccessToken = LocalStorageService.getAccessToken();

  let isvalidToken = false;
  if (AccessToken) {
    try {
      const decode = jwt_decode(AccessToken);
      const date = moment(new Date()).format("X");
      isvalidToken = decode.exp >= date;
    } catch (error) {
      isvalidToken = false;
    }
  } else {
    isvalidToken = false;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isvalidToken ? (
          <PrivateLayout>
            <Component />
          </PrivateLayout>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
export default PrivateGuard;
