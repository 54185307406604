import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import theme from "../../theme";
import { Interceptor } from "../../utils/interceptor";
const axiosInstance = Interceptor();
const useStyles = makeStyles(() => ({
  root: {
    background: "#eeeeee",
    borderRadius: "6px",
    "& .MuiOutlinedInput-root": {
      padding: "0px 9px !important",
      "& > .MuiAutocomplete-endAdornment": {
        right: "7px",
      },
    },
    "& .Mui-focused": {
      borderColor: "unset",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function Asynchronous(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [Company, setCompany] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const { inputlabel, value, onChange, onBlur, error, helperText } = props;

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <>
      <Typography
        color={theme.palette.black}
        fontSize={16}
        paddingLeft={0.5}
        paddingBottom={0.5}
        textAlign={"left"}
      >
        {inputlabel}
      </Typography>
      <Autocomplete
        sx={{ padding: "0px" }}
        classes={classes}
        id="asynchronous-demo"
        fullWidth
        open={Company.length > 2 ? open : false}
        onOpen={() => {
          options.length && setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        getOptionLabel={(option) => option.title || ""}
        options={options}
        value={value}
        loading={options.length !== 0 && loading}
        {...props}
        renderInput={(params) => (
          <TextField
            {...params}
            value={value}
            onChange={(e) => {
              setCompany(e.target.value);
              Company.length > 2 &&
                (async () => {
                  const companies = await axiosInstance.get("/companies");
                  setOptions([...companies.data]);
                })();
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: options.length !== 0 && (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {error && (
        <Box
          sx={{
            color: "#CF5951",
            textAlign: "left",
            margin: "4px 14px 0px 14px",
            fontSize: " 0.75rem",
            fontWeight: 400,
          }}
        >
          {helperText}
        </Box>
      )}
    </>
  );
}
