import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Card, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { deleteSalaryRange, getSalaryRange } from "../../store/Apis";

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& .MuiTableCell-root": {
    fontWeight: "bold",
  },
}));

const columns = [
  {
    id: "range",
    label: "Range",
    align: "center",
  },
  {
    id: "charges",
    label: "Charges",
    align: "center",
  },
  {
    id: "remove",
    label: " ",
    align: "center",
  },
];

export default function DataTable() {
  const dispatch = useDispatch();
  const salaryRange = useSelector((state) => state.admin.salarRange);
  

  useEffect(() => {
    dispatch(getSalaryRange());
  }, []);

  return (
    <>
      <Card sx={{ height: "fit-content" }}>
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 130px)", position: "relative" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <StyledTableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, paddingTop: "40px" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            {salaryRange?.map((data) => (
              <TableRow>
                <TableCell align="center">{data.salary_range}</TableCell>
                <TableCell align="center">{data.charges}</TableCell>
                <TableCell align="center" sx={{ color: "#c52323" }}>
                  <IconButton
                    onClick={() => dispatch(deleteSalaryRange(data.id))}
                  >
                    <Delete sx={{ color: "#9F325A" }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}
