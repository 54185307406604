import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getTermsAndConditions } from "../../store/Apis";
import { EMPLOYEE, EMPLOYER } from "../../constants/userTypes";
import QuillEditor from "./QuillEditor";
import Card from "../../components/Card";
import { Button } from "../../components/Buttons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdminTermsAndConditions() {
  const dispatch = useDispatch();
  const termsAndConditions = useSelector(
    (state) => state.common.termsAndCondtions
  );
  useEffect(() => {
    dispatch(getTermsAndConditions());
  }, []);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        height: "100%",
      }}
    >
      <Card
        sx={(theme) => ({
          height: "calc(100% - 60px)",
          [theme.breakpoints.down("lg")]: {
            height: "fit-content",
          },
        })}
      >
        <Typography variant="h6" style={{ padding: "40px", textAlign: "left" }}>
          Terms and conditions
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="fullWidth"
            >
              {termsAndConditions?.map((details, index) => {
                return (
                  <Tab
                    sx={{
                      border: 1,
                      borderColor: "divider",
                    }}
                    label={
                      details.role === EMPLOYEE
                        ? `Employee Terms & Condtions`
                        : details.role === EMPLOYER
                        ? `Employer Agreement`
                        : "Privacy Policy"
                    }
                    {...a11yProps(index)}
                  />
                );
              })}
            </Tabs>
          </Box>
          {termsAndConditions?.map((details, index) => {
            return (
              <TabPanel value={value} index={index}>
                <QuillEditor key={index} termsAndConditions={details} />
              </TabPanel>
            );
          })}
        </Box>
      </Card>
    </Container>
  );
}
