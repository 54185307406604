import { Box, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBox from "../../components/Dialog";
import { Button } from "../../components/Buttons";
import TextInput from "../../components/TextInput";
import * as yup from "yup";
import { Formik } from "formik";
import { ResendOTP } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { resendOTP, sendOtp, acceptTermsAndConditions } from "../../store/Apis";

const OTPVerificationDialog = (props) => {
  const { open, handleClose, setDialog } = props;
  const [otp, setOtp] = useState(null);
  const dispatch = useDispatch();
  const initialValues = {
    opt: null,
  };

  const validationShema = yup.object({
    opt: yup.number().required("Please Enter OTP"),
  });
  const role = useSelector((state) => state.persist.userData.role);
  const userId = useSelector((state) => state.persist.userData.id);
  const user_contact = useSelector((state) => state.persist.userData.contact);

  const handleResendOTP = () => {
    dispatch(
      resendOTP({
        contact: user_contact,
        resend_type: "terms-conditions",
      })
    );
  };

  const handleSubmit = (values) => {
    dispatch(
      acceptTermsAndConditions({
        user_id: userId,
        otp: otp,
        role: role,
      })
    );
  };

  useEffect(() => {
    if (open) {
      dispatch(
        sendOtp({
          contact: user_contact,
          user_id: userId,
        })
      );
    }
  }, [open]);
  return (
    <DialogBox open={open} handleClose={handleClose} title={""}>
      <DialogContent sx={{ overflowX: "hidden" }}>
        <Box
          sx={{
            padding: "16px",
            width: { sm: "fit-content", md: "562px" },
            fontSize: "18px",
            textAlign: "center",
          }}
        >
          By entering the SMS OTP you confirm your acceptence of AdvanceSalo
          agreement
        </Box>

        <Formik
          initialValues={initialValues}
          validationSchema={validationShema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {(props) => (
            <form>
              <Box
                sx={{
                  borderRadius: "10px",
                  padding: "20px",
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "column",
                }}
              >
                <TextInput
                  name={"smsOtp"}
                  value={props.values.opt}
                  onChange={(e) => {
                    props.handleChange(e);
                    setOtp(e.target.value);
                  }}
                  onBlur={props.handleBlur}
                  inputlabel={"SMS OTP"}
                  error={props.touched.opt && props.errors.opt}
                  helperText={props.errors.otp}
                />
                <ResendOTP onClick={handleResendOTP}>Resend SMS OTP</ResendOTP>
              </Box>
              <Box textAlign={"center"} pb={2} width="150px" margin={"auto"}>
                <Button
                  title={"Verify OTP"}
                  variant={"outlined"}
                  disabled={!props.dirty}
                  type="submit"
                  onClick={handleSubmit}
                />
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </DialogBox>
  );
};

export default OTPVerificationDialog;
