import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getCountries } from "../../store/Apis";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& .MuiTableCell-root": {
    fontWeight: "bold",
  },
}));

const columns = [
 
  {
    id: "CountryName",
    label: "Name",
    align: "center",
  },
  {
    id: "Cites",
    label: "Cites",
    align: "center",
  },
  {
    id: "#",
    label: "#",
    align: "center",
  },
];

const ITEM_HEIGHT = 48;

const useStyles = makeStyles(() => ({
  link: {
    color: "initial",
    textDecoration: "none",
    "&:hover": {
      color: "rgba(159, 50, 90, 0.97)",
    },
  },
}));

export default function DataTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.employer.countries);
  useEffect(() => {
    dispatch(getCountries());
  }, []);
  const [open, setOpen] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const ITEM_HEIGHT = 48;
  const openMenu = Boolean(anchorEl);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Paper sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
        <Typography variant="h6" style={{ padding: "40px", textAlign: "left" }}>
          Countries
        </Typography>
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 130px)", position: "relative" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <StyledTableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, paddingTop: "40px" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <TableRow>
              {countries.map((country) => (
                <>
                  <TableCell align={"center"}>{country?.name}</TableCell>
                  <TableCell align={"center"}>
                    <Link
                      color=""
                      to={`/all-cites/${country?.name}`}
                      className={classes.link}
                    >
                      All Cites
                    </Link>
                  </TableCell>

                  <TableCell align={"center"}>
                    <div>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleCloseMenu}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "16ch",
                            boxShadow:
                              "0 19px 38px rgba(0,0,0,0), 0 6px 8px rgba(0,0,0,0.1)",
                          },
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem
                          onClose={handleCloseMenu}
                          sx={{ fontSize: "15px" }}
                        >
                          Remove
                        </MenuItem>
                      </Menu>
                    </div>
                  </TableCell>
                </>
              ))}
            </TableRow>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
