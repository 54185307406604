import { Box } from "@mui/material";
import React from "react";
import { OtpContainer, ResendOTP, VerifyOtp } from "./styles";
import TextInput from "../../components/TextInput";
import { Button } from "../../components/Buttons";
import * as yup from "yup";
import { Formik } from "formik";
import Card from "../../components/Card";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyOptRequest, resendOTP } from "../../store/Apis";

const OTPVerification = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const validationSchema = yup.object({
    otp: yup.number().required("SMS OTP is required"),
  });
  const user_id = useSelector((state) => state.persist.userData.id);
  const user_contact = useSelector((state) => state.persist.userData.contact);
  const initialValues = {
    otp: "",
    user_id: "",
  };

  function handleResendOTP() {
    dispatch(
      resendOTP({
        contact: user_contact,
        resend_type: "signup",
      })
    );
  }

  return (
    <OtpContainer>
      <Box>
        <Card padding="80px 40px" sx={{ width: "100%" }}>
          <VerifyOtp>Verify Your OTP</VerifyOtp>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              values.user_id = user_id;
              dispatch(verifyOptRequest(values, history));
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Box>
                  <TextInput
                    name={"otp"}
                    value={props.values.otp}
                    onChange={props.handleChange}
                    inputlabel={"SMS OTP"}
                    error={props.touched.otp && props.errors.otp}
                    helperText={props.touched.otp && props.errors.otp}
                  />
                  <ResendOTP onClick={handleResendOTP}>
                    Resend SMS OTP
                  </ResendOTP>
                </Box>
                {/* {userType === EMPLOYER && (
                  <Box pt={2}>
                    <TextInput
                      name={"emailOtp"}
                      value={props.values.emailOtp}
                      onChange={props.handleChange}
                      inputlabel={"Email OTP"}
                      error={props.touched.emailOtp && props.errors.emailOtp}
                      helperText={
                        props.touched.emailOtp && props.errors.emailOtp
                      }
                    />
                    <ResendOTP>Resend Email OTP</ResendOTP>
                  </Box>
                )} */}
                <Box pt={4}>
                  <Button
                    disableRipple
                    title={"Verify"}
                    type="submit"
                    // onClick={history.push("/employer-documents-verification")}
                  />
                </Box>
              </form>
            )}
          </Formik>
        </Card>
      </Box>
    </OtpContainer>
  );
};

export default OTPVerification;
