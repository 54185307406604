import { Box, Container, Grid } from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";
import Card from "../../components/Card";
import TextInput from "../../components/TextInput";
import * as yup from "yup";
import { Button } from "../../components/Buttons";
import Select from "../../components/Select";
import SelectCountry from "../../components/Select/county";
import SelectCity from "../../components/Select/cities";
import { Title } from "./styles";
import { useHistory } from "react-router-dom";
import { submitEmployeeDetails } from "../../store/Apis";
import { useDispatch, useSelector } from "react-redux";
import { FILE_SIZE, SUPPORTED_FORMATS } from "../../constants/fileInfo";
import SelectBank from "../../components/Select/SelectBank";
const EmployeeDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.persist.userData.id);
  const validationSchema = yup.object({
    file: yup
      .mixed()
      .test("fileSize", "File too large", ({ size }) => {
        return size ? size <= FILE_SIZE : true;
      })
      .test("required", "File is required", ({ size }) => {
        return size == undefined ? false : true;
      })
      .test("fileFormat", "Unsupported Format", ({ type }) => {
        return type ? SUPPORTED_FORMATS.includes(type) : true;
      }),
    pin: yup.string().required("Provide KRA pin is required"),
    payroll: yup
      .string()
      .required("Provide employee payroll number  is required"),
    salary: yup.string().required("Current net monthly salary is required"),
    account_holder: yup.string().required("Account holder name is required"),
    account_number: yup.string().required("Account number is required"),
    bank_name: yup.string().required("Bank name is required"),
  });

  const initialValues = {
    pin: "",
    payroll: "",
    salary: "",
    account_holder: "",
    account_number: "",
    bank_name: "",
    file: {},
    user_id: user_id,
  };

  const handleFormSubmit = (values) => {
    // history.push("/set-password");
    values.user_id = user_id;
    const formData = new FormData();
    formData.append("pin", values.pin);
    formData.append("payroll", values.payroll);
    formData.append("salary", values.salary);
    formData.append("account_holder", values.account_holder);
    formData.append("account_number", values.account_number);
    formData.append("bank_name", values.bank_name);
    formData.append("file", values.file);
    formData.append("user_id", values.user_id);
    formData.append("is_web_app", 1);
    dispatch(submitEmployeeDetails(formData, history));
  };
  return (
    <Container
      disableGutters
      maxWidth={"lg"}
      sx={{
        height: "100%",
        display: "flex",
      }}
    >
      <Card>
        <Title py={2}>Fill in your details</Title>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => handleFormSubmit(values)}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={1}>
                <Grid item md={6} sm={12} width="100%">
                  <TextInput
                    name={"file"}
                    // value={props.values.f}

                    onChange={(e) => {
                      props.setFieldValue("file", e.target.files[0]);
                    }}
                    onBlur={props.handleBlur}
                    type={"file"}
                    inputlabel={"Upload your ID or Passport"}
                    error={props.touched.file && props.errors.file}
                    helperText={props.touched.file && props.errors.file}
                  />
                </Grid>
                <Grid item md={6} sm={12} width="100%">
                  <TextInput
                    name={"pin"}
                    fullWidth
                    sx={{ fontSize: "13px" }}
                    inputlabel={"Provide KRA Pin"}
                    value={props.values.pin}
                    onChange={props.handleChange}
                    error={props.touched.pin && props.errors.pin}
                    helperText={props.touched.pin && props.errors.pin}
                  />
                </Grid>
                <Grid item md={6} sm={12} width="100%">
                  <TextInput
                    name={"payroll"}
                    sx={{ fontSize: "13px" }}
                    inputlabel={"Provide Employee Payroll Number"}
                    value={props.values.payroll}
                    onChange={props.handleChange}
                    error={props.touched.payroll && props.errors.payroll}
                    helperText={props.touched.payroll && props.errors.payroll}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <TextInput
                    name={"salary"}
                    type={"number"}
                    sx={{ fontSize: "13px" }}
                    inputlabel={`Current Net Monthly Salary`}
                    extratext="actual net amount you receive per month after all
                      deductibles"
                    value={props.values.salary}
                    onChange={props.handleChange}
                    error={props.touched.salary && props.errors.salary}
                    helperText={props.touched.salary && props.errors.salary}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Title>Bank details</Title>
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextInput
                    name="account_holder"
                    sx={{ fontSize: "13px" }}
                    inputlabel="Account Holder Full Name"
                    value={props.values.account_holder}
                    onChange={props.handleChange}
                    Account
                    Holder
                    Name
                    error={
                      props.touched.account_holder &&
                      props.errors.account_holder
                    }
                    helperText={
                      props.touched.account_holder &&
                      props.errors.account_holder
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextInput
                    name="account_number"
                    sx={{ fontSize: "13px" }}
                    inputlabel="Account Number"
                    value={props.values.account_number}
                    onChange={props.handleChange}
                    Account
                    Holder
                    Name
                    error={
                      props.touched.account_number &&
                      props.errors.account_number
                    }
                    helperText={
                      props.touched.account_number &&
                      props.errors.account_number
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SelectBank
                    name="bank_name"
                    selectlabel={"Bank Name"}
                    value={props.values.bank_name}
                    // defaultValue={props.values.bankName}
                    onChange={props.handleChange}
                    error={Boolean(
                      props.touched.account_holder &&
                        props.errors.account_holder
                    )}
                    error_message={
                      props.touched.account_holder &&
                      props.errors.account_holder
                    }
                  />
                </Grid>
              </Grid>
              <Box pt={4} width={"200px"} margin={"auto"}>
                <Button
                  disableRipple
                  title={"Submit"}
                  type="submit"
                  // onClick={() => history.push("/my-requests")}
                />
              </Box>
            </form>
          )}
        </Formik>
      </Card>
    </Container>
  );
};
export default EmployeeDetails;
