import React, { useState } from "react";

import {
  Button,
  Card,
  Container,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import SalaryRangeForm from "./SalaryRangeForm";
import DataTable from "./Table";

const SalaryRange = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <Paper
        sx={{
          width: "auto",
          height: "89vh",
          overflow: "hidden",
          padding: "20px",
        }}
      >
        <Typography variant="h6" textAlign={"left"} padding="10px">
          Service charges as per salary range
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <SalaryRangeForm />
          </Grid>
          <Grid item xs={12} md={7}>
            <DataTable />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default SalaryRange;
