import { styled } from "@mui/system";

export const Profile = styled("p")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "20px",
  lineHeight: "23px",
  marginTop: "5px",
  color: theme.palette.black,
  paddingBottom: "10px",
  textAlign: "start",
  margin: "0",
}));
export const Account = styled("div")(({ theme }) => ({
  border: "1px solid #D0D0D0",
  borderRadius: "10px",
  padding: "16px 18px",
}));
export const Password = styled("div")(({ theme }) => ({
  // marginTop: "20px",
  border: "1px solid #D0D0D0",
  borderRadius: "10px",
  padding: "16px 18px",
}));
