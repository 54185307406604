import React from "react";
import { Container } from "@mui/material";
import { Formik } from "formik";
import { Login, Profile } from "../styles";
import { useHistory } from "react-router-dom";
import Card from "../../../components/Card";
import * as yup from "yup";
import SignupForm from "./Form";
import { useDispatch } from "react-redux";
import { EmployerSignUp as employerSignUp } from "../../../store/Apis";

const EmployerSignUp = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleUserRedirect = () => {
    history.push("/login");
  };
  const validationSchema = yup.object({
    salutation: yup.string().required("Salutation required*"),
    first_name: yup.string().required("First name is required*"),
    last_name: yup.string().required("Last name is required*"),
    company_name: yup.string().required("Company name is required*"),
    country: yup.string().required("Country name is required*"),
    city: yup.string().required("City is required*"),
    role: yup.string().required("Role is required*"),
    prefered_language: yup.string().required("Language is required*"),
    // email: yup.string().required("Email is required*"),
    contact: yup.string().required("Mobile number is required*"),
  });
  const initialValues = {
    salutation: "Mr.",
    first_name: "",
    last_name: "",
    company_name: "",
    country: "Kenya",
    city: "Nairobi",
    role: "Owner",
    prefered_language: "English",
    // email: "",
    contact: "",
  };

  return (
    <Container
      maxWidth={"md"}
      disableGutters
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Card>
        <Profile>Register your profile</Profile>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            dispatch(employerSignUp(values, history));
          }}
        >
          {(props) => <SignupForm {...props} />}
        </Formik>
        <Login>
          Already have an account ?{" "}
          <span onClick={handleUserRedirect}>Login</span>
        </Login>
      </Card>
    </Container>
  );
};

export default EmployerSignUp;
