import { styled } from "@mui/system";

export const Login = styled("div")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: theme.palette.black,
  paddingTop: "20px",

  "& span": {
    color: theme.palette.purple,
    cursor: "pointer",
  },
}));

export const Profile = styled("p")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "20px",
  lineHeight: "23px",
  marginTop: "5px",
  color: theme.palette.black,
  paddingBottom: "10px",
}));
