import React from "react";
// import MuiPhoneNumber from 'material-ui-phone-number'

import PhoneInput from "react-phone-input-2";
import "./style.css";
import { Box } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  label: {
    // padding: 10,
    "& span": {
      color: "red",
    },
  },
  BoxInline: {
    flexDirection: "row",
    display: "flex",
    height: "100%",
    placeItems: "self-end",
  },
  BoxText: {
    display: "flex",
    paddingBottom: "5px",
    color: theme.palette.black,
    "& span": {
      color: "red",
      paddingLeft: 5,
    },
  },
}));

const Input = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.BoxInline}>
      <Box sx={{ width: "100%" }}>
        <Box className={classes.BoxText} pr={1}>
          {props.label || props.labels}
        </Box>
        <PhoneInput
          specialLabel={""}
          country={"ke"}
          inputStyle={{
            width: "100%",
            border: "none",
            color: theme.palette.black,
            backgroundColor: "#eeeeee",
            borderRadius: "8px",
          }}
          {...props}
          onChange={props.onChange}
          onlyCountries={["ke"]}
        />
        {props.touched && props.error && (
          <p
            style={{
              color: theme.palette.error.main,
              fontSize: "12px",
              marginLeft: "12px",
              textAlign: "initial",
            }}
            className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled MuiFormHelperText-marginDense"
          >
            {props.error}
          </p>
        )}
      </Box>
    </Box>
  );
};

const MobileNumberInput = (props) => {
  return (
    <Input
      label={"Mobile Number"}
      req={true}
      helperText={""}
      error={true}
      isSelect={false}
      {...props}
      {...props.input}
      {...props.meta}
      {...props.custom}
    />
  );
};

export default MobileNumberInput;
