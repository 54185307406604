import {
  ALL_EMPLOYERS,
  ALL_REQUESTS,
  EMPLOYER_DETAILS,
  EMPLOYER_PENDING_REQUEST,
  SALARY_RANGE,
} from "../constants/Admin";
import { LOADER_CLOSE } from "../constants/common";

const initialState = {
  allRequests: [],
  allEmployers: [],
  AllCountries: [],
  countryCities: [],
  salarRange: [],
};
export const AdminSlice = (state = initialState, action) => {
  switch (action.type) {
    case ALL_REQUESTS:
      return {
        ...state,
        allRequests: action.payload,
        status: 200,
      };
    case EMPLOYER_PENDING_REQUEST:
      return {
        ...state,
        allRequests: action.payload,
        status: 200,
      };
    case ALL_EMPLOYERS:
      return {
        ...state,
        allEmployers: action.payload,
        status: 200,
      };
    case EMPLOYER_DETAILS:
      return {
        ...state,
        employerDetails: action.payload,
        status: 200,
      };
      case SALARY_RANGE:
      return {
        ...state,
        salarRange: action.payload,
        status: 200,
      };
    default: {
      return state;
    }
  }
};
