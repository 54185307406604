import { styled } from "@mui/system";

export const Container = styled("div")(({ theme }) => ({
  maxWidth: "50rem",
  margin: "auto",
  height: "100%",
  display: "flex",
}));
export const Title = styled("div")(({ theme }) => ({
  fontStyle: "normal",
  fontSeight: "400",
  fontSize: "20px",
  lineHeight: "23px",
  paddingBottom: "20px",
  color: theme.palette.black,
}));
