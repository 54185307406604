import { CURRENCY_LABEL } from "../constants/userTypes";

const formatNumber = (number) => {
  if (!number) {
    return "";
  }
  const formatedNumber = parseFloat(number).toLocaleString("en-US");
  return CURRENCY_LABEL + formatedNumber;
};

export default formatNumber;
