import {
  Box,
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  TextField,
} from "@mui/material";
import React from "react";
import { Button } from "../../components/Buttons";
import { Container } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Account, Profile } from "../viewEmployeeDetails/styles";
import moment from "moment";
import { Details, Requested, DetailsContainer } from "./styles";
import Card from "../../components/Card";
import { getEmployeeRequest } from "../../store/Apis";
import { changeEmployeeRequestStatus } from "../../store/Apis";
import { useEffect } from "react";
import { useState } from "react";
import {
  APPROVED,
  REQUEST_COMPLETED,
  PENDING,
  PAID,
} from "../../constants/requestStatus";
import formatNumber from "../../helper/formatNumber";

const PaymentLedgerAdmin = ({ requestData }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [moreInfo, setMoreInfo] = useState("");

  const requestStatus = useSelector(
    (state) => state.employer.changeEmployeeRequestStatus
  );

  useEffect(() => {
    dispatch(getEmployeeRequest(id));
  }, []);
  useEffect(() => {
    if (requestStatus === "success") {
      dispatch(getEmployeeRequest(id));
    }
  }, [requestStatus]);
  console.log(requestData.request_status, "asd");
  const remainingSalary =
    requestData?.employee_details?.employee_detail?.salary -
    requestData?.service_charges -
    requestData?.amount;
  const remainingDues = requestData?.service_charges + requestData?.amount;
  const columns = [
    {
      id: "#",
      label: "#",
      align: "center",
    },
    { id: "PAYMENT", label: "PAYMENT", align: "center" },
    { id: "pending", label: "PENDING", align: "center" },
    {
      id: "requested on",
      label: "REQUESTED ON",
      align: "center",
    },
    {
      id: "status",
      label: "STATUS",
      align: "center",
    },
    {
      id: "transaction",
      label: "TRANSACTION ID/PROOF",
      align: "center",
    },
    {
      id: "paid",
      label: "PAID ON",
      align: "center",
    },
  ];
  function updateRequest(e) {
    e.preventDefault();

    dispatch(
      changeEmployeeRequestStatus(
        {
          status: status,
          more_info: status == "more info" ? moreInfo : null,
        },
        id
      )
    );
  }
  return (
    <div>
      <Box sx={{ width: "100%", height: "30%" }}>
        <Profile sx={{ mt: 5, mb: 2 }}>
          Request Status{" "}
          <span style={{ color: "green", fontSize: "15px" }}>
            {requestData?.request_status}
          </span>
        </Profile>
        <Account>
          <Typography
            sx={{ marginTop: "10px" }}
            fontSize={18}
            textAlign={"left"}
          >
            Advance Payment Ledger
          </Typography>
          <TableContainer
            sx={{ maxHeight: "calc(100vh - 100px)", position: "relative" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      paddingTop: "40px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>

              <TableBody>
                <TableRow>
                  <TableCell align={"center"}>1</TableCell>
                  <TableCell align={"center"}>
                    {formatNumber(requestData?.amount)}
                  </TableCell>
                  <TableCell align={"center"}>0</TableCell>
                  <TableCell align={"center"}>
                    {" "}
                    {moment(requestData?.created_at).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell align={"center"}>
                    {requestData?.request_status}
                  </TableCell>
                  <TableCell align={"center"}>
                    {requestData.transaction_id}
                  </TableCell>
                  <TableCell align={"center"}>
                    {requestData?.request_status === PAID
                      ? moment(requestData?.approved_at).format("MM/DD/YYYY")
                      : "----"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Account>
        {requestData.payment_status === PAID && (
          <Grid spacing={1} container sx={{ mt: 5 }}>
            <Grid item xs={12}>
              <Box
                sx={{
                  height: "50px",
                  backgroundColor: "lightblue",
                  padding: "10px",
                }}
                borderRadius="5px"
              >
                <Typography
                  sx={{
                    marginTop: "5px",
                  }}
                  fontSize={15}
                  textAlign={"left"}
                >
                  Your remaining salary to your employee
                </Typography>
                <Typography
                  sx={{ marginTop: "5px" }}
                  fontSize={15}
                  textAlign={"left"}
                >
                  {formatNumber(
                    requestData?.employee_details?.employee_detail?.salary
                  )}
                  - {formatNumber(requestData?.amount)}-{" "}
                  {formatNumber(requestData?.service_charges)} =
                  <span style={{ fontWeight: "700" }}>
                    {formatNumber(remainingSalary)}
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  height: "50px",
                  backgroundColor: "lightblue",
                  padding: "10px",
                }}
                borderRadius="5px"
              >
                <Typography
                  sx={{
                    marginTop: "5px",
                  }}
                  fontSize={15}
                  textAlign={"left"}
                >
                  Your Due payments to Advance Salo
                </Typography>
                <Typography
                  sx={{ marginTop: "5px" }}
                  fontSize={15}
                  textAlign={"left"}
                >
                  {formatNumber(requestData?.amount)} +
                  {formatNumber(requestData?.service_charges)}=
                  <span style={{ fontWeight: "700" }}>
                    {" "}
                    {formatNumber(remainingDues)}
                  </span>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </div>
  );
};

export default PaymentLedgerAdmin;
