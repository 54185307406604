import { Typography } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";

const Timer = (props) => {
  const [minutes, setMinutes] = useState(parseInt(props.seconds / 60));
  const [seconds, setSeconds] = useState(props.seconds % 60);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      {minutes === 0 && seconds === 0 ? null : (
        <Typography>
          {" "}
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </Typography>
      )}
    </div>
  );
};
export default Timer;
