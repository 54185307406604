import React from "react";
import Table from "./Table";
import Card from "../../components/Card";
import { Container } from "@mui/material";

const MyEmployees = () => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        height: "100%",
      }}
    >
      <Card padding="0px 0px" sx={{ width: "100%", height: "100%" }}>
        <Table />
      </Card>
    </Container>
  );
};

export default MyEmployees;
