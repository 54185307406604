import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//common
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import OTPVerification from "../pages/OTPVerification";
import SetPassword from "../pages/SetPassword";
import Page404 from "../pages/Page404";
import TermsAndConditions from "../pages/termsAndConditions";

//employer
import EmployerSignUp from "../pages/Signup/EmployerSignup";
import MyEmployees from "../pages/My-Employees";
import ReceivedRequests from "../pages/ReceivedRequests";
import ViewEmployeeDetails from "../pages/viewEmployeeDetails";
import ViewRequestDetails from "../pages/viewRequestDetails";

//employee
import EmployeeSignup from "../pages/Signup/EmployeeSignup";
import EmployeeDetails from "../pages/addEmployeeDetails";
import MyRequests from "../pages/MyRequests";
import AccountSetting from "../pages/AccountSetting";
import PublicGuard from "./publicGuard";
import PrivateGuard from "./PrivateGuard";
import PrivacyPolicy from "../pages/PrivacyPolicy";

//admin
import AllPendingRequest from "../pages/allPendingRequest";
import MyEmployers from "../pages/myEmloyers.js";
import AddedCountries from "../pages/addedCountries";
import SalaryRange from "../pages/salaryRange";
import AllCites from "../pages/allCItes.js";
import AllRequest from "../pages/allRequests";
import viewEmployerDetails from "../pages/viewEmployerDetails/viewEmployerDetails";
import EmployerDocumentsVerification from "../pages/EmployerDocumentsVerification ";
import AdminTermsAndConditions from "../pages/AdminTermsAndConditions";
import { useSelector } from "react-redux";
import LocalStorageService from "../utils/localStorageService";
import { ADMIN, EMPLOYER } from "../constants/userTypes";

const AppRoutes = () => {
  const AccessToken = LocalStorageService.getAccessToken();
  const user = LocalStorageService.getCurrentUser();


  return (
    <Router>
      <Switch>
        {AccessToken ? (
          user?.role === ADMIN ? (
            <PrivateGuard
              path="/"
              exact
              component={() => {
                return (
                  <>
                    {user?.role === ADMIN ? (
                      <Redirect to="/my-employers" />
                    ) : (
                      <Redirect to="/my-employees" />
                    )}
                  </>
                );
              }}
            />
          ) : (
            <PrivateGuard
              path="/"
              exact
              component={() => {
                return <Redirect to="/my-requests" />;
              }}
            />
          )
        ) : (
          <PublicGuard
            path="/"
            exact
            component={() => {
              return <Redirect to="/login" />;
            }}
          />
        )}

        <PublicGuard path="/login" component={Login} />
        <PublicGuard path="/signup" component={Signup} />
        <PublicGuard path="/employer-signup" component={EmployerSignUp} />

        <PublicGuard path="/employee-signup" component={EmployeeSignup} />

        <PublicGuard
          path="/documents-verification"
          component={EmployerDocumentsVerification}
        />
        <PublicGuard path="/verify-otp" component={OTPVerification} />
        <PublicGuard path="/set-password" component={SetPassword} />

        <PublicGuard path="/add-employee-details" component={EmployeeDetails} />
        <PrivateGuard path="/my-requests" component={MyRequests} />
        <PrivateGuard path="/new-request" component={MyRequests} />
        <PrivateGuard path="/account-setting" component={AccountSetting} />

        <PrivateGuard
          path="/all-requests?status=pending"
          component={AllPendingRequest}
        />
        <PrivateGuard path="/all-employers" component={MyEmployers} />
        <PrivateGuard path="/added-countries" component={AddedCountries} />
        <PrivateGuard path="/set-salary-range" component={SalaryRange} />
        <PrivateGuard path="/all-cites/:countryName?" component={AllCites} />
        <PrivateGuard path="/all-requests" component={AllRequest} />
        <PrivateGuard
          path="/employer-details/:id?"
          component={viewEmployerDetails}
        />

        <PrivateGuard path="/received-requests" component={ReceivedRequests} />
        <PrivateGuard
          key="2"
          path="/my-employees/:id?"
          component={MyEmployees}
        />
        <PrivateGuard key={`1`} path="/my-employees" component={MyEmployees} />

        <PrivateGuard
          path="/view-employee-details/:id?"
          component={ViewEmployeeDetails}
        />
        <PrivateGuard
          path="/view-request-details/:id?"
          component={ViewRequestDetails}
        />
        <PrivateGuard
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <PrivateGuard
          path="/admin/terms-and-conditions"
          component={AdminTermsAndConditions}
        />
        <PrivateGuard path="/privacy-policy" component={PrivacyPolicy} />

        <Route path="*">
          <Page404 />
        </Route>
      </Switch>
    </Router>
  );
};

export default AppRoutes;
